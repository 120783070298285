import CompanyAssets from "components/CompanyAssets";
import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap";
import AsyncSelect from 'react-select/async';
import { getDevices } from "api/v1";
import { range } from "lodash";

function index({ validation, loading = false, target = null }) {
  let edit = !!target
  const [items, setItems] = useState([]);
  const [intervals, setIntervals] = useState([])

  useEffect(() => {
    let _images = items.filter(_item => _item.id).map(_item => _item.id)
    validation.setValues({
      ...validation.values,
      images: _images,
      imagesLength: _images.length
    })
  }, [items])

  let loadDevices = (search) => {
    return getDevices({ search, no_paginate: 1 })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }

  useEffect(() => {
    if (validation.values.duration) {
      let _intervals = []
      range(2, 13, 1).reverse().forEach(_interval => {
        if (((validation.values.duration * 60) % _interval) == 0)
          _intervals.push((validation.values.duration * 60) / _interval)
      })
      setIntervals(_intervals)
    }
    else
      setIntervals([])
  }, [validation.values.duration])

  useEffect(() => {
    if (validation.values.duration && validation.values.interval) {
      let _totalImages = (validation.values.duration * 60) / validation.values.interval

      setItems(_items => {
        if (_items.length > _totalImages)
          return _items.slice(0, _totalImages)
        else
          return [..._items, ...range(_items.length + 1, _totalImages + 1, 1).map(_item => ({ dragKey: _item, ...(target?.images[_item - 1] || {}) }))]
      })
    }
  }, [validation.values.duration, validation.values.interval])

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col xs="12" className="mb-3">
          <Label className="form-label">Name</Label>
          <Input
            name="name"
            type="text"
            placeholder="Enter Name"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.name || ""}
            invalid={
              validation.touched.name && validation.errors.name ? true : false
            }
          />
          {validation.touched.name && validation.errors.name ? (
            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" className="mb-3">
          <Label className="form-label">Select Device</Label>
          <AsyncSelect
            defaultOptions
            placeholder="Select Device"
            required
            value={validation.values.device}
            getOptionLabel={({ name }) => `${name}`}
            getOptionValue={({ id }) => id}
            loadOptions={loadDevices}
            onChange={(_device) => {
              validation.setFieldValue('device', _device)
            }}

          />
          {validation.touched.device && validation.errors.device ? (
            <span className="text-danger font-size-11">{validation.errors.device}</span>
          ) : null}
        </Col>

        <Col xs="12" className="mb-3">
          <Label className="form-label">Start Time</Label>
          <Input
            name="start_time"
            type="datetime-local"
            disabled={edit}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.start_time || ""}
            invalid={
              validation.touched.start_time && validation.errors.start_time ? true : false
            }
          />
          {validation.touched.start_time && validation.errors.start_time ? (
            <FormFeedback type="invalid">{validation.errors.start_time}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" className="mb-3">
          <Label className="form-label">Duration (hours)</Label>
          <Input
            name="duration"
            type="select"
            disabled={edit}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.duration || ""}
            invalid={
              validation.touched.duration && validation.errors.duration ? true : false
            }
          >
            <option value="">Select Duration</option>
            {range(1, 25, 1).map(duration => <option key={`duration-${duration}`} value={duration}>{duration}</option>)}
          </Input>
          {validation.touched.duration && validation.errors.duration ? (
            <FormFeedback type="invalid">{validation.errors.duration}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" className="mb-3">
          <Label className="form-label">Interval (mins)</Label>
          <Input
            name="interval"
            type="select"
            disabled={edit}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.interval || ""}
            invalid={
              validation.touched.interval && validation.errors.interval ? true : false
            }
          >
            <option value="">Select Interval</option>
            {intervals.map(interval => <option key={`interval-${interval}`} value={interval}>{interval}</option>)}
          </Input>
          {validation.touched.interval && validation.errors.interval ? (
            <FormFeedback type="invalid">{validation.errors.interval}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" className="mb-3">
          {items.length > 0 && <Label className="form-label">Images</Label>}

          <CompanyAssets items={items} setItems={setItems} />
          {
            validation.touched.images && validation.errors.images ? (
              <span className="text-danger font-size-11">{validation.errors.images}</span>
            ) : null
          }
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-end">
            <button
              disabled={loading}
              type="submit"
              className="btn btn-success save-customer"
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default index
