import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Form,
  Alert,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { useHistory, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import { setTabBarLoader } from "utils/common";
import { editCompany, getCompany } from "store/actions";
import Subscription from "components/Subscription";

import AsyncSelect from 'react-select/async';
import { countries, provinces, industries } from "api/v1";

const Company = () => {
  let [logo, setLogo] = useState()
  const history = useHistory()
  const [alertVisible, setAlertVisible] = useState(true)

  const { company, _loading, profile } = useSelector(state => ({
    _loading: state.company.loading,
    company: state.company.company,
    profile: state.profiles.profile
  }));
  let isAdmin = profile?.profile.is_company_admin


  let loadCountries = async (search) => {
    return countries({ search, is_active: true })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error("Something went wrong loading countries");
        }
      })
  }

  let loadIndustries = async (search) => {
    return industries({ search })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error("Something went wrong loading industries");
        }
      })
  }

  let loadProvinces = async (search) => {
    return provinces(form.values.country.id, { search, is_active: true })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error("Something went wrong loading provinces");
        }
      })
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAdmin) {
      history.push('/dashboard')
      return
    }

    dispatch(getCompany());
  }, [])

  const loading = _loading

  useEffect(() => {
    setTabBarLoader(loading)
  }, [loading]) //loading

  const form = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (company && company.name) || "",
      logo: (company && company.logo) || null,
      country: (company && company.country) || null,
      province: (company && company.province) || null,
      industry: (company && company.industry) || null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter company"),
      country: Yup.mixed().required("Please select Country"),
      province: Yup.mixed().required("Please select Province"),
      industry: Yup.mixed().required("Please select Industry")
    }),
    onSubmit: (values, actions) => {
      dispatch(editCompany({
        ...values, country: values.country.id,
        industry: values.industry.id,
        province: values.province.id
      }, actions))
    }
  });

  return (
    <React.Fragment >
      <div className="page-content">
        <MetaTags>
          <title>Account | Settings</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb back={true} title="Settings" breadcrumbItem="Account" />
          {
            ((!company?.subscribed_plan && company?.is_trial_available) || (company?.subscribed_plan && !company?.is_subscription_active)) && isAdmin &&
            <Alert isOpen={alertVisible} toggle={() => setAlertVisible(false)} color={
              (company?.subscribed_plan && !company?.is_subscription_active) ? "danger" : company?.remaining_trial_days > 0 ? "warning" : "danger"
            }>
              {
                (company?.subscribed_plan && !company?.is_subscription_active) ? <span>Your subscription is disabled or cancelled, please enable it again.</span>
                  : company?.remaining_trial_days > 0 ? <span>Your free trial will be expired in <b>{company?.remaining_trial_days} Day(s)</b>.</span> : <span>Your free trial is expired.</span>
              }
            </Alert>
          }

          <Row>
            {
              company.organization &&
              <Col lg="12">
                <Card>
                  <h5 className="mt-3 px-4">Organization Details</h5>
                  <CardBody>
                    <Row>
                      <div className="mb-3 col-12 col-md-6">
                        <Label className="form-label">Name</Label>
                        <Input
                          disabled
                          name="name"
                          type="text"
                          value={company.organization.name}
                        />
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            }
            <Col lg="12">
              <Card>
                <h5 className="mt-3 px-4">Account Details</h5>
                <CardBody>
                  <Form onSubmit={e => {
                    e.preventDefault();
                    form.handleSubmit();
                    return false;
                  }}>
                    <Row>
                      <div className="mb-3 col-12 col-md-6">
                        <Label className="form-label">Location Name</Label>
                        <Input
                          name="name"
                          type="text"
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.name || ""}
                          invalid={
                            form.touched.name && form.errors.name ? true : false
                          }
                        />
                        {form.touched.name && form.errors.name ? (
                          <FormFeedback type="invalid">{form.errors.name}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3 col-12 col-md-6">
                        <Label className="form-label">Industry</Label>
                        <AsyncSelect
                          isClearable
                          defaultOptions
                          placeholder="Select Industry"
                          value={form.values.industry}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ id }) => id}
                          loadOptions={loadIndustries}
                          onChange={_industry => form.setFieldValue('industry', _industry)}
                        />
                        {form.errors.industry && form.touched.industry && <p className="text-danger mb-0 mt-1 ml-0">{form.errors.industry}</p>}
                      </div>

                      <div className={`mb-3 col-12 col-md-6`}>
                        <Label className="form-label">Country</Label>
                        <AsyncSelect
                          isClearable
                          defaultOptions
                          placeholder="Select Country"
                          value={form.values.country}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ id }) => id}
                          loadOptions={loadCountries}
                          onChange={_country => form.setValues({
                            ...form.values,
                            country: _country,
                            province: null
                          })}
                        />
                        {form.errors.country && form.touched.country && <p className="text-danger mb-0 mt-1 ml-0">{form.errors.country}</p>}
                      </div>

                      {
                        form.values.country &&
                        <div className="mb-3 col-12 col-md-6">
                          <Label className="form-label">Province</Label>
                          <AsyncSelect
                            key={`provinces-${form.values.country?.id}`}
                            isClearable
                            defaultOptions
                            placeholder="Select Province"
                            value={form.values.province}
                            getOptionLabel={({ name }) => name}
                            getOptionValue={({ id }) => id}
                            loadOptions={loadProvinces}
                            onChange={_province => form.setFieldValue('province', _province)}
                          />
                          {form.errors.province && form.touched.province && <p className="text-danger mb-0 mt-1 ml-0">{form.errors.province}</p>}
                        </div>
                      }

                      <div className="mb-3 col-12 col-md-6">
                        <Label className="form-label">Logo</Label>
                        <Input
                          name="logo"
                          type="file"
                          onChange={event => {
                            form.setFieldValue('logo', event.target.files[0])
                            if (event.target.files.length > 0) {
                              var reader = new FileReader();
                              reader.readAsDataURL(event.target.files[0]);
                              reader.onloadend = function (e) {
                                setLogo(reader.result)
                              }

                            } else {
                              setLogo(null)
                            }
                          }}
                          accept="image/*"
                          onBlur={form.handleBlur}
                          invalid={
                            form.touched.logo && form.errors.logo ? true : false
                          }
                        />
                        {form.touched.logo && form.errors.logo ? (
                          <FormFeedback type="invalid">{form.errors.logo}</FormFeedback>
                        ) : null}

                        {
                          (logo || form.values.logo) &&
                          <div className="table-avatar-lg mt-2">
                            <img
                              src={logo || form.values.logo}
                              alt={form.values.name ? form.values.name[0] : "A"}
                            />
                          </div>
                        }
                      </div>

                      <div className="text-end mt-2">
                        <button
                          disabled={loading || !isAdmin}
                          className="btn btn-success save-customer"
                        >
                          {loading ? "Saving" : "Save"} {" "}
                          {loading && <i className="bx bx-hourglass bx-spin font-size-16 align-middle"></i>}
                        </button>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>

            <Col lg="12">
              <Subscription />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default withRouter(Company);
