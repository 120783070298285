import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { withRouter, Link } from "react-router-dom"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"
import { useDispatch, useSelector } from "react-redux"
import { getProfile, getCompany } from "store/actions"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const { profile, company } = useSelector(state => ({
    profile: state.profiles.profile,
    company: state.company.company,
  }));

  let isAdmin = profile?.profile.is_company_admin

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getCompany());
  }, [])

  let _profile = profile?.profile

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={_profile?.profile_pic || user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{_profile?.first_name || "Admin"}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile/settings">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem>


          {
            isAdmin &&
            <DropdownItem tag="a" href="/account/settings">
              <i className="bx bx-home-alt font-size-16 align-middle me-1" />
              {props.t("Account")}
            </DropdownItem>
          }

          {
            isAdmin && company?.subscribed_plan &&
            <DropdownItem tag="a" href="/billing/invoices">
              <i className="bx bx-credit-card font-size-16 align-middle me-1" />
              {props.t("Billing")}
            </DropdownItem>
          }
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment >
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

export default withRouter(
  (withTranslation()(ProfileMenu))
)
