import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_INVOICES,
  DELETE_INVOICE,
  ADD_INVOICE,
  EDIT_INVOICE
} from "./actionTypes";

import {
  getInvoicesSuccess,
  getInvoices,
  getInvoicesFail,
  deleteInvoiceModal
} from "./actions";

import {
  getInvoices as getInvoicesAPI,
  deleteInvoice as deleteInvoiceAPI,
  addInvoice as addInvoiceAPI,
  editInvoice as editInvoiceAPI,
} from "api/v1";


function* fetchInvoices({ payload: { params } }) {
  try {
    const response = yield call(getInvoicesAPI, params);
    yield put(getInvoicesSuccess(response));
  } catch (error) {
    if (error.message != "request-cancel") {
      yield put(getInvoicesFail("Something went wrong!"))
      toast.error("Something went wrong!");
    }
  }
}

function* addInvoice({ payload: { invoice, actions } }) {
  try {
    yield call(addInvoiceAPI, invoice)
    toast.success("Invoice Created!");
    yield put(getInvoices(true));
    actions.resetForm()

  } catch ({ errors }) {
    yield put(getInvoicesFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
}

function* editInvoice({ payload: { id, invoice, actions } }) {
  try {
    yield call(editInvoiceAPI, id, invoice)
    toast.success("Invoice Updated!");
    yield put(getInvoices(true));

  } catch ({ errors }) {
    yield put(getInvoicesFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
}

function* deleteInvoice({ payload: { invoice } }) {
  try {
    yield call(deleteInvoiceAPI, invoice.id);
    put(deleteInvoiceModal())
    toast.warn("Invoice Deleted!");
  } catch (error) {
    toast.error("Something went wrong!");
  }
  yield put(getInvoices(true));
}

function* invoiceSaga() {
  yield takeEvery(GET_INVOICES, fetchInvoices);
  yield takeEvery(DELETE_INVOICE, deleteInvoice);
  yield takeEvery(ADD_INVOICE, addInvoice);
  yield takeEvery(EDIT_INVOICE, editInvoice);
}

export default invoiceSaga;
