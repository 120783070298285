import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes"

export const loginUser = (user, history, actions) => {
  return {
    type: LOGIN_USER,
    payload: { user, history, actions },
  }
}

export const resetAuth = () => {
  return {
    type: 'RESET_AUTH',
  }
}

export const verifyPasswordChangeToken = (data, history) => {
  return {
    type: 'VERIFY_PASSWORD_CHANGE_TOKEN',
    payload: { data, history },
  }
}

export const requestPasswordChange = (user, actions, history) => {
  return {
    type: 'REQUEST_PASSWORD_CHANGE',
    payload: { user, actions, history },
  }
}

export const tfa = (user, actions, history) => {
  return {
    type: 'TFA',
    payload: { user, actions, history },
  }
}

export const resetPassword = (data, actions, history) => {
  return {
    type: 'RESET_PASSWORD',
    payload: { data, actions, history },
  }
}

export const changeState = state => {
  return {
    type: 'CHANGE_AUTH_STATE',
    payload: state
  }
}

export const setTokens = tokens => {
  return {
    type: 'SET_AUTH_TOKENS',
    payload: tokens
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const apiPassError = error => {
  return {
    type: 'API_PASS_ERROR',
    payload: error,
  }
}
