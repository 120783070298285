import React, { memo } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import Steps from './Steps'

function Flow({ visible, cancel, activePlan }) {
  return (
    <Modal centered size='lg' isOpen={visible} toggle={cancel}>
      <ModalHeader tag="h4" toggle={cancel}>
        Subscription Update
      </ModalHeader>
      <ModalBody>
        {
          visible &&
          <Steps
            close={cancel}
            activePlan={activePlan}
          />
        }
      </ModalBody>
    </Modal>
  )
}

export default memo(Flow)