export const getCompany = () => ({
  type: 'GET_COMPANY'
})

export const editCompany = (company, actions) => {
  return {
    type: 'EDIT_COMPANY',
    payload: { company, actions },
  }
}

export const onCompanyComplete = () => ({
  type: 'COMPANY_COMPLETE'
})

export const setCompany = (company) => {
  return {
    type: 'SET_COMPANY',
    payload: { company }
  }
}
