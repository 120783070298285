import { filterParams } from "../apiHelper";
import request from "../restClient";

export const getPlaylists = async (params) => {
  return await request({
    url: "playlists/",
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const deletePlaylist = async (id) => {
  return await request({
    url: `playlists/${id}/`,
    method: "DELETE"
  });
};

export const getPlaylist = async (id) => {
  return await request({
    url: `playlists/${id}/`,
    method: "GET"
  });
};

export const editPlaylist = async (id, data) => {

  return await request({
    url: `playlists/${id}/`,
    method: "PATCH",
    data
  });
};

export const addPlaylist = async (data) => {

  return await request({
    url: `playlists/`,
    method: "POST",
    data
  });
};
