import React from 'react'
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from './components/CheckoutForm';
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux';
import { getCompany } from 'store/actions';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function Stripe({ close, cart }) {
  const dispatch = useDispatch();

  const onComplete = () => {
    close()

    Swal.fire({
      title: 'Processing...',
      html: 'Please wait we are enabling your account.',
      timer: 5000,
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      },
    }).finally(() => {
      dispatch(getCompany())
    })
  }

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm cart={cart} onComplete={onComplete} />
    </Elements>

  )
}

export default Stripe
