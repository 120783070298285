import { filterParams } from "../apiHelper";
import request from "../restClient";

export const getCards = async (params) => {
  return await request({
    url: "payment_methods/",
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const deleteCard = async (id) => {
  return await request({
    url: `payment_methods/${id}/`,
    method: "DELETE"
  });
};

export const editCard = async (id) => {

  return await request({
    url: `payment_methods/${id}/`,
    method: "PATCH"
  });
};

export const addCard = async (data) => {

  return await request({
    url: `payment_methods/`,
    method: "POST",
    data
  });
};
