import request from "../restClient";

export const getProfile = async () => {
  return await request({
    url: "users/profile/",
    method: "GET"
  });
};

export const editProfile = async (_data) => {
  const formD = new FormData()

  Object.entries(_data).forEach((keyValue) => {
    if (keyValue[0] != "profile_pic")
      formD.append(keyValue[0], keyValue[1])
    else if (typeof (keyValue[1]) != "string" && keyValue[1])
      formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: `users/profile/`,
    method: "PATCH",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};

export const requestPhoneOtp = async () => {
  return await request({
    url: `users/request_phone_otp`,
    method: "post"
  });
};

export const verifyOtp = async (data) => {
  return await request({
    url: `users/verify_phone`,
    method: "post",
    data
  });
};
