import {
  GET_EVENTS_FAIL,
  GET_EVENTS_SUCCESS,
  GET_EVENTS,
} from "./actionTypes";

const INIT_STATE = {
  events: [],
  loading: false
};

const events = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EVENTS:
      return {
        ...state,
        loading: action.payload.loading
      };

    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        events: action.payload,
      };

    case GET_EVENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default events;
