import {
  GET_DEVICES_FAIL,
  GET_DEVICES_SUCCESS,
  GET_DEVICES,
  GET_DEVICE,
  GET_DEVICE_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  deviceWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  device: null,
  loading: false
};

const devices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEVICES:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case GET_DEVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        deviceWithPagy: action.payload,
      };

    case GET_DEVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        device: action.payload,
      };

    case GET_DEVICE:
      return {
        ...state,
        device: null,
        loading: true
      };

    case GET_DEVICES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default devices;
