import React from 'react'
import { Fragment } from 'react'
import { FormFeedback, Input, Label } from 'reactstrap'
import AsyncSelect from 'react-select/async';
import { countries, provinces, industries } from 'api/v1';
import { toast } from 'react-toastify';

function Location({ validation }) {
  let loadCountries = async (search) => {
    return countries({ search, is_active: true })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error("Something went wrong loading countries");
        }
      })
  }

  let loadIndustries = async (search) => {
    return industries({ search })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error("Something went wrong loading industries");
        }
      })
  }

  let loadProvinces = async (search) => {
    return provinces(validation.values.country.id, { search, is_active: true })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error("Something went wrong loading provinces");
        }
      })
  }

  return (
    <Fragment>
      <div className="mb-3">
        <Label className="form-label">Organization Name</Label>
        <Input
          name="company_name"
          type="text"
          placeholder="Enter Organization Name"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.company_name || ""}
          invalid={
            validation.touched.company_name && validation.errors.company_name ? true : false
          }
        />
        {validation.touched.company_name && validation.errors.company_name ? (
          <FormFeedback type="invalid">{validation.errors.company_name}</FormFeedback>
        ) : null}
      </div>

      <div className="mb-3">
        <Label className="form-label">Industry</Label>
        <AsyncSelect
          isClearable
          defaultOptions
          placeholder="Select Industry"
          value={validation.values.industry}
          getOptionLabel={({ name }) => name}
          getOptionValue={({ id }) => id}
          loadOptions={loadIndustries}
          onChange={_industry => validation.setFieldValue('industry', _industry)}
        />
        {validation.errors.industry && validation.touched.industry && <p className="text-danger mt-1 ml-0">{validation.errors.industry}</p>}
      </div>

      <div className="mb-3">
        <Label className="form-label">Country</Label>
        <AsyncSelect
          isClearable
          defaultOptions
          placeholder="Select Country"
          value={validation.values.country}
          getOptionLabel={({ name }) => name}
          getOptionValue={({ id }) => id}
          loadOptions={loadCountries}
          onChange={_country => validation.setValues({
            ...validation.values,
            country: _country,
            province: null
          })}
        />
        {validation.errors.country && validation.touched.country && <p className="text-danger mt-1 ml-0">{validation.errors.country}</p>}
      </div>
      {
        validation.values.country &&
        <div className="mb-3">
          <Label className="form-label">Province</Label>
          <AsyncSelect
            key={`provinces-${validation.values.country.id}`}
            isClearable
            defaultOptions
            placeholder="Select Province"
            value={validation.values.province}
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
            loadOptions={loadProvinces}
            onChange={_province => validation.setFieldValue('province', _province)}
          />
          {validation.errors.province && validation.touched.province && <p className="text-danger mt-1 ml-0">{validation.errors.province}</p>}
        </div>
      }
    </Fragment>
  )
}

export default Location
