import React, { Fragment, useEffect, useMemo, useState } from 'react'
import Timeline from 'components/Timeline'
import Pricing from './Pricing'
import Screens from './Screens'
import Payment from './Payment'

const STEPS = ['screens', 'pricing', 'payment']

const defaultPayload = {
  total_screens: 1,
  frequency: null
}

function Steps({ activePlan, close }) {
  const [currentStep, setCurrentStep] = useState('screens')
  const [steps, setSteps] = useState([...STEPS])
  const [subscription, setSubscription] = useState({ ...defaultPayload })

  useEffect(() => {
    if (activePlan) {
      setSteps(STEPS.slice(0, -1))
      setSubscription({ ...subscription, total_screens: activePlan?.plan?.total_screens || 1 })
    }
  }, [activePlan])

  const Step = useMemo(() => getCorrespondingStep(currentStep), [currentStep, subscription, steps]);

  function getCorrespondingStep(_currentStep) {
    const timeline = <Timeline
      steps={steps}
      currentStep={_currentStep}
    />

    switch (_currentStep) {
      case 'screens':
        return <Screens
          subscription={subscription}
          setSubscription={setSubscription}
          timeline={timeline}
          goForward={goForward}
        />
      case 'pricing':
        return <Pricing
          subscription={subscription}
          setSubscription={setSubscription}
          timeline={timeline}
          goForward={goForward}
          forwardable={!activePlan}
          goBack={goBack}
          close={close}
        />
      case 'payment':
        return <Payment
          subscription={subscription}
          timeline={timeline}
          goBack={goBack}
          close={close}
        />

      default:
        return <Fragment />;
    }
  }

  function goForward(e = null) {
    if (e) e.preventDefault();

    const _index = steps.findIndex(_step => _step == currentStep)
    setCurrentStep(steps[_index + 1])
  }

  function goBack(e = null) {
    if (e) e.preventDefault();

    const _index = steps.findIndex(_step => _step == currentStep)
    setCurrentStep(steps[_index - 1])
  }

  return (
    <Fragment>
      {Step}
    </Fragment>
  )
}

export default Steps
