import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { sendModal, sendSchedule } from 'store/schedules/actions'
import { getDevices } from "api/v1";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success mx-2',
    cancelButton: 'btn btn-danger'
  },
  buttonsStyling: false
})

function index() {
  let dispatch = useDispatch()
  let history = useHistory()
  const [loading, setLoading] = useState(false)

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: '',
      devices: [],
      image: null
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      image: Yup.mixed().required("Please drag image"),
      devices: Yup.array().min(1, "Please select at least 1 device").required("required"),
    }),
    onSubmit: async (values, actions) => {
      setLoading(true)
      try {
        let _devices = await getDevices({ is_schedule_exists: true, no_paginate: true, device_ids: values.devices.map(_device => _device.id) }) || []
        if (_devices.length == 0)
          dispatch(sendSchedule({ ...values, devices: values.devices.map(_device => _device.id), override_schedule: false }, actions, history))
        else {
          let _errors = ""
          _devices?.forEach(_device => {
            _errors += `<li><strong>${_device.name}</strong></li>`
          })

          swalWithBootstrapButtons.fire({
            title: 'Schedule for following devices already exist, do you want to override them?',
            icon: 'info',
            allowOutsideClick: false,
            showCancelButton: true,
            html: `<ul class=text-left>${_errors}</ul>`
          }).then(({ isConfirmed, isDismissed }) => {
            if (isConfirmed)
              dispatch(sendSchedule({ ...values, devices: values.devices.map(_device => _device.id), override_schedule: true }, actions, history))
            else if (isDismissed)
              dispatch(sendModal())
          })
        }
      } catch (error) {
        toast.error("something went wrong!")
      }
      setLoading(false)
    },
  });

  return (
    <Form
      sendableLoading={loading}
      sendable
      validation={validation}
    />
  )
}

export default index
