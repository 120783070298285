import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Input,
  Row
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  getCevents as onGetCevents,
  deleteCevent as onDeleteCevent,
  newCeventModal,
  deleteCeventModal,
  editCeventModal
} from "store/cevents/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from "utils/common";
import Create from './Create'
import Edit from './Edit'

const Cevents = () => {
  const dispatch = useDispatch();

  const { ceventWithPagy, loading, deleteModal } = useSelector(state => ({
    ceventWithPagy: state.cevents.ceventWithPagy,
    loading: state.cevents.loading,
    deleteModal: state.cevents.delete,
  }));

  const [cevent, setCevent] = useState(null);

  //delete cevent

  const onClickDelete = (cevent) => {
    setCevent(cevent);
    dispatch(deleteCeventModal())
  };

  const handleDeleteCevent = () => {
    dispatch(onDeleteCevent(cevent))
  }


  function init() {
    dispatch(onGetCevents(true, {}));
  }

  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  const handleCreateCevent = _cevent => {
    setCevent(_cevent)
    dispatch(newCeventModal())
  };

  const handleEditCevent = _cevent => {
    setCevent(_cevent)
    dispatch(editCeventModal())
  };

  function handleChange(isExist, event) {
    if (isExist)
      onClickDelete(event.company_event)
    else
      handleCreateCevent(event)

  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCevent}
        onCloseClick={() => dispatch(deleteCeventModal())}
      />

      <Create cevent={cevent} />
      <Edit cevent={cevent} />

      <div className={"page-content"}>
        <MetaTags>
          <title>Events</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Events" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row>
                    {
                      Object.keys(ceventWithPagy).map((_event, _index) => <Col key={`event-${_index}`} className="mb-4" xs={12} md={6} lg={4}>
                        <h5 className="mb-2">{_event}</h5>
                        {
                          ceventWithPagy[_event].length > 0 ?
                            <Fragment>
                              {
                                ceventWithPagy[_event].map((__event, __index) => <div className="d-flex" key={`event-${_index}-${__index}`}>
                                  <div>
                                    <Input name={`event-${_index}-${__index}`}
                                      onClick={() => handleChange(!!__event.company_event, __event)}
                                      onChange={() => handleChange(!!__event.company_event, __event)}
                                      id={`event-${_index}-${__index}`}
                                      type="checkbox"
                                      checked={!!__event.company_event}
                                    />
                                    <Label className="form-label d-inline-block mx-2" htmlFor={`event-${_index}-${__index}`}>{__event.name}</Label>
                                  </div>

                                  {!!__event.company_event && <i onClick={() => handleEditCevent(__event.company_event)} className="fas cursor-pointer fa-pencil-alt text-success mt-1" />}
                                </div>
                                )
                              }
                            </Fragment>
                            : <p className="text-muted">No Event found</p>
                        }

                      </Col>
                      )
                    }
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Cevents.propTypes = {
  cevents: PropTypes.array,
  onGetCevents: PropTypes.func,
  onAddNewCevent: PropTypes.func,
  onDeleteCevent: PropTypes.func,
  onUpdateCevent: PropTypes.func,
};

export default Cevents;
