import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
} from "./actionTypes"

export const registerUser = (user, actions) => {
  return {
    type: REGISTER_USER,
    payload: { user, actions },
  }
}

export const joinUser = (user, actions) => {
  return {
    type: 'JOIN_USER',
    payload: { user, actions },
  }
}

export const confirmUser = (user, actions) => {
  return {
    type: 'CONFIRM_USER',
    payload: { user, actions },
  }
}

export const resetRegister = () => {
  return {
    type: 'RESET_REGISTER',
  }
}

export const changeState = state => {
  return {
    type: 'CHANGE_STATE',
    payload: state
  }
}

export const setTokens = tokens => {
  return {
    type: 'SET_TOKENS',
    payload: tokens
  }
}

export const registerUserSuccessful = msg => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: msg,
  }
}

export const registerUserFailed = registrationError => {
  return {
    type: REGISTER_USER_FAILED,
    payload: registrationError,
  }
}
