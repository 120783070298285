import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
import { pick } from 'lodash';
//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from "utils/common";

import { withRouter, Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import Preloader from "components/Common/Preloader";
import logo from "../../assets/images/logo.png";

import { requestPasswordChange, resetAuth } from "store/actions";

const ForgetPasswordPage = props => {
  const { passError, loading } = useSelector((state) => state.Login);

  const dispatch = useDispatch();

  const form = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: { email: '' },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Please Enter Your Email"),
    }),
    onSubmit: (values, actions) => {

      dispatch(requestPasswordChange(pick(
        { ...values, redirect_uri: `${process.env.REACT_APP_HOST}/set_password` },
        ['email', 'redirect_uri']
      ), actions, props.history));
    }
  });

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  useEffect(() => {
    dispatch(resetAuth())
  }, [])


  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Forget Password |ePaperSignSystem
        </title>
      </MetaTags>

      <section className="auth-wrapper">
        <Preloader />
        <div className="min-vh-100 d-flex align-items-center">
          <Container className="py-3">
            <Row className="justify-content-center">
              <Col xl={7}>
                <Card>
                  <CardBody>
                    <div className="p-4 text-center">
                      <div className="text-center mb-3">
                        <img
                          src={logo}
                          alt=""
                          height="65"
                        />
                      </div>
                      <h4>Forgot Password</h4>
                    </div>
                    <div className="p-2">
                      {passError ? <Alert color="danger">{passError}</Alert> : null}

                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          form.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.email || ""}
                            invalid={
                              form.touched.email && form.errors.email ? true : false
                            }
                          />
                          {form.touched.email && form.errors.email ? (
                            <FormFeedback type="invalid">{form.errors.email}</FormFeedback>
                          ) : null}
                        </div>
                        <Row className="my-2">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-md "
                              type="submit"
                            >
                              Reset
                            </button>
                          </Col>
                        </Row>
                      </Form>
                      <div className="mt-2 text-center">
                        <p>
                          Go back to{" "}
                          <Link to="/login" className="font-weight-medium text-primary">
                            Login
                          </Link>{" "}
                        </p>
                        <p>
                          © {new Date().getFullYear()} by ePaperSignSystem
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
