import React from "react";
import {
  Col,
  Row,
  Label,
  Form,
} from "reactstrap";
import AsyncSelect from 'react-select/async';
import { getDevices } from "api/v1";

function index({
  validation,
  target = null
}) {

  let loadDevices = (search) => {
    return getDevices({ search, no_paginate: 1 })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col xs="12" className="mb-3">
          <Label className="form-label">Select Devices</Label>
          <AsyncSelect
            defaultOptions
            placeholder="Select Devices"
            required
            isMulti
            value={validation.values.devices}
            getOptionLabel={({ name }) => `${name}`}
            getOptionValue={({ id }) => id}
            loadOptions={loadDevices}
            onChange={(_devices) => {
              validation.setFieldValue('devices', _devices)
            }}

          />
          {validation.touched.devices && validation.errors.devices ? (
            <span className="text-danger font-size-11">{validation.errors.devices}</span>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-end">
            <button
              type="submit"
              className="btn btn-success save-customer"
            >
              Save
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default index
