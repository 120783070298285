import MetaTags from "react-meta-tags";
import React, { useState, useEffect, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Form,
  Alert,
} from "reactstrap";
import PhoneInput from 'react-phone-input-2'

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import { setTabBarLoader } from "utils/common";
import { editProfile, getProfile } from "store/actions";
import Verify from "./Verify";
import { requestPhoneOtp } from "api/v1";
import { toast } from "react-toastify";

const Profile = () => {
  let [profilePic, setProfilePic] = useState()
  const [phoneAlert, setPhoneAlert] = useState(true)
  const [otp, setOtp] = useState(false)
  const [otpLoading, setOtpLoading] = useState(false)

  const { profile, loading } = useSelector(state => ({
    loading: state.profiles.loading,
    profile: state.profiles.profile
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile());
  }, [])

  useEffect(() => {
    setTabBarLoader(loading || otpLoading)
  }, [loading, otpLoading]) //loading

  let _profileDetail = profile?.profile


  const form = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: (profile && profile.email) || '',
      is_mfa_enabled: (profile && profile.is_mfa_enabled) || false,
      phone_number: (profile && profile.phone_number) || '',
      send_login_otp_to: (profile && profile.send_login_otp_to) || '',

      first_name: (_profileDetail && _profileDetail.first_name) || "",
      first_name: (_profileDetail && _profileDetail.first_name) || "",
      last_name: (_profileDetail && _profileDetail.last_name) || "",
      profile_pic: (_profileDetail && _profileDetail.profile_pic) || null,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please enter your first name"),
      phone_number: Yup.string().required("Please enter your phone number"),
      last_name: Yup.string().required("Please enter your last name"),
      send_login_otp_to: Yup.string().required("Please select login option"),
    }),
    onSubmit: (values, actions) => {
      dispatch(editProfile(values, actions))
    }
  });

  useEffect(() => {
    if (profile)
      setPhoneAlert(!profile?.is_phone_verified)

  }, [profile])


  async function requestOtp() {
    setOtpLoading(true)
    try {
      await requestPhoneOtp()
      setOtp(true)
    } catch ({ errors }) {
      if (errors?.non_field_errors)
        toast.error(errors.non_field_errors[0]);
      else
        toast.error("Something went wrong while sending otp!")
    }
    setOtpLoading(false)
  }

  return (
    <React.Fragment >
      <div className="page-content">
        <MetaTags>
          <title>Profile | Settings</title>
        </MetaTags>
        <Container fluid>
          <Verify
            modal={otp}
            onSuccess={() => setPhoneAlert(false)}
            toggle={setOtp}
          />

          <Breadcrumb back={true} title="Settings" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              <Card>
                <h5 className="mt-3 px-4">Profile Details</h5>
                <Alert isOpen={!!profile?.phone_number && phoneAlert && !loading} toggle={() => setPhoneAlert(false)} color="warning" className="mx-3 mt-3">
                  Please verify phone number to receive updates.
                  <b onClick={requestOtp} className="mx-2 cursor-pointer">Verify now!</b>
                </Alert>

                <CardBody>
                  <Form onSubmit={e => {
                    e.preventDefault();
                    form.handleSubmit();
                    return false;
                  }}>
                    <Row>
                      <div className="mb-3 col-12 col-md-6">
                        <Label className="form-label">First Name</Label>
                        <Input
                          name="first_name"
                          type="text"
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.first_name || ""}
                          invalid={
                            form.touched.first_name && form.errors.first_name ? true : false
                          }
                        />
                        {form.touched.first_name && form.errors.first_name ? (
                          <FormFeedback type="invalid">{form.errors.first_name}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3 col-12 col-md-6">
                        <Label className="form-label">Last Name</Label>
                        <Input
                          name="last_name"
                          type="text"
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.last_name || ""}
                          invalid={
                            form.touched.last_name && form.errors.last_name ? true : false
                          }
                        />
                        {form.touched.last_name && form.errors.last_name ? (
                          <FormFeedback type="invalid">{form.errors.last_name}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3 col-12 col-md-6">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          disabled
                          type="text"
                          value={form.values.email || "No email added"}
                        />
                      </div>

                      <div className="mb-3 col-12 col-md-6">
                        <Label className="form-label">Phone Number</Label>
                        <PhoneInput
                          inputProps={{
                            name: 'phone_number'
                          }}
                          onBlur={form.handleBlur}
                          onChange={e => form.setFieldValue('phone_number', `+${e}`)}
                          value={form.values.phone_number}
                          isValid={!(form.errors.phone_number && form.touched.phone_number)}
                          specialLabel={null}
                          country={'fr'}
                        />
                        {form.errors.phone_number && form.touched.phone_number && <p className="text-danger mt-1 mb-0 ml-0">{form.errors.phone_number}</p>}
                      </div>

                      <div className="my-3 col-12">
                        <div className="form-check form-switch form-switch-md">
                          <Label className="form-label">Is multi factor authentication enabled?</Label>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitch2"
                            defaultChecked={form.values.is_mfa_enabled}
                            onChange={e => form.setFieldValue('is_mfa_enabled', !form.values.is_mfa_enabled)}
                            onBlur={form.handleBlur}
                          />
                        </div>
                      </div>

                      <div className="mb-3 col-12 col-md-6">
                        <Label className="form-label">Login Otp Options</Label>
                        <Input
                          name="send_login_otp_to"
                          type="select"
                          disabled={!profile?.is_phone_verified}
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.send_login_otp_to || ""}
                          invalid={
                            form.touched.send_login_otp_to && form.errors.send_login_otp_to ? true : false
                          }
                        >
                          <option value="">Select Option</option>
                          <option value="email">Email Address</option>
                          {
                            profile?.is_phone_verified &&
                            <Fragment>
                              <option value="phone">Phone Number</option>
                              <option value="both">Both</option>
                            </Fragment>
                          }
                        </Input>
                        {form.touched.send_login_otp_to && form.errors.send_login_otp_to ? (
                          <FormFeedback type="invalid">{form.errors.send_login_otp_to}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="col-12 col-md-6" />

                      <div className="mb-3 col-12 col-md-6">
                        <Label className="form-label">Profile Picture</Label>
                        <Input
                          name="profile_pic"
                          type="file"
                          onChange={event => {
                            form.setFieldValue('profile_pic', event.target.files[0])
                            if (event.target.files.length > 0) {
                              var reader = new FileReader();
                              reader.readAsDataURL(event.target.files[0]);
                              reader.onloadend = function (e) {
                                setProfilePic(reader.result)
                              }

                            } else {
                              setProfilePic(null)
                            }
                          }}
                          accept="image/*"
                          onBlur={form.handleBlur}
                          invalid={
                            form.touched.profile_pic && form.errors.profile_pic ? true : false
                          }
                        />
                        {form.touched.profile_pic && form.errors.profile_pic ? (
                          <FormFeedback type="invalid">{form.errors.profile_pic}</FormFeedback>
                        ) : null}

                        {
                          (profilePic || form.values.profile_pic) &&
                          <div className="table-avatar-lg mt-2">
                            <img
                              src={profilePic || form.values.profile_pic}
                              alt={form.values.email[0]}
                            />
                          </div>
                        }
                      </div>

                      <div className="text-end mt-2">
                        <button
                          type="submit"
                          disabled={loading}
                          className="btn btn-success save-customer"
                        >
                          {loading ? "Saving" : "Save"} {" "}
                          {loading && <i className="bx bx-hourglass bx-spin font-size-16 align-middle"></i>}
                        </button>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default withRouter(Profile);
