import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import { resizeImage, setLoader } from "utils/common";
import { toast } from "react-toastify";
import { createCompanyImage, getCompanyImages } from "api/v1";
import Organization from "./Folders/Organization";
import Company from "./Folders/Company";
import Industry from "./Folders/Industry";

const Assets = () => {
  const [images, setImages] = useState({
    "organization": [],
    "company": [],
    "industry": []
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoader(loading)
  }, [loading])

  useEffect(() => {
    loadImages()
  }, [])

  async function loadImages() {
    setLoading(true)
    try {
      let _images = await getCompanyImages()
      let __images = {
        "organization": [],
        "company": [],
        "industry": []
      }
      _images.forEach(_image => __images[_image.content_type].push(_image))
      setImages(__images)

    } catch (error) {
      toast.error("Something went wrong loading images")
    }
    setLoading(false)
  }


  return (
    <React.Fragment >
      <div className="page-content">
        <MetaTags>
          <title>Assets</title>
        </MetaTags>

        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb back={true} title="Assets" />
          <Card>
            <CardBody>
              {images.organization.length > 0 && <Organization images={images.organization} setImages={setImages} />}
              {images.company.length > 0 && <Company images={images.company} setImages={setImages} />}
              {images.industry.length > 0 && <Industry images={images.industry} setImages={setImages} />}

              {
                !loading && <input
                  type="file"
                  // multiple
                  onChange={async (event) => {
                    let _image = await resizeImage(event.target.files[0])
                    setLoading(true)
                    try {
                      _image = await createCompanyImage({ image: _image })
                      setImages(_payload => ({ ..._payload, [_image.content_type]: [_image, ..._payload[_image.content_type]] }))
                    } catch (error) {
                      toast.error("Unable to add company image, please try again")
                    }
                    setLoading(false)
                  }}
                  role={"button"}
                  className='btn btn-sm'
                  accept="image/png, image/gif, image/jpeg"
                />
              }
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default withRouter(Assets);
