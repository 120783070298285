import React from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap'

function Terms({ show, close }) {
  return (
    <Modal centered isOpen={show} size="lg" toggle={close}>
      <ModalHeader tag="h5" toggle={close}>
        Terms and Conditions
      </ModalHeader>

      <ModalBody>
        <label>
          <b>PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS WEBSITE.</b>
          <br />
          <br />

          The following Terms and Conditions govern and apply to your use of or reliance upon this website
          maintained by
          ePaperSignSystem.com (the "Website”). Your access or use of the Website indicates that you have
          read, understand and
          agree to be bound by these Terms and Conditions and any other applicable laws, statutes and/or
          regulations. We may
          change these Terms and Conditions at any time without notice, effective upon its posting to the
          Website. Your continued
          use of the Website will be considered your acceptance to the revised Terms and Conditions.
          <br />
          <br />



          <b>1. INTELLECTUAL PROPERTY</b>
          <br />


          All intellectual property on the Website (except for User Generated Content, as defined below) is
          owned by us or our
          licensors, which includes materials protected by copyright, trademark, or patent laws. All
          trademarks, service marks and
          trade names are owned, registered and/or licensed by us. All content on the Website (except for
          User Generated Content,
          as defined below), including but not limited to text, software, code, designs, graphics, photos,
          sounds, music, videos,
          applications, interactive features and all other content is a collective work under Canadian and
          other copyright laws
          and is the proprietary property of the Company; All rights reserved.
          <br />
          <br />




          <b>2. USE OF COMPANY MATERIALS</b>
          <br />
          <br />

          We may provide you with certain information as a result of your use of the Website including, but
          not limited to,
          documentation, data, or information developed by us, and other materials which may assist in the
          use of the Website or
          Services ("Company Materials"). The Company Materials may not be used for any other purpose than
          the use of this Website
          and the services offered on the Website. Nothing in these Terms of Use may be interpreted as
          granting any licence of
          intellectual property rights to you.

          <br />
          <br />


          <b>3. USER GENERATED CONTENT</b>
          <br />
          <br />

          "User Generated Content" is communications, materials, information, data, opinions, photos,
          profiles messages, notes,
          website links, text information, music, videos, designs, graphics, sounds, and any other content
          that you and/or other
          Website users post or otherwise make available on or through the Website, except to the extent the
          content is owned by
          us.
          <br />
          <br />



          <b>4. ACCOUNT AND ACCOUNT USE</b>

          <br />
          <br />

          If your use of the Website requires an account identifying you as a user of the Website (an
          "Account"):

          a) you are solely responsible for your Account and the maintenance, confidentiality and security
          of your Account and all
          passwords related to your Account, and any and all activities that occur under your Account,
          including all activities of
          any persons who gain access to your Account with or without your permission;

          <br />
          <br />

          b) you agree to immediately notify us of any unauthorized use of your Account, any service
          provided through your Account
          or any password related to your Account, or any other breach of security with respect to your
          Account or any service
          provided through it, and you agree to provide assistance to us, as requested, to stop or remedy
          any breach of security
          related to your Account, and
          <br />


          c) you agree to provide true, current, accurate and complete customer information as requested by
          us from time to time
          and you agree to promptly notify us of any changes to this information as required to

          keep such information held by us current, complete and accurate.
          <br />
          <br />



          <b>5. PAYMENT</b>
          <br />
          <br />

          When you make a purchase on the Website, you agree to provide a valid instrument to make a
          payment. Pay attention to the
          details of the transaction, as your total price may include taxes, fees, and shipping costs, all
          of which you are
          responsible for.
          <br />
          When you provide a payment instrument to us, you confirm that you are permitted to use that
          payment instrument. When you
          make a payment, you authorize us (and our designated payment processor) to charge the full amount
          of the payment
          instrument you designate for the transaction. You also authorize us to collect and store that
          funding instrument, along
          with other related transaction information.

          <br />

          If you pay by credit or debit card, we may obtain a pre-approval from the issuer of the card for
          an amount as high as
          the full price. If you cancel a transaction before completion, this pre-approval may result in
          those funds not otherwise
          being immediately available to you.
          <br />


          We may cancel any transaction if we believe the transaction violates these Terms, or if we believe
          in doing so may
          prevent financial loss.
          <br />


          In order to prevent financial loss to you or to us, we may contact your funding instrument issuer,
          law enforcement, or
          affected third parties (including other users) and share details of any payments you are
          associated with, if we believe
          doing so may prevent financial loss or a violation of law.
          <br />


          Payment for any ongoing services is billed automatically until notification that you would like to
          terminate your access
          to the services.
          <br />
          <br />



          <b>6. SALE OF GOODS AND SERVICES</b>
          <br />
          <br />

          We may sell goods or services or allow third parties to sell goods or services on the Website. We
          undertake to be as
          accurate as possible with all information regarding the goods and services, including product
          descriptions and images.
          However, we do not guarantee the accuracy or reliability of any product information and you
          acknowledge and agree that
          you purchase such products at your own risk.
          <br />
          <br />



          <b>7. SHIPPING, DELIVERY AND RETURN POLICY</b>
          <br />
          <br />

          You agree to ensure payment for any items you may purchase from us and you acknowledge and affirm
          that prices are
          subject to change. When purchasing a physical good, you agree to provide us with a valid email and
          shipping address, as
          well as valid billing information. We reserve the right to reject or cancel an order for any
          reason, including errors or
          omissions in the information you provide to us. If we do so after payment has been processed, we
          will issue a refund to
          you in the amount of the purchase price. We may also request additional information from you prior
          to confirming a sale
          and we reserve the right to place any additional restrictions on the sale of any of our products.
          For the sale of
          physical products, we may preauthorize your credit or debit card at the time you place the order
          or we may simply charge
          your card upon shipment. You agree to monitor your method of payment. Shipment costs and dates are
          subject to change
          from the costs and dates you are quoted due to unforeseen circumstances.
          <br />


          For any questions, concerns, or disputes, you agree to contact us in a timely manner at the
          following:
          <br />
          <br />


          ePaperSignSystem
          <br />
          c/o Customer Service
          <br />
          1005 Skyview Drive
          <br />
          Burlington, ON L7P 5B1
          <br />
          info@epapersignsystem.com
          <br />


          If you are unhappy with anything you have purchased on our Website, you may do the following:
          <br />
          <br />

          Customers can send an email requesting a refund or simply return their product in the same
          condition as it was received.
          Additional charges may apply if returned inoperable or in damaged condition.

          <br />
          <br />


          <b>8. ACCEPTABLE USE</b>
          <br />
          <br />

          You agree not to use the Website for any unlawful purpose, or any purpose prohibited under this
          clause. You agree not to
          use the Website in any way that could damage the Website, the services or the general business of
          ePaperSignSystem.
          <br />


          You further agree not to use and/or access the Website:
          <br />
          <br />

          a) To harass, abuse, or threaten others or otherwise violate any person's legal rights;
          <br />
          <br />

          b) To violate any intellectual property rights of us or any third party;
          <br />
          <br />

          c) To upload or otherwise disseminate any computer viruses or other software that may damage the
          property of another:
          <br />
          <br />

          d) To perpetrate any fraud;

          <br />
          <br />

          e) To engage in or create any unlawful gambling, sweepstakes, or pyramid scheme;
          <br />
          <br />

          f) To publish or distribute any obscene or defamatory material;

          <br />
          <br />
          g) To publish or distribute any material that incites violence, hate or discrimination towards any
          group;

          <br />
          <br />
          h) To unlawfully gather information about others.
          <br />
          <br />



          <b>9. PROTECTION OF PRIVACY</b>
          <br />
          <br />

          Through your use of the Website, you may provide us with certain information. By using the
          Website, you authorize us to
          use your information in Canada and any other country where We may operate.
          <br />


          When you register for an account, you provide us with a valid email address and may provide us
          with additional
          information, such as your name and/or billing information. Depending on how you use our Website,
          we may also receive
          information from external applications you use to access our Website, or we may receive
          information through various web
          technologies, such as cookies, log files, clear gifs, web beacons or others.
          <br />


          We use the information gathered from you to ensure your continued good experience on our website,
          including through
          email communication. We may also track certain of the passive information received to improve our
          marketing and
          analytics, and for this, we may work with third-party providers.
          <br />


          If you would like to disable our access to any passive information we receive from the use of
          various technologies, you
          may choose to disable cookies in your web browser. Please be aware that we will still receive
          information about you that
          you have provided, such as your email address.
          <br />


          If you choose to terminate your account, we will store information about you for a reasonable
          period of time to be
          determined by us in accordance with applicable federal and provincial laws. After this period, all
          information about you
          will be deleted.
          <br />
          <br />



          <b>10. REVERSE ENGINEERING &amp; SECURITY</b>

          <br />
          <br />

          You may not undertake any of the following actions:
          <br />
          <br />

          a) Reverse engineer, or attempt to reverse engineer or disassemble any code or software from or on
          the Website;
          <br />
          <br />

          b) Violate the security of the Website through any unauthorized access, circumvention of
          encryption or other security
          tools, data mining or interference to any host, user or network.
          <br />
          <br />




          <b>11. DATA LOSS</b>
          <br />
          <br />

          We are not responsible for the security of your Account or Content. Your use of the Website is at
          your own risk.

          <br />
          <br />


          <b>12. INDEMNIFICATION</b>
          <br />
          <br />

          You defend and indemnify ePaperSignSystem.com and any of its affiliates and hold us harmless
          against any and all legal
          claims and demands, including reasonable attorney's fees, which may arise from or relate to your
          use or misuse of the
          Website, your breach of these Terms and Conditions, or your conduct or actions. We will select our
          own legal counsel and
          may participate in our own defense, if we wish to do so.
          <br />
          <br />



          <b>16. SERVICE INTERRUPTIONS</b>
          <br />

          <br />
          We may need to interrupt your access to the Website to perform maintenance or emergency services
          on a scheduled or
          unscheduled basis. You agree that your access to the Website may be affected by unanticipated or
          unscheduled downtime,
          for any reason, but that the we will have no liability for any damage or loss caused as a result
          of such downtime.
          <br />

          <br />


          <b>17. TERMINATION OF ACCOUNT</b>
          <br />
          <br />

          We may, in our sole discretion, suspend, restrict or terminate your Account and your use of the
          Website, effective at
          any time, without notice to you, for any reason, including because the operation or efficiency of
          the Website or our or
          any third party's equipment or network is impaired by your use of the Website, any amount is past
          due from you to us, we
          have received a third party complaint which relates to your use or misuse of the Website, or you
          have been or are in
          breach of any term or condition of these Terms and Conditions. We will have no responsibility to
          notify any third party,
          including any third-party providers of services, merchandise or information, of any suspension,
          restriction or
          termination of your access to the Website.

          <br />
          <br />


          <b>18. NO WARRANTIES</b>

          <br />
          <br />
          Your use of the Website is at your sole and exclusive risk and any services provided by us are on
          an "as is" basis. We
          disclaim any and all express or implied warranties of any kind, including, but not limited to the
          implied warranty of
          fitness for a particular purpose and the implied warranty of merchantability. We make no
          warranties that the Website
          will meet your needs or that the Website will be uninterrupted, error-free, or secure. We also
          make no warranties as to
          the reliability or accuracy of any information on the Website or obtained through the Services.
          Any damage that may
          occur to you, through your computer system, or as a result of loss of your data from your use of
          the Website is your
          sole responsibility and we are not liable for any such damage or loss.
          <br />


          <br />

          <b>19. PRIVACY</b>
          <br />
          <br />

          Internet communications are subject to interception, loss or alteration and, as consequences, you
          acknowledge that
          information or data you provide by electronic means by accessing or using this Website are not
          confidential or
          exclusive, except to the extent required by the applicable laws, and that communications by email
          may be intercepted,
          altered or lost. For more information, please refer to our Privacy Policy, which you may find at
          the following address:
          https://www.epapermadeeasy.com/privacy
          <br />
          <br />




          <b>20. LIMITATION ON LIABILITY</b>

          <br />
          <br />
          We are not liable for any damages that may occur to you as a result of your use of the Website, to
          the fullest extent
          permitted by law. The maximum liability of ePaperSignSystem.com arising from your use of the
          Website is limited to one
          hundred ($100) Canadian Dollars. This applies to any and all claims by you, including, but not
          limited to, lost profits
          or revenues, consequential or punitive damages, negligence, strict liability, fraud, or torts of
          any kind.
        </label>
      </ModalBody>

      <ModalFooter>
        <Button color="secondary" onClick={close}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Terms