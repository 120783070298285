import PropTypes from "prop-types";
import React, { Fragment, useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const SidebarContent = props => {
  const { company } = useSelector(state => ({
    company: state.company.company,
  }));

  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  const _isSubscriptionActive = (company?.is_trial_available && company?.remaining_trial_days > 0) || company?.is_subscription_active

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/dashboard" className="">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            {
              _isSubscriptionActive &&
              <li>
                <Link to="/users" className="">
                  <i className="bx bx-user"></i>
                  <span>{props.t("Users")}</span>
                </Link>
              </li>
            }

            {
              _isSubscriptionActive &&
              <li>
                <Link to="/devices" className="">
                  <i className="bx bx-devices"></i>
                  <span>{props.t("Devices")}</span>
                </Link>
              </li>
            }

            <li>
              <Link to="/assets">
                <i className="bx bx-file"></i>
                <span>{props.t("Assets")}</span>
              </Link>
            </li>


            {
              _isSubscriptionActive &&
              <Fragment>
                <li>
                  <Link to="/schedules" className="">
                    <i className="bx bx-task"></i>
                    <span>{props.t("Schedules")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/deploy" className="">
                    <i className="mdi mdi-send-outline"></i>
                    <span>{props.t("Deploy")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/playlists" className="">
                    <i className="bx bx-play-circle"></i>
                    <span>{props.t("Playlist")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/events" className="">
                    <i className="bx bxs-calendar-event"></i>
                    <span>{props.t("Events")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/calendar" className="">
                    <i className="bx bx-calendar"></i>
                    <span>{props.t("Calendar")}</span>
                  </Link>
                </li>
              </Fragment>
            }
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
