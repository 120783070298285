import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";

import { setLoader } from "utils/common";
import { toast } from "react-toastify";
import { deleteCompanyImage } from "api/v1";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const Industry = ({ images, setImages }) => {
  const [loading, setLoading] = useState(false)
  const [photoIndex, setphotoIndex] = useState(-1);

  useEffect(() => {
    setLoader(loading)
  }, [loading])

  async function deleteImage(id) {
    setLoading(true)
    try {
      await deleteCompanyImage(id)
      setImages(_payload => ({ ..._payload, "industry": images.filter((_image) => id != _image.id) }))

    } catch (error) {
      toast.error("Something went wrong while deleting image")
    }
    setLoading(false)
  }


  return (
    <React.Fragment >
      {photoIndex > -1 && images.length > 0 ? (
        <Lightbox
          mainSrc={images[photoIndex].image}
          nextSrc={images[(photoIndex + 1) % images.length].image}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].image}
          onCloseRequest={() => {
            setphotoIndex(-1);
          }}
          onMovePrevRequest={() => {
            setphotoIndex((photoIndex + images.length - 1) % images.length);
          }}
          onMoveNextRequest={() => {
            setphotoIndex((photoIndex + 1) % images.length);
          }}
        />
      ) : null}

      <Row className="mb-2">
        <div className="col-xs-12">
          <h4 className="mb-3">Industry</h4>
          {images.length == 0 && <p className="mb-3">{"No images are found"}</p>}

          <Row>
            {
              images.map((image, _index) =>
                <Col className="mb-2 position-relative" key={`gallary-image-${_index}`} xs="12" md="3">
                  {
                    image.is_deletable &&
                    <button disabled={loading} onClick={() => deleteImage(image.id)} type='button' className="btn btn-danger btn-xs position-absolute py-0 end-0">
                      <i className="fas fa-times"></i>
                    </button>
                  }
                  <img
                    onClick={() => setphotoIndex(_index)}
                    src={image.image}
                    className="w-100" style={{
                      height: 'auto'
                    }} />
                </Col>
              )
            }
          </Row>
        </div>
      </Row>
    </React.Fragment >
  );
};

export default Industry
