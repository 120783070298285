const INIT_STATE = {
  loading: false,
  company: null
};

const companys = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'GET_COMPANY':
      return {
        ...state,
        loading: true
      };

    case 'COMPANY_COMPLETE':
      return {
        ...state,
        loading: false
      };

    case 'SET_COMPANY':
      return {
        ...state,
        company: action.payload.company
      };

    case 'EDIT_COMPANY':
      return {
        ...state,
        loading: true
      };

    default:
      return state;
  }
};

export default companys;
