import { filterParams } from "../apiHelper";
import request from "../restClient";

export const login = async (data) => {
  return await request({ url: "authenticate/", method: "POST", data });
};

export const signup = async (data) => {
  let { uidb64, token, ...formdata } = data
  return await request({ url: `signup/${uidb64}/${token}`, method: "POST", data: formdata });
};

export const join = async (data) => {
  return await request({ url: "join/", method: "POST", data });
};

export const countries = async (params) => {
  return await request({
    url: "countries/",
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const industries = async (params) => {
  return await request({
    url: "industries/",
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const provinces = async (countryId, params) => {
  return await request({
    url: `countries/${countryId}/provinces`,
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const confirmUser = async (data) => {
  let { uidb64, token, ...formdata } = data
  return await request({ url: `confirm_user/${uidb64}/${token}`, method: "POST", data: formdata });
};

export const tfa = async (data) => {
  let { uidb64, token, ...formdata } = data
  return await request({ url: `two_factor_authenticate/${uidb64}/${token}`, method: "POST", data: formdata });
};

export const requestPasswordChange = async (data) => {
  return await request({ url: "forget_password/", method: "POST", data });
};

export const verifyPasswordChange = async (data) => {
  let { uidb64, token, ...formdata } = data
  return await request({ url: `verify_confirmation_token/${uidb64}/${token}`, method: "POST", data: formdata });
};

export const passwordChange = async (data) => {
  let { uidb64, token, ...formdata } = data
  return await request({ url: `reset_password/${uidb64}/${token}`, method: "POST", data: formdata });
};
