import { deleteCompanyImage } from 'api/v1';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { setTabBarLoader, useHover } from 'utils/common';

function Image({ image, setItems = null, isDeletable = false, deleteImage = null }) {
  const [hoverRef, isHovered] = useHover();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setTabBarLoader(loading)
  }, [loading])


  async function _deleteImage(id) {
    setLoading(true)
    try {
      await deleteCompanyImage(id)
      deleteImage(id)
    } catch (error) {
      toast.error("Something went wrong while deleting image")
    }
    setLoading(false)
  }


  useEffect(() => {
    if (isHovered)
      hoverRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" })

  }, [isHovered])

  return (
    <div className="position-relative">
      {
        setItems &&
        <button onClick={() => setItems(_items =>
          _items.map(_item => _item.id == image.id ? { dragKey: _item.dragKey } : _item)
        )} type='button' className="btn btn-danger btn-xs position-absolute rounded-circle p-1 py-0 end-0">
          <i className="fas fa-times"></i>
        </button>
      }
      {
        isDeletable &&
        <button disabled={loading} onClick={() => _deleteImage(image.id)} type='button' className="btn btn-danger btn-xs position-absolute rounded-circle p-1 py-0 end-0">
          <i className="fas fa-times"></i>
        </button>
      }
      <img ref={hoverRef} src={image.image}
        className="img-thumbnail"
      />
    </div>
  )
}

export default Image
