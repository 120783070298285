import React, { useEffect, useState } from 'react'
import { setTabBarLoader } from 'utils/common'

function Screens({
  subscription,
  setSubscription,
  timeline,
  goForward
}) {
  const [loading, setLoading] = useState(false)
  const { total_screens: screens } = subscription

  useEffect(() => {
    setTabBarLoader(loading)
  }, [loading])

  return (
    <div>
      {timeline}

      <div className='text-center mt-5 mb-2'>
        <div className='my-3 mx-auto text-light bg-primary rounded-circle large-circle'>
          {screens}
        </div>

        <div className="btn-group" role="group">
          <button onClick={() => {
            setSubscription({ ...subscription, total_screens: screens - 1 })
          }} disabled={screens == 1} type="button" className="font-size-18 btn btn-primary">-</button>
          <button disabled type="button" className="font-size-18 btn btn-light px-4">{screens}</button>
          <button onClick={() => {
            setSubscription({ ...subscription, total_screens: screens + 1 })
          }} type="button" className="font-size-18 btn btn-primary">+</button>
        </div>

        <div className="text-end mt-3">
          <button onClick={goForward} type="button" className="btn btn-primary px-4">Next</button>
        </div>
      </div>
    </div>
  )
}

export default Screens