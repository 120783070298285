import React, { Fragment, useEffect, useState } from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";

import CardSection from "./CardSection";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { cadFormat, setTabBarLoader } from "utils/common";

function CheckoutForm({ cart, ...props }) {
  let { profile } = useSelector(state => ({
    profile: state.profiles.profile.profile
  }))

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setTabBarLoader(loading)
  }, [loading])

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true)

    const { stripe, elements } = props;
    if (!stripe || !elements) {
      return;
    }

    try {
      const result = await stripe.confirmCardPayment(cart.client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: profile.first_name + " " + profile.last_name,
          },
        },
        setup_future_usage: 'off_session'
      });

      if (result.error) {
        toast.error(result.error.message)
      } else {
        props.onComplete()
      }
    } catch (error) {
      toast.error("Something went wrong!")
    }

    setLoading(false)
  };

  if (!cart)
    return <Fragment />

  return (

    <form onSubmit={handleSubmit}>
      <CardSection />
      <button disabled={!props.stripe || loading} className="btn btn-primary w-100">
        {
          loading ?
            <i className="bx bx-hourglass bx-spin font-size-16 align-middle"></i>
            : `Pay ${cadFormat(cart.price.amount || 0)}`
        }
      </button>
    </form>
  );
}

export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} {...props} />
      )}
    </ElementsConsumer>
  );
}
