import CompanyAssets from "components/CompanyAssets";
import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Row,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap";
import AsyncSelect from 'react-select/async';
import { getDevices } from "api/v1";

function index({
  validation,
  sendableLoading = false,
  sendable = false,
  target = null
}) {
  let edit = !!target
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems([{ dragKey: 1, ...(target?.image || {}) }]);
  }, [])

  useEffect(() => {
    if (items[0])
      validation.setFieldValue('image', items[0].id || null)
  }, [items])

  let loadDevices = (search) => {
    return getDevices({ search, no_paginate: 1 })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col xs="12" className="mb-3">
          <Label className="form-label">Name</Label>
          <Input
            name="name"
            type="text"
            placeholder="Enter Name"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.name || ""}
            invalid={
              validation.touched.name && validation.errors.name ? true : false
            }
          />
          {validation.touched.name && validation.errors.name ? (
            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
          ) : null}
        </Col>

        {
          sendable &&
          <Col xs="12" className="mb-3">
            <Label className="form-label">Select Devices</Label>
            <AsyncSelect
              defaultOptions
              isDisabled={edit}
              placeholder="Select Devices"
              required
              isMulti
              value={validation.values.devices}
              getOptionLabel={({ name }) => `${name}`}
              getOptionValue={({ id }) => id}
              loadOptions={loadDevices}
              onChange={(_devices) => {
                validation.setFieldValue('devices', _devices)
              }}

            />
            {validation.touched.devices && validation.errors.devices ? (
              <span className="text-danger font-size-11">{validation.errors.devices}</span>
            ) : null}
          </Col>
        }

        {
          !sendable &&
          <Fragment>
            <Col xs="12" className="mb-3">
              <Label className="form-label">Select Device</Label>
              <AsyncSelect
                defaultOptions
                isDisabled={edit}
                placeholder="Select Device"
                required
                value={validation.values.device}
                getOptionLabel={({ name }) => `${name}`}
                getOptionValue={({ id }) => id}
                loadOptions={loadDevices}
                onChange={(_device) => {
                  validation.setFieldValue('device', _device)
                }}

              />
              {validation.touched.device && validation.errors.device ? (
                <span className="text-danger font-size-11">{validation.errors.device}</span>
              ) : null}
            </Col>

            <Col xs="12" className="mb-3">
              <Label className="form-label">Display From</Label>
              <Input
                name="display_from"
                type="datetime-local"
                disabled={edit}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.display_from || ""}
                invalid={
                  validation.touched.display_from && validation.errors.display_from ? true : false
                }
              />
              {validation.touched.display_from && validation.errors.display_from ? (
                <FormFeedback type="invalid">{validation.errors.display_from}</FormFeedback>
              ) : null}
            </Col>

            <Col xs="12" className="mb-3">
              <Label className="form-label">Display To</Label>
              <Input
                name="display_to"
                type="datetime-local"
                disabled={edit}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.display_to || ""}
                invalid={
                  validation.touched.display_to && validation.errors.display_to ? true : false
                }
              />
              {validation.touched.display_to && validation.errors.display_to ? (
                <FormFeedback type="invalid">{validation.errors.display_to}</FormFeedback>
              ) : null}
            </Col>
          </Fragment>
        }

        <Col xs="12" className="mb-3">
          <Label className="form-label">Image</Label>

          <CompanyAssets items={items} setItems={setItems} />
          {
            validation.touched.image && validation.errors.image ? (
              <span className="text-danger font-size-11">{validation.errors.image}</span>
            ) : null
          }
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-end">
            <button
              disabled={sendableLoading}
              type="submit"
              className="btn btn-success save-customer"
            >
              {sendableLoading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default index
