import MetaTags from "react-meta-tags";
import React from "react";
import {
  Card,
  CardBody,
  Container
} from "reactstrap";


import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import Send from 'pages/Schedules/Send'

const Deploy = () => {

  return (
    <React.Fragment >
      <div className="page-content">
        <MetaTags>
          <title>Deploy</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb back={true} title="Deploy" breadcrumbItem="Schedules" />
          <Card>
            <CardBody>
              <Send />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default withRouter(Deploy);
