import {
  GET_PLAYLISTS,
  GET_PLAYLISTS_SUCCESS,
  GET_PLAYLISTS_FAIL,
  DELETE_PLAYLIST,
  ADD_PLAYLIST,
  EDIT_PLAYLIST
} from "./actionTypes"

export const getPlaylists = (loading = false, params = {}, deviceId = null) => ({
  type: GET_PLAYLISTS,
  payload: { loading, params, deviceId }
})

export const getPlaylistsSuccess = playlists => ({
  type: GET_PLAYLISTS_SUCCESS,
  payload: playlists,
})

export const getPlaylistsFail = error => ({
  type: GET_PLAYLISTS_FAIL,
  payload: error,
})

export const deletePlaylist = playlist => ({
  type: DELETE_PLAYLIST,
  payload: { playlist }
})

export const addPlaylist = (playlist, actions) => {
  return {
    type: ADD_PLAYLIST,
    payload: { playlist, actions },
  }
}

export const editPlaylist = (id, playlist, actions) => {
  return {
    type: EDIT_PLAYLIST,
    payload: { id, playlist, actions },
  }
}

export const newPlaylistModal = () => ({
  type: 'NEW_PLAYLIST_MODAL'
})

export const deletePlaylistModal = () => ({
  type: 'DELETE_PLAYLIST_MODAL'
})

export const editPlaylistModal = () => ({
  type: 'EDIT_PLAYLIST_MODAL'
})


export const getPlaylist = (id) => {
  return {
    type: 'GET_PLAYLIST',
    payload: { id },
  }
}

export const getPlaylistSuccess = device => ({
  type: 'GET_PLAYLIST_SUCCESS',
  payload: device,
})