import React, { useEffect } from "react";
import { Alert, Input, Label, Form, FormFeedback } from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { joinUser } from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import { setLoader } from "utils/common";
import { Fragment } from "react";


const Join = props => {
  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Please enter your email")
    }),
    onSubmit: (values, actions) => {
      dispatch(joinUser(values, actions));
    }
  });

  const { registrationSuccess, registrationError, loading } = useSelector((state) => state.Account);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  return (
    <Fragment>
      <h5 className="text-center mb-4">Please enter your email to set up your account</h5>
      <Form
        className="form-horizontal"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        {registrationSuccess && registrationSuccess ? (
          <Alert color="success">
            {registrationSuccess}
          </Alert>
        ) : null}

        {registrationError && registrationError ? (
          <Alert color="danger">{registrationError}</Alert>
        ) : null}
        <div className="mb-3">
          <Label className="form-label">Email</Label>
          <Input
            id="email"
            name="email"
            className="form-control"
            placeholder="Enter email"
            type="email"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.email || ""}
            invalid={
              validation.touched.email && validation.errors.email ? true : false
            }
          />
          {validation.touched.email && validation.errors.email ? (
            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
          ) : null}
        </div>

        <div className="mt-4">
          <button
            className="btn btn-primary d-block w-100"
            type="submit"
          >
            Join
          </button>
        </div>

        <div className="mt-3 text-center">
          <p>
            Already have an account ?{" "}
            <Link to="/login" className="font-weight-medium text-primary">
              {" "}
              Login
            </Link>{" "}
          </p>
        </div>
      </Form>
    </Fragment>
  );
};

export default Join;
