import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Badge, Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  getDevice
} from "store/devices/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";


import { getThumbnail, setLoader } from "utils/common";
import { round } from "lodash";
// import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Schedules from "pages/Schedules/Scheules";
import Playlists from "pages/Playlists/Playlists";
//redux

const Device = props => {
  // const [photoIndex, setphotoIndex] = useState(-1);
  const {
    match: { params },
  } = props;

  useEffect(() => {
    dispatch(getDevice(params.id))
  }, [])

  const dispatch = useDispatch();

  const { device, loading } = useSelector(state => ({
    device: state.devices.device,
    loading: state.devices.loading,
  }));

  useEffect(() => {
    setLoader(loading)
  }, [loading])

  if (!device)
    return (<Fragment />)

  return (
    <div className="page-content">
      <MetaTags>
        <title>
          Device | ePaperSignSystem
        </title>
      </MetaTags>
      <Container fluid>
        {/* {photoIndex > -1 && device.images.length > 0 ? (
          <Lightbox
            mainSrc={device.images[photoIndex].image}
            nextSrc={device.images[(photoIndex + 1) % device.images.length].image}
            prevSrc={device.images[(photoIndex + device.images.length - 1) % device.images.length].image}
            onCloseRequest={() => {
              setphotoIndex(-1);
            }}
            onMovePrevRequest={() => {
              setphotoIndex((photoIndex + device.images.length - 1) % device.images.length);
            }}
            onMoveNextRequest={() => {
              setphotoIndex((photoIndex + 1) % device.images.length);
            }}
          />
        ) : null} */}

        <Breadcrumbs back={true} title="Devices" breadcrumbItem="Device" />
        <Card>
          <CardBody>
            <h5 className="mb-3">Device Details</h5>

            <Row>
              <Col xs="12" className="mb-5">
                <div className="d-flex flex-wrap align-items-center border border-dark rounded py-2">
                  <div className="mt-2 mb-3 px-4">
                    <label>Serial No</label>
                    <div>{device.serial_no}</div>
                  </div>

                  <div className="mt-2 mb-3 px-4">
                    <label>Name</label>
                    <div>{device.name}</div>
                  </div>

                  <div className="mt-2 mb-3 px-4">
                    <label>Connection Type</label>
                    <div>{device.info.comms_type}</div>
                  </div>

                  <div className="mt-2 mb-3 px-4">
                    <label>Status</label>

                    <div>
                      <Badge className={"bg-primary"}>
                        {device.info.state}
                      </Badge>
                    </div>
                  </div>

                  <div className="mt-2 mb-3 px-4">
                    <label>Hearbeat</label>
                    <div>{device.config?.heartbeat_default_value || 0}s</div>
                  </div>

                  <div className="mt-2 mb-3 px-4">
                    <label>Battery Voltage</label>
                    <div className="text-success">{device.info.battery_voltage}V</div>
                  </div>

                  <div className="mt-2 mb-3 px-4">
                    <label>Temperature</label>
                    <div>{round(device.info.temperature, 2)}℃</div>
                  </div>

                  <div className="mt-2 mb-3 px-4">
                    <label>Last disconnected</label>
                    <div>{device.info.last_disconnection_time}</div>
                  </div>

                  <div className="mt-2 mb-3 px-4">
                    <label>Expected activation</label>
                    <div>{device.info.expected_activation_time}</div>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Row>
          <div className="col-xs-12 col-md-4">
            <Card>
              <CardBody>
                <h4 className="my-1">{device?.active_image ? "" : "No"} Active Image</h4>

                <div className="mt-2 mb-2">
                  <img src={getThumbnail(device?.active_image)} style={{
                    maxWidth: '70%',
                    height: 'auto',
                    border: 'solid 10px'
                  }} />

                  <Row className="mt-2">
                    <div className="d-flex col-6 mb-1 align-items-baseline">
                      <i className="fas fa-undo text-dark me-2" />
                      <span className="text-dark">{device.info.displays[0]?.rotation || 90} deg</span>
                    </div>

                    <div className="d-flex col-6 mb-1 align-items-baseline">
                      <i className="fas fa-mobile text-dark me-2" />
                      <span className="text-dark">{device.info.displays[0]?.device_dispid || 0} (physical)</span>
                    </div>

                    <div className="d-flex col-6 mb-1 align-items-baseline">
                      <i className="fas fa-arrows-alt text-dark me-2" />
                      <span className="text-dark">{device.info.displays[0]?.res_x || 0}x{device.info.displays[0]?.res_y || 0}</span>
                    </div>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="col-xs-12 col-md-8">
            <Schedules deviceId={device.id} />
          </div>

          <div className="col-xs-12">
            <Playlists deviceId={device.id} />
          </div>
        </Row>

        {/* <div className="col-xs-12 col-md-8">
                <h5 className="my-2">{device.images.length > 0 ? "Image Gallery" : "No images are found for gallery"}</h5>

                <Row>
                  {
                    device.images.map((_image, _index) =>
                      <Col onClick={() => setphotoIndex(_index)} key={`gallary-image-${_index}`} xs="12" md="3">
                        <img
                          src={_image.image}
                          className="w-100" style={{
                            height: 'auto'
                          }} />
                      </Col>
                    )
                  }
                </Row>
              </div> */}
      </Container >
    </div >
  );
};

Device.propTypes = {
  match: PropTypes.object,
};

export default withRouter(Device);
