import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, CardBody, Card, Container } from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

import Preloader from "components/Common/Preloader";
import { resetRegister } from "store/actions";
import Register from './Register'
import Join from "./Join";
import Otp from './Otp'
import logo from "../../assets/images/logo.png";

const RegisterControl = () => {
  const dispatch = useDispatch();
  const { state } = useSelector((state) => state.Account);

  useEffect(() => {
    dispatch(resetRegister())
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Sign up | ePaperSignSystem</title>
      </MetaTags>

      <section className="auth-wrapper">
        <Preloader />
        <div className="min-vh-100 d-flex align-items-center">
          <Container className="py-3">
            <Row className="justify-content-center">
              <Col xl={7}>
                <Card>
                  <CardBody>
                    <div className="p-4 text-center">
                      <div className="text-center mb-3">
                        <img
                          src={logo}
                          alt=""
                          height="65"
                        />
                      </div>
                      <h4>Sign Up</h4>
                    </div>
                    <div className="px-2 pb-2">
                      {
                        state == 'join' ?
                          <Join />
                          : state == 'otp' ?
                            <Otp register />
                            : <Register />
                      }
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
};

export default RegisterControl;
