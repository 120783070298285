import React from 'react';
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from '@dnd-kit/sortable';

import { SortableItem } from 'components/Sortable/SortableItem';
import { Col, Row } from 'reactstrap';
import {
  restrictToWindowEdges
} from '@dnd-kit/modifiers';
import CompanyImages from 'components/CompanyImages';

export default function CompanyAssets({ items, setItems }) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      }
    }))

  return (
    <DndContext
      modifiers={[restrictToWindowEdges]}
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        disabled={items.length == 1}
        items={items.map(_item => _item.dragKey)}
        strategy={rectSortingStrategy}
      >
        <Row>
          {items.map(item => <Col key={`key-sortable-${item.dragKey}`} xs="4"><SortableItem setItems={setItems} item={item} /></Col>)}
        </Row>

        <Row>
          <CompanyImages bucketImages={items} />
        </Row>
      </SortableContext>
    </DndContext>
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.map(_item => _item.dragKey).indexOf(active.id);
        const newIndex = items.map(_item => _item.dragKey).indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }
}