import {
  GET_CEVENTS,
  GET_CEVENTS_SUCCESS,
  GET_CEVENTS_FAIL,
  DELETE_CEVENT,
  ADD_CEVENT,
  EDIT_CEVENT
} from "./actionTypes"

export const getCevents = (loading = false, params = {}, deviceId = null) => ({
  type: GET_CEVENTS,
  payload: { loading, params, deviceId }
})

export const getCeventsSuccess = cevents => ({
  type: GET_CEVENTS_SUCCESS,
  payload: cevents,
})

export const getCeventsFail = error => ({
  type: GET_CEVENTS_FAIL,
  payload: error,
})

export const deleteCevent = cevent => ({
  type: DELETE_CEVENT,
  payload: { cevent }
})

export const addCevent = (cevent, actions) => {
  return {
    type: ADD_CEVENT,
    payload: { cevent, actions },
  }
}

export const editCevent = (id, cevent, actions) => {
  return {
    type: EDIT_CEVENT,
    payload: { id, cevent, actions },
  }
}

export const newCeventModal = () => ({
  type: 'NEW_CEVENT_MODAL'
})

export const deleteCeventModal = () => ({
  type: 'DELETE_CEVENT_MODAL'
})

export const editCeventModal = () => ({
  type: 'EDIT_CEVENT_MODAL'
})
