import {
  GET_EVENTS,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAIL,
} from "./actionTypes"

export const getEvents = (params = {}, loading = false) => ({
  type: GET_EVENTS,
  payload: { loading, params }
})

export const getEventsSuccess = events => ({
  type: GET_EVENTS_SUCCESS,
  payload: events,
})
export const getEventsFail = error => ({
  type: GET_EVENTS_FAIL,
  payload: error,
})
