import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
} from "./actionTypes"

const initialState = {
  registrationError: null,
  registrationSuccess: null,
  loading: false,

  state: 'join',
  email: '',
  token: '',
  uidb64: ''
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      state = {
        ...state,
        loading: true,
        registrationError: null,
        registrationSuccess: null
      }
      break
    case 'CONFIRM_USER':
      state = {
        ...state,
        loading: true,
        registrationSuccess: null,
        registrationError: null
      }
      break
    case 'JOIN_USER':
      state = {
        ...state,
        loading: true,
        registrationSuccess: null,
        registrationError: null,
      }
      break
    case 'RESET_REGISTER':
      state = {
        ...state,
        loading: false,
        registrationSuccess: null,
        registrationError: null,
        state: 'join',
        email: '',
        token: '',
        uidb64: ''
      }
      break
    case 'CHANGE_STATE':
      state = {
        ...state,
        state: action.payload
      }
      break
    case 'SET_TOKENS':
      state = {
        ...state,
        email: action.payload.email || state.email,
        token: action.payload.token,
        uidb64: action.payload.uidb64
      }
      break
    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        registrationSuccess: action.payload,
        registrationError: null,
      }
      break
    case REGISTER_USER_FAILED:
      state = {
        ...state,
        loading: false,
        registrationError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default account
