import request from "../restClient";

export const getCompany = async () => {
  return await request({
    url: "company/",
    method: "GET"
  });
};

export const getCompanyImages = async () => {
  return await request({
    url: "assets",
    method: "GET"
  });
};

export const deleteCompanyImage = async (id) => {
  return await request({
    url: `assets/${id}/`,
    method: "DELETE"
  });
};

export const createCompanyImage = async (data) => {
  const formD = new FormData()

  Object.entries(data).forEach((keyValue) => {
    formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: "assets",
    method: "POST",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};

export const editCompany = async (_data) => {
  const formD = new FormData()

  Object.entries(_data).forEach((keyValue) => {
    if (keyValue[0] != "logo")
      formD.append(keyValue[0], keyValue[1])
    else if (typeof (keyValue[1]) != "string" && keyValue[1])
      formD.append(keyValue[0], keyValue[1])
  })
  return await request({
    url: `company/`,
    method: "PATCH",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};

export async function getPlans(data) {
  return request({
    url: `plans/calculate`,
    method: 'post',
    data
  });
}

export async function createPlan(data) {
  return request({
    url: `plans`,
    method: 'post',
    data
  });
}

export async function cancelPlan() {
  return request({
    url: `plans/cancel`,
    method: 'delete',
  });
}
