import React, { useEffect, useState } from "react";
import { editPlaylist, getPlaylist } from "api/v1";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from 'pages/Playlists/common'
import Loader from 'common/Loader'

import moment from "moment";

function index({ id, close, onSuccess }) {
  const modal = !!id
  let [playlist, setPlaylist] = useState()
  const [loading, setLoading] = useState(false)

  async function loadPlaylist() {
    setLoading(true)
    try {
      let _playlist = await getPlaylist(id)
      setPlaylist(_playlist)
    } catch (error) {
      toast.error("Something went wrong loading playlist")
      close()
    }
    setLoading(false)
  }

  useEffect(() => {
    if (modal) {
      loadPlaylist()
    } else {
      setTimeout(() => setPlaylist(null), 1000)
    }
  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (playlist && playlist.name) || "",
      start_time: (playlist && moment(new Date(playlist.start_time)).format('YYYY-MM-DDTHH:mm:ss')) || '',
      duration: (playlist && playlist.duration) || "",
      interval: (playlist && playlist.interval) || "",
      device: (playlist && playlist.device) || null,
      images: (playlist && playlist.images?.map(_img => _img.id)) || [],
      imagesLength: (playlist && playlist.images?.length)
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      start_time: Yup.string().required("Please select start time"),
      duration: Yup.string().required("Please select duration"),
      interval: Yup.string().required("Please select interval"),
      device: Yup.mixed().required("Please select device"),
      images: Yup.array().
        when(['duration', 'interval', 'imagesLength'], {
          is: (duration, interval, imagesLength) => (duration && interval && imagesLength < (parseInt(duration) * 60) / parseInt(interval)),
          then: Yup.array().min(13, "Please drag all images").required("required"),
          otherwise: Yup.array()
        })
        .required("Please drag images")
    }),
    onSubmit: async (values, actions) => {
      setLoading(true)
      try {
        await editPlaylist(playlist.id, {
          ...values,
          device: values.device.id,
          duration: parseFloat(values.duration),
          interval: parseFloat(values.interval),
          start_time: moment(values.start_time, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DDTHH:mm:ssZ"),
        })
        onSuccess()
      } catch ({ errors }) {
        if (errors) {
          actions.setErrors(errors)
        }
        if (errors?.non_field_errors)
          toast.error(errors.non_field_errors[0]);
        else
          toast.error("Something went wrong!");
      }
      setLoading(false)
    },
  });

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={modal} toggle={close}>
        <ModalHeader tag="h4" toggle={close}>
          Edit Playlist
        </ModalHeader>
        <ModalBody>
          {
            !playlist && loading ? <Loader />
              : playlist ?
                <Form
                  loading={loading}
                  target={playlist}
                  validation={validation}
                />
                : null
          }
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
