import React from "react"
import { useDrop } from "react-dnd"

export default function Bucket({ item: content, children, setItems }) {
  const [{ isOver }, drop] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: 'BOX',
    // Props to collect
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
    drop: (droppedItem) => setItems(items => items.map(_item => _item.dragKey == content.dragKey ? { ..._item, ...droppedItem } : _item))
  }))

  return (
    <div className="draggable-content mt-1 rounded"
      ref={drop}
      role={'Dustbin'}
      style={{ opacity: isOver ? 0.5 : 1 }}
    >
      {isOver || !content.image ? <div className="bg-light text-center draggable-content px-2 d-flex justify-content-center align-items-center rounded border">
        <h6 className="font-size-12">Drag image here</h6>
      </div> : children}
    </div>
  )
}