import {
  GET_SCHEDULES_FAIL,
  GET_SCHEDULES_SUCCESS,
  GET_SCHEDULES,
  ADD_SCHEDULE,
  EDIT_SCHEDULE,
  DELETE_SCHEDULE,
} from "./actionTypes";

const INIT_STATE = {
  scheduleWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  send: false,
  new: false,
  edit: false,
  delete: false,
  loading: false,
};

const schedules = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SCHEDULES:
      return {
        ...state,
        new: false,
        send: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case GET_SCHEDULES_SUCCESS:
      return {
        ...state,
        loading: false,
        scheduleWithPagy: action.payload,
      };

    case ADD_SCHEDULE:
      return {
        ...state,
        loading: true
      };

    case 'SEND_SCHEDULE':
      return {
        ...state,
        loading: true
      };

    case EDIT_SCHEDULE:
      return {
        ...state,
        loading: true
      };

    case GET_SCHEDULES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_SCHEDULE:
      return {
        ...state,
        loading: true
      };

    case "NEW_SCHEDULE_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "SEND_SCHEDULE_MODAL":
      return {
        ...state,
        send: !state.send
      };

    case "DELETE_SCHEDULE_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "EDIT_SCHEDULE_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default schedules;
