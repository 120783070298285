import React, { Fragment } from "react";
import { CardElement } from "@stripe/react-stripe-js";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#303238",
      fontSize: "16px",
      fontFamily: "sans-serif",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#CFD7DF"
      }
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238"
      }
    }
  }
};

function CardSection() {
  return (
    <Fragment>
      <div className="my-3">
        <h4 className="text-start mb-3">Card Details</h4>
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </div>
    </Fragment>
  );
}

export default CardSection;
