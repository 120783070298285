import React, { useState } from 'react'
import { FormFeedback, Input, Label, Row, Col } from 'reactstrap'
import PhoneInput from 'react-phone-input-2'
import Terms from "components/Terms";
import Policies from 'components/Policies'

function Auth({ validation, email }) {
  const [termShow, setTermShow] = useState(false)
  const [policyShow, setPolicyShow] = useState(false)

  return (
    <Row>
      <Terms
        close={() => setTermShow(false)}
        show={termShow}
      />

      <Policies
        close={() => setPolicyShow(false)}
        show={policyShow}
      />

      <Col xs={12} className="mb-3">
        <Label className="form-label">Email</Label>
        <Input
          name="email"
          type="text"
          disabled={true}
          value={email || ""}
        />
      </Col>

      <Col xs={12} lg={6} className="mb-3">
        <Label className="form-label">First Name</Label>
        <Input
          name="first_name"
          type="text"
          placeholder="Enter First Name"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.first_name || ""}
          invalid={
            validation.touched.first_name && validation.errors.first_name ? true : false
          }
        />
        {validation.touched.first_name && validation.errors.first_name ? (
          <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
        ) : null}
      </Col>

      <Col xs={12} lg={6} className="mb-3">
        <Label className="form-label">Last Name</Label>
        <Input
          name="last_name"
          type="text"
          placeholder="Enter Last Name"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.last_name || ""}
          invalid={
            validation.touched.last_name && validation.errors.last_name ? true : false
          }
        />
        {validation.touched.last_name && validation.errors.last_name ? (
          <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
        ) : null}
      </Col>

      <Col xs={12} className="mb-3">
        <Label className="form-label">Phone Number</Label>
        <PhoneInput
          inputProps={{
            name: 'phone_number'
          }}
          onBlur={validation.handleBlur}
          onChange={e => validation.setFieldValue('phone_number', `+${e}`)}
          value={validation.values.phone_number}
          isValid={!(validation.errors.phone_number && validation.touched.phone_number)}
          specialLabel={null}
          country={'ca'}
        />
        {validation.errors.phone_number && validation.touched.phone_number && <p className="text-danger mt-1 mb-0 ml-0">{validation.errors.phone_number}</p>}
      </Col>


      <Col xs={12} lg={6} className="mb-3">
        <Label className="form-label">Password</Label>
        <Input
          name="password"
          type="password"
          autoComplete="new-password"
          placeholder="Enter Password"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.password || ""}
          invalid={
            validation.touched.password && validation.errors.password ? true : false
          }
        />
        {validation.touched.password && validation.errors.password ? (
          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
        ) : null}
      </Col>

      <Col xs={12} lg={6} className="mb-3">
        <Label className="form-label">Confirm Password</Label>
        <Input
          name="password2"
          type="password"
          placeholder="Enter Password"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.password2 || ""}
          invalid={
            validation.touched.password2 && validation.errors.password2 ? true : false
          }
        />
        {validation.touched.password2 && validation.errors.password2 ? (
          <FormFeedback type="invalid">{validation.errors.password2}</FormFeedback>
        ) : null}
      </Col>

      <Col xs="12">
        <Input id="terms" type="checkbox" required />
        <Label className="form-label d-inline-block mx-lg-2" htmlFor="terms">I agree to ePaperSignSystem's <b onClick={() => setPolicyShow(true)} className="cursor-pointer">Privacy Policy</b> and <b onClick={() => setTermShow(true)} className="cursor-pointer">Terms and Conditions</b></Label>
      </Col>
    </Row>
  )
}

export default Auth