import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_EVENTS,
} from "./actionTypes";

import {
  getEventsSuccess,
  getEventsFail,
} from "./actions";

import {
  getEvents as getEventsAPI,
} from "api/v1";


function* fetchEvents({ payload: { params } }) {
  try {
    const response = yield call(getEventsAPI, params);
    yield put(getEventsSuccess(response));
  } catch (error) {
    if (error.message != "request-cancel") {
      yield put(getEventsFail("Something went wrong!"))
      toast.error("Something went wrong!");
    }
  }
}

function* eventSaga() {
  yield takeEvery(GET_EVENTS, fetchEvents);
}

export default eventSaga;
