import React from "react"
import PropTypes from 'prop-types'
import { Link, useHistory } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import styles from './css/Breadcrumb.module.css'

const Breadcrumb = props => {
  let history = useHistory()
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <div onClick={() => { props.back && history.goBack() }} className={`${props.back ? styles.back : ""} d-sm-flex align-items-center justify-content-between`}>
            {
              props.back &&
              <i className="mdi mdi-chevron-left font-size-24" />
            }

            <h5 className="mb-0 font-size-18">
              {props.title || props.breadcrumbItem}
            </h5>
          </div>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem />
              <BreadcrumbItem active>
                <Link to="#">{props.breadcrumbItem}</Link>
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default Breadcrumb
