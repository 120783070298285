import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import BootstrapTheme from "@fullcalendar/bootstrap";
import Schedule from './Schedule'
import Playlist from './Playlist'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//css
import "@fullcalendar/bootstrap/main.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from "utils/common";
import { getEvents } from "store/actions";
import { capitalize } from "lodash";

const Calender = () => {
  const [params, setParams] = useState({})
  const [scheduleId, setScheduleId] = useState(null)
  const [playlistId, setPlaylistId] = useState(null)
  const dispatch = useDispatch();

  const { events, loading } = useSelector(state => ({
    events: state.events.events.map(_event => ({
      id: `${_event.type}-${_event.id}`,
      title: _event.name + ' - ' + capitalize(_event.type),
      start: new Date(_event.start_time),
      end: new Date(_event.end_time),
      display: 'block',
      backgroundColor: _event.device.color,
      borderColor: _event.device.color,
      textColor: "white"
    })),
    loading: state.events.loading
  }));

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = ({ event }) => {
    let [type, id] = event.id.split('-')

    if (type == 'schedule')
      setScheduleId(id)
    else
      setPlaylistId(id)
  };

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  async function handleMonthChange(payload) {
    let { startStr: from_date, endStr: to_date } = payload
    let _params = { from_date, to_date }
    dispatch(getEvents(_params, true));
    setParams(_params)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Calendar</title>
        </MetaTags>
        <Container fluid={true}>
          <Schedule
            close={() => setScheduleId(null)}
            onSuccess={() => {
              setScheduleId(null)
              dispatch(getEvents(params, true));
            }}
            id={scheduleId}
          />

          <Playlist
            close={() => setPlaylistId(null)}
            onSuccess={() => {
              setPlaylistId(null)
              dispatch(getEvents(params, true));
            }}
            id={playlistId}
          />
          {/* Render Breadcrumb */}
          <Breadcrumbs breadcrumbItem="Calendar" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      {/* fullcalendar control */}
                      <FullCalendar
                        plugins={[
                          BootstrapTheme,
                          dayGridPlugin,
                        ]}
                        displayEventTime={false}
                        datesSet={handleMonthChange}
                        handleWindowResize={true}
                        themeSystem="bootstrap"
                        headerToolbar={{
                          left: 'today',
                          right: "prev,next",
                          center: "title"
                        }}
                        events={events}
                        eventClick={handleEventClick}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Calender.propTypes = {
  events: PropTypes.array,
  onGetEvents: PropTypes.func
};

export default Calender;
