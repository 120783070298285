import { filterParams } from "../apiHelper";
import request from "../restClient";

export const getSchedules = async (params, deviceId) => {
  return await request({
    url: deviceId ? `devices/${deviceId}/schedules` : "schedules/",
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const getSchedule = async (scheduleId) => {
  return await request({
    url: `schedules/${scheduleId}/`,
    method: "GET"
  });
};

export const deleteSchedule = async (id) => {
  return await request({
    url: `schedules/${id}/`,
    method: "DELETE"
  });
};

export const editSchedule = async (id, data) => {
  const formD = new FormData()
  Object.entries(data).forEach((keyValue) => {
    if (keyValue[0] != "avatar" || typeof (keyValue[1]) != "string")
      formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: `schedules/${id}/`,
    method: "PATCH",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};

export const addSchedule = async (data) => {
  const formD = new FormData()
  Object.entries(data).forEach((keyValue) => {
    formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: `schedules/`,
    method: "POST",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};

export const sendSchedule = async (data) => {
  const formD = new FormData()
  Object.entries(data).forEach((keyValue) => {
    if (keyValue[0] == 'devices')
      keyValue[1].forEach((device) => {
        formD.append('devices', device)
      })
    else
      formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: `schedules/next/`,
    method: "POST",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};
