import { filterParams } from "../apiHelper";
import request from "../restClient";

export const getCevents = async (params) => {
  return await request({
    url: "events/",
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const deleteCevent = async (id) => {
  return await request({
    url: `company/events/${id}/`,
    method: "DELETE"
  });
};

export const getCevent = async (id) => {
  return await request({
    url: `company/events/${id}/`,
    method: "GET"
  });
};

export const editCevent = async (id, data) => {

  return await request({
    url: `company/events/${id}/`,
    method: "PATCH",
    data
  });
};

export const addCevent = async (data) => {

  return await request({
    url: `company/events/`,
    method: "POST",
    data
  });
};
