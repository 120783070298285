import * as React from 'react';
export const loadingRef = React.createRef();
import PlaceholderImage from 'assets/images/placeholder.png';
import Resizer from 'react-image-file-resizer'
import moment from 'moment-timezone';

var abbrs = {
  EST: 'Eastern Time',
  EDT: 'Eastern Time',
  CST: 'Central Time',
  CDT: 'Central Time',
  MST: 'Mountain Time',
  MDT: 'Mountain Time',
  PST: 'Pacific Time',
  PDT: 'Pacific Time',
};

const setLoader = (loading) => {
  if (loading) {
    document.getElementById("preloader") && (document.getElementById("preloader").style.display = "block");
    document.getElementById("status") && (document.getElementById("status").style.display = "block");
  } else {
    document.getElementById("preloader") && (document.getElementById("preloader").style.display = "none");
    document.getElementById("status") && (document.getElementById("status").style.display = "none");
  }
};

export function getIntersection(a, b) {
  const set1 = new Set(a);
  const set2 = new Set(b);

  const intersection = [...set1].filter(
    element => set2.has(element)
  );

  return intersection;
}

const setTabBarLoader = (loading) => {
  if (loading) {
    loadingRef.current.continuousStart()
  } else {
    loadingRef.current.complete()
  }
};

const formatter = new Intl.NumberFormat('en-CA', {
  style: 'currency',
  currency: 'CAD',
  minimumFractionDigits: 2
})

const intFormatter = new Intl.NumberFormat('en-CA', {
  style: 'currency',
  currency: 'CAD',
  minimumFractionDigits: 0
})

function trunc(text, length = 20) {
  return text.length > length ? `${text.substr(0, length)}...` : text;
}

function cadFormat(amount, int = false) {
  if (int)
    return intFormatter.format(amount)
  else
    return formatter.format(amount)
}

function getThumbnail(_image) {
  _image = _image || { image: PlaceholderImage }
  return _image.image
}

// Hook
function useHover() {
  const [value, setValue] = React.useState(false);
  const ref = React.useRef(null);
  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);
  React.useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);
        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );
  return [ref, value];
}

async function resizeImage(_image) {
  if (!_image)
    return

  try {
    // const image = await _resizeFile(_image)
    return _image
  } catch (error) {
    toast.error("unable to process the image")
    return null
  }

}

function getTimezoneAbb() {
  return abbrs[moment().tz(moment.tz.guess()).format('z')];
}

const _resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

export {
  setLoader,
  resizeImage,
  setTabBarLoader,
  trunc,
  getTimezoneAbb,
  getThumbnail,
  useHover,
  cadFormat
};

