export const getProfile = () => ({
  type: 'GET_PROFILE'
})

export const editProfile = (profile, actions) => {
  return {
    type: 'EDIT_PROFILE',
    payload: { profile, actions },
  }
}

export const onProfileComplete = () => ({
  type: 'PROFILE_COMPLETE'
})

export const setProfile = (profile) => {
  return {
    type: 'SET_PROFILE',
    payload: { profile }
  }
}
