const INIT_STATE = {
  loading: false,
  profile: null
};

const profiles = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'GET_PROFILE':
      return {
        ...state,
        loading: true
      };

    case 'PROFILE_COMPLETE':
      return {
        ...state,
        loading: false
      };

    case 'SET_PROFILE':
      return {
        ...state,
        profile: action.payload.profile
      };

    case 'EDIT_PROFILE':
      return {
        ...state,
        loading: true
      };

    default:
      return state;
  }
};

export default profiles;
