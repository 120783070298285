import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React from "react";

import { Row, Col, CardBody, Card, Container } from "reactstrap";

import { Link, withRouter } from "react-router-dom";
import Preloader from "components/Common/Preloader";
import ResetPassword from "./ResetPassword";
import logo from "../../assets/images/logo.png";

const ResetPasswordControl = () => {

  return (
    <React.Fragment>
      <MetaTags>
        <title>Set Password | ePaperSignSystem</title>
      </MetaTags>

      <section className="auth-wrapper">
        <Preloader />
        <div className="min-vh-100 d-flex align-items-center">
          <Container className="py-3">
            <Row className="justify-content-center">
              <Col xl={7}>
                <Card>
                  <CardBody>
                    <div className="p-4 text-center">
                      <div className="text-center mb-3">
                        <img
                          src={logo}
                          alt=""
                          height="65"
                        />
                      </div>
                      <h4>New Password</h4>
                    </div>
                    <ResetPassword />
                    <div className="mt-3 text-center">
                      <p>
                        Go back to{" "}
                        <Link to="/login" className="font-weight-medium text-primary">
                          Login
                        </Link>{" "}
                      </p>
                      <p>
                        © {new Date().getFullYear()} by ePaperSignSystem
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
};

export default withRouter(ResetPasswordControl);

ResetPasswordControl.propTypes = {
  history: PropTypes.object,
};
