import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from 'pages/Schedules/common'

import moment from "moment";
import { editSchedule, getSchedule } from "api/v1";
import Loader from "common/Loader";
import { toast } from "react-toastify";

function index({ id, close, onSuccess }) {
  const modal = !!id
  let [schedule, setSchedule] = useState()
  const [loading, setLoading] = useState(false)

  async function loadSchedule() {
    setLoading(true)
    try {
      let _schedule = await getSchedule(id)
      setSchedule(_schedule)
    } catch (error) {
      toast.error("Something went wrong loading schedule")
      close()
    }
    setLoading(false)
  }

  useEffect(() => {
    if (modal) {
      loadSchedule()
    } else {
      setTimeout(() => setSchedule(null), 1000)
    }
  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (schedule && schedule.name) || '',
      device: (schedule && schedule.device) || null,
      image: (schedule && schedule.image) || null,
      display_from: (schedule && moment(new Date(schedule.display_from)).format('YYYY-MM-DDTHH:mm:ss')) || '',
      display_to: (schedule && moment(new Date(schedule.display_to)).format('YYYY-MM-DDTHH:mm:ss')) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      display_from: Yup.string().required("Please select display from period"),
      display_to: Yup.string().required("Please select display to period"),
      device: Yup.mixed().required("Please select device"),
      image: Yup.mixed().required("Please drag image")
    }),
    onSubmit: async (values, actions) => {
      setLoading(true)
      try {
        await editSchedule(schedule.id, {
          name: values.name,
          image: values.image
        })
        onSuccess()
      } catch ({ errors }) {
        if (errors) {
          actions.setErrors(errors)
        }
        if (errors?.non_field_errors)
          toast.error(errors.non_field_errors[0]);
        else
          toast.error("Something went wrong!");
      }
      setLoading(false)
    },
  });

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={modal} toggle={close}>
        <ModalHeader tag="h4" toggle={close}>
          Edit Schedule
        </ModalHeader>
        <ModalBody>
          {
            !schedule && loading ? <Loader />
              : schedule ?
                <Form
                  sendableLoading={loading}
                  target={schedule}
                  validation={validation}
                />
                : null
          }
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
