import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"

// Profile
import profiles from "./profiles/reducer"
import company from "./company/reducer"
import cards from "./cards/reducer"
import users from "./users/reducer"
import devices from "./devices/reducer"
import invoices from "./invoices/reducer"
import schedules from "./schedules/reducer"
import cevents from "./cevents/reducer"
import events from "./events/reducer"
import playlists from "./playlists/reducer"
import { REHYDRATE } from 'redux-persist/lib/constants'; 

const appReducer = combineReducers({
  // public
  Layout,
  Login,
  profiles,
  invoices,
  cards,
  users,
  devices,
  cevents,
  schedules,
  events,
  playlists,
  company,
  Account
})

const rootReducer = (state, action) => {
  
  if (action.type === REHYDRATE) {
    return { ...state };
  }
  else if (action.type === "RESET_STATE") {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
