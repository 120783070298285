import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/LoginControl"
import ResetPassword from "../pages/Authentication/ResetPasswordControl"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/RegisterControl"
import ForgetPassword from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Profile from "../pages/Profile/index"
import Company from "../pages/Company/index"
//Pages
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"
import Invoices from "pages/Invoices"
import Cards from "pages/Cards"
import Users from "pages/Users"
import Devices from "pages/Devices"
import Calendar from "pages/Calendar"
import Device from "pages/Devices/Show"
import Schedules from "pages/Schedules"
import Playlists from "pages/Playlists"
import Deploy from 'pages/Deploy'
import Assets from 'pages/Assets'
import CompanyEvents from 'pages/CompanyEvents'
import Playlist from "pages/Playlists/Show"


const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/users", exact: true, component: Users },
  { path: "/devices", exact: true, component: Devices },
  { path: "/devices/:id", exact: true, component: Device },
  { path: "/playlists/:id", exact: true, component: Playlist },
  { path: "/profile/settings", exact: true, component: Profile },
  { path: "/account/settings", exact: true, component: Company },
  { path: "/billing/cards", exact: true, component: Cards },
  { path: "/billing/invoices", exact: true, component: Invoices },
  { path: "/schedules", exact: true, component: Schedules },
  { path: "/events", exact: true, component: CompanyEvents },
  { path: "/assets", exact: true, component: Assets },
  { path: "/calendar", exact: true, component: Calendar },
  { path: "/deploy", exact: true, component: Deploy },
  { path: "/playlists", exact: true, component: Playlists },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/set_password/:uidb64/:token", component: ResetPassword },
  { path: "/forgot-password", component: ForgetPassword },
  { path: "/register", component: Register },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
]

export { authProtectedRoutes, publicRoutes }
