import {
  GET_DEVICES,
  GET_DEVICES_SUCCESS,
  GET_DEVICES_FAIL,
  GET_DEVICE,
  GET_DEVICE_SUCCESS
} from "./actionTypes"

export const getDevices = (loading = false, params = {}) => ({
  type: GET_DEVICES,
  payload: { loading, params }
})

export const getDevicesSuccess = devices => ({
  type: GET_DEVICES_SUCCESS,
  payload: devices,
})

export const getDeviceSuccess = device => ({
  type: GET_DEVICE_SUCCESS,
  payload: device,
})

export const getDevicesFail = error => ({
  type: GET_DEVICES_FAIL,
  payload: error,
})

export const getDevice = (id) => {
  return {
    type: GET_DEVICE,
    payload: { id },
  }
}
