import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import {
  getDevices as onGetDevices,
} from "store/devices/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import Loader from "common/Loader";
import { round } from "lodash";

const Devices = () => {
  const dispatch = useDispatch();

  const { deviceWithPagy, loading } = useSelector(state => ({
    deviceWithPagy: state.devices.deviceWithPagy,
    loading: state.devices.loading,
  }));
  let { results: devices } = deviceWithPagy

  //pagination customization
  const pageOptions = {
    sizePerPage: deviceWithPagy.page_size,
    totalSize: deviceWithPagy.total,
    page: deviceWithPagy.page,
    custom: true,
  };

  const COLS = [
    {
      text: "id",
      dataField: "id",
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      text: "ID",
      dataField: "serial_no",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.serial_no}</>,
    },
    {
      text: "Name",
      dataField: "name",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <b><i>{row.name || 'N/A'}</i></b>
    },
    {
      text: "Status",
      dataField: "status",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <div>
        <span id={`device-${row.id}`} className={`fas fa-circle text-${row.is_active ? 'primary' : 'danger'}`} />
        <span> {row.info.state}</span>
        <UncontrolledTooltip
          placement="bottom"
          target={`device-${row.id}`}
        >
          {row.is_active ? 'Active' : 'Inactive'}
        </UncontrolledTooltip>
      </div>
      ,
    },
    {
      text: "Heartbeat",
      dataField: "heartbeat",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.config?.heartbeat_default_value || 0}s</>,
    },
    {
      text: "Expected Activation",
      dataField: "expected_activation",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.info.expected_activation_time}</>,
    },
    {
      text: "Battery",
      dataField: "battery",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <span className="text-primary">{row.info.battery_voltage}V</span>,
    },
    {
      text: "Temp",
      dataField: "temprature",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{round(row.info.temperature, 2)}℃</>,
    }
  ];


  var node = useRef();

  function init() {
    dispatch(onGetDevices(true, { config: true }));
  }

  useEffect(() => {
    init()
  }, []);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetDevices(true, { page, config: true }));
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];


  return (
    <Card>
      <h4 className="mt-2 mx-3">Devices</h4>
      <CardBody>
        {
          !loading ?
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="id"
              columns={COLS}
              data={devices}
            >
              {({ paginationProps, paginationTableProps }) => {
                return (
                  <ToolkitProvider
                    keyField="id"
                    data={devices || []}
                    columns={COLS}
                    bootstrap4
                    search
                  >
                    {toolkitProps => {
                      return (
                        <React.Fragment>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  noDataIndication={"No Data found"}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-middle table-nowrap"}
                                  keyField="id"
                                  remote={{ search: true, pagination: true }}
                                  {...toolkitProps.baseProps}
                                  onTableChange={handleTableChange}
                                  {...paginationTableProps}
                                  ref={node}
                                />
                              </div>

                            </Col>
                          </Row>
                          <Row className="align-items-md-center mt-30">
                            <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                              <PaginationListStandalone
                                {...paginationProps}
                              />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )
                    }}
                  </ToolkitProvider>
                )
              }}
            </PaginationProvider>
            : <Loader />
        }
      </CardBody>
    </Card>
  );
};

Devices.propTypes = {
  devices: PropTypes.array,
  onGetDevices: PropTypes.func,
};

export default Devices;
