import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Badge, Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  getPlaylist
} from "store/playlists/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";


import { setLoader } from "utils/common";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import moment from "moment";
//redux

const Playlist = props => {
  const [photoIndex, setphotoIndex] = useState(-1);
  const {
    match: { params },
  } = props;

  useEffect(() => {
    dispatch(getPlaylist(params.id))
  }, [])

  const dispatch = useDispatch();

  const { playlist, loading } = useSelector(state => ({
    playlist: state.playlists.playlist,
    loading: state.playlists.loading,
  }));

  useEffect(() => {
    setLoader(loading)
  }, [loading])

  if (!playlist)
    return (<Fragment />)

  return (
    <div className="page-content">
      <MetaTags>
        <title>
          Playlist | ePaperSignSystem
        </title>
      </MetaTags>
      <Container fluid>
        {photoIndex > -1 && playlist.images.length > 0 ? (
          <Lightbox
            mainSrc={playlist.images[photoIndex].image}
            nextSrc={playlist.images[(photoIndex + 1) % playlist.images.length].image}
            prevSrc={playlist.images[(photoIndex + playlist.images.length - 1) % playlist.images.length].image}
            onCloseRequest={() => {
              setphotoIndex(-1);
            }}
            onMovePrevRequest={() => {
              setphotoIndex((photoIndex + playlist.images.length - 1) % playlist.images.length);
            }}
            onMoveNextRequest={() => {
              setphotoIndex((photoIndex + 1) % playlist.images.length);
            }}
          />
        ) : null}

        <Breadcrumbs back={true} title="Playlists" breadcrumbItem="Playlist" />
        <Card>
          <CardBody>
            <h5 className="mb-3">Playlist Details</h5>

            <Row>
              <Col xs="12" className="mb-5">
                <div className="d-flex flex-wrap align-items-center border border-dark rounded py-2">
                  <div className="mt-2 mb-3 px-4">
                    <label>Name</label>
                    <div>{playlist.name}</div>
                  </div>

                  <div className="mt-2 mb-3 px-4">
                    <label>Device</label>
                    <div>{playlist.device.name}</div>
                  </div>

                  <div className="mt-2 mb-3 px-4">
                    <label>Duration</label>
                    <div>{playlist.duration} hour(s)</div>
                  </div>

                  <div className="mt-2 mb-3 px-4">
                    <label>Interval</label>

                    <div>
                      <Badge className={"bg-primary"}>
                        {playlist.interval} mins
                      </Badge>
                    </div>
                  </div>

                  <div className="mt-2 mb-3 px-4">
                    <label>Start Time</label>
                    <div>{moment(new Date(playlist.start_time)).format("DD MMM Y hh:mm a")}</div>
                  </div>

                  <div className="mt-2 mb-3 px-4">
                    <label>End Time</label>
                    <div>{moment(new Date(playlist.end_time)).format("DD MMM Y hh:mm a")}</div>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <Row>
              <div className="col-xs-12">
                <h5 className="mt-2 mb-3">{playlist.images.length > 0 ? "Images" : "No images are found"}</h5>

                <Row>
                  {
                    playlist.images.map(({ image }, _index) =>
                      <Col className="mb-2" onClick={() => setphotoIndex(_index)} key={`gallary-image-${_index}`} xs="12" md="3">
                        <img
                          src={image}
                          className="w-100" style={{
                            height: 'auto'
                          }} />
                      </Col>
                    )
                  }
                </Row>
              </div>
            </Row>
          </CardBody>
        </Card>

      </Container >
    </div >
  );
};

Playlist.propTypes = {
  match: PropTypes.object,
};

export default withRouter(Playlist);
