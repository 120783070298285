import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_CARDS,
  DELETE_CARD,
  ADD_CARD,
  EDIT_CARD
} from "./actionTypes";

import {
  getCardsSuccess,
  getCards,
  getCardsFail,
  deleteCardModal
} from "./actions";

import {
  getCards as getCardsAPI,
  deleteCard as deleteCardAPI,
  addCard as addCardAPI,
  editCard as editCardAPI,
} from "api/v1";


function* fetchCards({ payload: { params } }) {
  try {
    const response = yield call(getCardsAPI, params);
    yield put(getCardsSuccess(response));
  } catch (error) {
    if (error.message != "request-cancel") {
      yield put(getCardsFail("Something went wrong!"))
      toast.error("Something went wrong!");
    }
  }
}

function* addCard({ payload: { card } }) {
  try {
    yield call(addCardAPI, card)
    toast.success("Card Created!");
    yield put(getCards(true));

  } catch ({ errors }) {
    yield put(getCardsFail("Creation failed!"))
    if (errors?.non_field_errors)
      toast.error(errors.non_field_errors[0]);
    else
      toast.error("Something went wrong!")
  }
}

function* editCard({ payload: { id } }) {
  try {
    yield call(editCardAPI, id)
    toast.success("Card Updated!");
    yield put(getCards(true));

  } catch ({ errors }) {
    yield put(getCardsFail("Creation failed!"))

    if (errors?.non_field_errors)
      toast.error(errors.non_field_errors[0]);
    else
      toast.error("Something went wrong!")
  }
}

function* deleteCard({ payload: { card } }) {
  try {
    yield call(deleteCardAPI, card.id);
    put(deleteCardModal())

    toast.warn("Card Deleted!");
  } catch (error) {
    toast.error("Something went wrong!");
  }
  yield put(getCards(true));
}

function* cardSaga() {
  yield takeEvery(GET_CARDS, fetchCards);
  yield takeEvery(DELETE_CARD, deleteCard);
  yield takeEvery(ADD_CARD, addCard);
  yield takeEvery(EDIT_CARD, editCard);
}

export default cardSaga;
