import { filterParams } from "../apiHelper";
import request from "../restClient";

export const getUsers = async (params) => {
  return await request({
    url: "users/",
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const getActivities = async (params) => {
  return await request({
    url: "activities/",
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const deleteUser = async (id) => {
  return await request({
    url: `users/${id}/`,
    method: "DELETE"
  });
};

export const addUser = async (data) => {
  const form = new FormData()

  let { email, profile_pic, ..._formData } = data

  if (typeof (profile_pic) != "string" && profile_pic)
    form.append('profile_pic', profile_pic)

  form.append('email', email)
  form.append("profile", JSON.stringify(_formData))
  form.append("redirect_uri", `${process.env.REACT_APP_HOST}/set_password`)

  return await request({
    url: `users/`,
    method: "POST",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: form
  });
};


export const editUser = async (id, data) => {

  return await request({
    url: `users/${id}/`,
    method: "PATCH",
    data: data
  });
};

export const inviteUser = async (id) => {

  return await request({
    url: `users/${id}/send_password`,
    method: "post",
    data: { redirect_uri: `${process.env.REACT_APP_HOST}/set_password` }
  });
};

export const requestPhoneOtp = async () => {
  return await request({
    url: `users/request_phone_otp`,
    method: "post"
  });
};

export const verifyOtp = async (data) => {
  return await request({
    url: `users/verify_phone`,
    method: "post",
    data
  });
};
