import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap";
import { useHistory } from "react-router-dom"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import DeleteIcon from 'assets/images/Delete.svg'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import AddCard from 'components/AddCard'
import {
  getCards as onGetCards,
  deleteCard as onDeleteCard,
  newCardModal,
  deleteCardModal
} from "store/cards/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from "utils/common";
import { editCard } from "store/actions";

const EcommerceCards = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { cardWithPagy, loading, deleteModal, company, profile } = useSelector(state => ({
    cardWithPagy: state.cards.cardWithPagy,
    loading: state.cards.loading,
    company: state.company.company,
    deleteModal: state.cards.delete,
    profile: state.profiles.profile
  }));
  let { results: cards = [] } = cardWithPagy
  let isAdmin = profile?.profile.is_company_admin

  const [card, setCard] = useState(null);
  const [searchParams, setSearchParams] = useState({
    search: '',
  })

  //pagination customization
  const pageOptions = {
    sizePerPage: cardWithPagy.page_size,
    totalSize: cardWithPagy.total,
    page: cardWithPagy.page,
    custom: true,
  };

  const COLS = [
    {
      text: "#",
      dataField: "index",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row, rowIndex) => <>{rowIndex + 1}</>,
    },
    {
      dataField: "number",
      text: "Card Number",
      sort: false,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${"***** ".repeat(4)} ${row.card.last4}`}</p>
        </>
      ),
    },
    {
      dataField: "brand",
      text: "Brand",
      sort: false,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1 text-capitalize">{`${row.card.brand}`}</p>
        </>
      ),
    },
    {
      dataField: "is_default_payment_method",
      text: "Default",
      sort: false,
      formatter: (cellContent, card) => {
        return (
          <>
            <div className="form-check form-switch form-switch-md">
              <input
                disabled={card.is_default_payment_method}
                type="checkbox"
                className="form-check-input"
                id="customSwitch2"
                defaultChecked={card.is_default_payment_method}
                onClick={e => {
                  dispatch(editCard(card.id))
                }}
              />
            </div>
          </>
        )
      }
    },
    {
      dataField: "expiry",
      text: "Expiry",
      sort: false,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.card.exp_month}/${row.card.exp_year}`}</p>
        </>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, card) => (
        <div className="d-flex align-items-center">
          {
            !card.is_default_payment_method && cards.length > 1 &&
            <div onClick={() => onClickDelete(card)} className="me-2 mb-1">
              <img
                src={DeleteIcon}
                height="20"
                width="20"
              />
            </div>
          }
        </div>
      ),
    },
  ];


  var node = useRef();

  //delete card

  const onClickDelete = (card) => {
    setCard(card);
    dispatch(deleteCardModal())
  };

  const handleDeleteCard = () => {
    dispatch(onDeleteCard(card))
  }

  const { SearchBar } = Search;

  function init() {
    if (!company?.subscribed_plan || !isAdmin) {
      history.push('/dashboard')
      return
    }
    dispatch(onGetCards(true));
  }



  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetCards(true, { page, ...searchParams }));
  };

  const handleCreateCard = () => {
    dispatch(newCardModal())
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];


  // let handleSearch = (key, value) => {
  //   let _searchParams = { ...searchParams, [key]: value }
  //   setSearchParams(_searchParams)
  //   dispatch(onGetCards(false, _searchParams));
  // }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCard}
        onCloseClick={() => dispatch(deleteCardModal())}
      />

      <AddCard />

      <div className="page-content">
        <MetaTags>
          <title>Cards | ePaperSignSystem</title>
        </MetaTags>
        <Container fluid>
          <div className="text-center my-3">
            <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
              <input onChange={() => history.push('/billing/invoices')} type="radio" className="btn-check" name="toggler" id="invoices" autoComplete="off" />
              <label className="btn btn-outline-primary" htmlFor="invoices">Invoices</label>

              <input type="radio" className="btn-check" name="toggler" id="cards" autoComplete="off" defaultChecked />
              <label className="btn btn-outline-primary" htmlFor="cards">Cards Management</label>
            </div>
          </div>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={COLS}
                    data={cards}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField="id"
                          data={cards || []}
                          columns={COLS}
                          bootstrap4
                          search
                        >
                          {toolkitProps => {
                            return (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    {/* <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          searchText={searchParams.search}
                                          onSearch={text => handleSearch("search", text)} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div> */}
                                  </Col>
                                  <Col sm="8">
                                    <div className="text-sm-end">
                                      <Button
                                        type="button"
                                        color="success"
                                        className="btn-rounded  mb-2 me-2"
                                        onClick={handleCreateCard}
                                      >
                                        <i className="mdi mdi-plus me-1" />
                                        New card
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        noDataIndication={"No Data found"}
                                        defaultSorted={defaultSorted}
                                        classes={"table align-middle table-nowrap"}
                                        keyField="id"
                                        remote={{ search: true, pagination: true }}
                                        {...toolkitProps.baseProps}
                                        onTableChange={handleTableChange}
                                        {...paginationTableProps}
                                        ref={node}
                                      />
                                    </div>

                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          }}
                        </ToolkitProvider>
                      )
                    }}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

EcommerceCards.propTypes = {
  cards: PropTypes.array,
  onGetCards: PropTypes.func,
  onAddNewCard: PropTypes.func,
  onDeleteCard: PropTypes.func,
  onUpdateCard: PropTypes.func,
};

export default EcommerceCards;
