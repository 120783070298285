import {
  GET_PLAYLISTS_FAIL,
  GET_PLAYLISTS_SUCCESS,
  GET_PLAYLISTS,
  ADD_PLAYLIST,
  EDIT_PLAYLIST,
  DELETE_PLAYLIST,
} from "./actionTypes";

const INIT_STATE = {
  playlistWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  playlist: null,
  new: false,
  edit: false,
  delete: false,
  loading: false,
};

const playlists = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PLAYLISTS:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case GET_PLAYLISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        playlistWithPagy: action.payload,
      };

    case ADD_PLAYLIST:
      return {
        ...state,
        loading: true
      };

    case 'GET_PLAYLIST_SUCCESS':
      return {
        ...state,
        loading: false,
        playlist: action.payload,
      };

    case 'GET_PLAYLIST':
      return {
        ...state,
        playlist: null,
        loading: true
      };

    case EDIT_PLAYLIST:
      return {
        ...state,
        loading: true
      };

    case GET_PLAYLISTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_PLAYLIST:
      return {
        ...state,
        loading: true
      };

    case "NEW_PLAYLIST_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "DELETE_PLAYLIST_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "EDIT_PLAYLIST_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default playlists;
