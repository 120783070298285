import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_PLAYLISTS,
  DELETE_PLAYLIST,
  ADD_PLAYLIST,
  EDIT_PLAYLIST
} from "./actionTypes";

import {
  getPlaylistsSuccess,
  getPlaylists,
  getPlaylistsFail,
  deletePlaylistModal,
  getPlaylistSuccess
} from "./actions";

import {
  getPlaylists as getPlaylistsAPI,
  getPlaylist as getPlaylistAPI,
  deletePlaylist as deletePlaylistAPI,
  addPlaylist as addPlaylistAPI,
  editPlaylist as editPlaylistAPI,
} from "api/v1";


function* fetchPlaylists({ payload: { params, deviceId } }) {
  try {
    const response = yield call(getPlaylistsAPI, params, deviceId);
    yield put(getPlaylistsSuccess(response));
  } catch (error) {
    yield put(getPlaylistsFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addPlaylist({ payload: { playlist, actions } }) {
  try {
    yield call(addPlaylistAPI, playlist)
    toast.success("Playlist Created!");
    yield put(getPlaylists(true));
    actions.resetForm()

  } catch ({ errors }) {
    yield put(getPlaylistsFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    if (errors?.non_field_errors)
      toast.error(errors.non_field_errors[0]);
    else
      toast.error("Something went wrong!");
  }
}

function* editPlaylist({ payload: { id, playlist, actions } }) {
  try {
    yield call(editPlaylistAPI, id, playlist)
    toast.success("Playlist Updated!");
    yield put(getPlaylists(true));

  } catch ({ errors }) {
    yield put(getPlaylistsFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    if (errors?.non_field_errors)
      toast.error(errors.non_field_errors[0]);
    else
      toast.error("Something went wrong!");
  }
}

function* deletePlaylist({ payload: { playlist } }) {
  try {
    yield call(deletePlaylistAPI, playlist.id);
    put(deletePlaylistModal())
    toast.warn("Playlist Deleted!");
  } catch (error) {
    toast.error("Something went wrong!");
  }
  yield put(getPlaylists(true));
}

function* getPlaylist({ payload: { id } }) {
  try {
    const response = yield call(getPlaylistAPI, id);
    yield put(getPlaylistSuccess(response));
  } catch (error) {
    if (error.message != "request-cancel") {
      yield put(getPlaylistsFail("Something went wrong!"))
      toast.error("Something went wrong!");
    }
  }
}

function* playlistSaga() {
  yield takeEvery(GET_PLAYLISTS, fetchPlaylists);
  yield takeEvery(DELETE_PLAYLIST, deletePlaylist);
  yield takeEvery(ADD_PLAYLIST, addPlaylist);
  yield takeEvery(EDIT_PLAYLIST, editPlaylist);
  yield takeEvery('GET_PLAYLIST', getPlaylist);
}

export default playlistSaga;
