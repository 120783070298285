import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { newUserModal, addUser } from 'store/users/actions'

function index() {
  let dispatch = useDispatch()
  const { new: modal } = useSelector((state) => state.users);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      first_name: '',
      last_name: '',
      email: "",
      is_company_admin: false,
      profile_pic: null,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter Your First Name"),
      email: Yup.string().email().email().required("Please Enter Your Email Address"),
      last_name: Yup.string().required("Please Enter Your Last Name"),
    }),
    onSubmit: (values, actions) => {
      dispatch(addUser(values, actions))
    },
  });

  useEffect(() => {
    if (modal) {
      validation.resetForm()
    }
  }, [modal])

  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={() => dispatch(newUserModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(newUserModal())}>
          Add User
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
