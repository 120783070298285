import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_CEVENTS,
  DELETE_CEVENT,
  ADD_CEVENT,
  EDIT_CEVENT
} from "./actionTypes";

import {
  getCeventsSuccess,
  getCevents,
  getCeventsFail,
  deleteCeventModal
} from "./actions";

import {
  getCevents as getCeventsAPI,
  deleteCevent as deleteCeventAPI,
  addCevent as addCeventAPI,
  editCevent as editCeventAPI,
} from "api/v1";


function* fetchCevents({ payload: { params, deviceId } }) {
  try {
    const response = yield call(getCeventsAPI, params, deviceId);
    yield put(getCeventsSuccess(response));
  } catch (error) {
    yield put(getCeventsFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addCevent({ payload: { cevent, actions } }) {
  try {
    yield call(addCeventAPI, cevent)
    toast.success("Event Created!");
    yield put(getCevents(true));
    actions.resetForm()

  } catch ({ errors }) {
    yield put(getCeventsFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    if (errors?.non_field_errors)
      toast.error(errors.non_field_errors[0]);
    else
      toast.error("Something went wrong!");
  }
}

function* editCevent({ payload: { id, cevent, actions } }) {
  try {
    yield call(editCeventAPI, id, cevent)
    toast.success("Event Updated!");
    yield put(getCevents(true));

  } catch ({ errors }) {
    yield put(getCeventsFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    if (errors?.non_field_errors)
      toast.error(errors.non_field_errors[0]);
    else
      toast.error("Something went wrong!");
  }
}

function* deleteCevent({ payload: { cevent } }) {
  try {
    yield call(deleteCeventAPI, cevent.id);
    put(deleteCeventModal())
    toast.warn("Event Deleted!");
  } catch (error) {
    toast.error("Something went wrong!");
  }
  yield put(getCevents(true));
}

function* ceventSaga() {
  yield takeEvery(GET_CEVENTS, fetchCevents);
  yield takeEvery(DELETE_CEVENT, deleteCevent);
  yield takeEvery(ADD_CEVENT, addCevent);
  yield takeEvery(EDIT_CEVENT, editCevent);
}

export default ceventSaga;
