import React, { useState } from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";

import CardSection from "./CardSection";
import { toast } from "react-toastify";
import { addCard } from "api/v1";
import { useDispatch } from "react-redux";
import { getCards } from "store/actions";

function CheckoutForm(props) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true)

    const { stripe, elements } = props;
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })

    if (error)
      toast.error(error.message)
    else {
      try {
        await addCard({ payment_method_id: paymentMethod.id })
        dispatch(getCards(false))
        toast.success("Card is saved!")

      } catch ({ errors }) {
        if (errors.non_field_errors)
          toast.error(errors.non_field_errors[0]);
        else
          toast.error("Something went wrong!")
      }

    }

    setLoading(false)
  };

  return (
    <div>
      <div className="product-info">
        <h3 className="product-title">{`Add Card`}</h3>
      </div>
      <form onSubmit={handleSubmit}>
        <CardSection />
        <button disabled={!props.stripe || loading} className="btn btn-primary w-100">
          {
            loading ?
              <i className="bx bx-hourglass bx-spin font-size-16 align-middle"></i>
              : `Create`
          }
        </button>
      </form>
    </div>
  );
}

export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} {...props} />
      )}
    </ElementsConsumer>
  );
}
