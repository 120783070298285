import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  passError: "",
  success: '',
  loading: false,

  state: 'login',
  token: '',
  uidb64: ''
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        error: "",
        success: "",
        passError: "",
        loading: true,
      }
      break
    case 'VERIFY_PASSWORD_CHANGE_TOKEN':
      state = {
        ...state,
        error: "",
        passError: "",
        success: "",
        loading: true,
      }
      break
    case 'TFA':
      state = {
        ...state,
        loading: true,
        success: "",
        error: "",
        passError: "",
      }
      break
    case 'RESET_AUTH':
      state = {
        ...state,
        error: "",
        success: "",
        passError: "",
        loading: false,

        state: 'login',
        token: '',
        uidb64: ''
      }
      break
    case 'CHANGE_AUTH_STATE':
      state = {
        ...state,
        state: action.payload
      }
      break
    case 'SET_AUTH_TOKENS':
      state = {
        ...state,
        token: action.payload.token,
        uidb64: action.payload.uidb64
      }
      break
    case 'RESET_PASSWORD':
      state = {
        ...state,
        error: "",
        success: "",
        passError: "",
        loading: true,
      }
      break
    case 'REQUEST_PASSWORD_CHANGE':
      state = {
        ...state,
        passError: "",
        error: "",
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        loading: false,
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case API_ERROR:
      state = { ...state, success: "", passError: "", error: action.payload, loading: false }
      break;
    case 'API_PASS_ERROR':
      state = { ...state, success: "", error: "", passError: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
