import React, { useEffect, useState } from "react";
import { Alert, Form } from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import { setLoader } from "utils/common";
import { Fragment } from "react";
import Auth from "components/Register/Auth";
import Location from "components/Register/Location";

let commonSchema = Yup.object({
  company_name: Yup.string().required("Please enter your organization name"),
  country: Yup.mixed().required("Please select Country"),
  province: Yup.mixed().required("Please select Province"),
  industry: Yup.mixed().required("Please select Industry"),
})

const Register = () => {
  const [step, setStep] = useState('location')
  const { registrationSuccess, registrationError, loading, email, token, uidb64 } = useSelector((state) => state.Account);

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      company_name: '',
      country: null,
      province: null,
      industry: null,

      first_name: '',
      last_name: '',
      phone_number: '',
      password: '',
      password2: '',
    },
    validationSchema: step == 'location' ? commonSchema : commonSchema.concat(Yup.object({
      first_name: Yup.string().required("Please enter your First Name"),
      last_name: Yup.string().required("Please enter your Last Name"),
      password: Yup.string().required("Please enter Password"),
      password2: Yup.string().required("Please confirm Password")
    })),
    onSubmit: (_values, actions) => {
      let { country, industry, province, ...values } = _values

      if (step == 'location')
        setStep('auth')
      else
        dispatch(registerUser({ ...values, country: country.id, province: province.id, industry: industry.id, token, uidb64 }, { ...actions, setStep }));

    }
  });

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  return (
    <Fragment>
      <h5 className="text-center mb-4">Complete the details</h5>
      <Form
        className="form-horizontal"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        {
          registrationSuccess && registrationSuccess ? (
            <Alert color="success">
              {step == 'location' ? 'Please enter Location' : 'Please create your account'}
            </Alert>
          ) : null
        }

        {
          registrationError && registrationError ? (
            <Alert color="danger">{registrationError}</Alert>
          ) : null
        }

        {
          step == 'location' ?
            <Location
              validation={validation}
            />
            :
            <Auth
              validation={validation}
              email={email}
            />
        }

        <div className="mt-4">
          <button
            className="btn btn-primary d-block w-100"
            type="submit"
          >
            {step == 'location' ? 'Next' : 'Create'}
          </button>

          {
            step == 'auth' &&
            <button
              type="button"
              onClick={() => {
                setStep('location')
              }}
              className="btn btn-outline-primary mt-1 w-100">
              Back
            </button>
          }
        </div>

        <div className="mt-3 text-center">
          <p>
            Already have an account ?{" "}
            <Link to="/login" className="font-weight-medium text-primary">
              {" "}
              Login
            </Link>{" "}
          </p>
        </div>
      </Form>
    </Fragment>
  );
};

export default Register;
