import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";

//redux
import { useSelector } from "react-redux";

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import { cadFormat, setTabBarLoader } from "utils/common";
import Loader from "common/Loader";
import Flow from "./Flow";
import Swal from "sweetalert2";
import { cancelPlan } from "api/v1";
import { useDispatch } from "react-redux";
import { getCompany } from "store/actions";
import { toast } from "react-toastify";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success mx-2',
    cancelButton: 'btn btn-danger'
  },
  buttonsStyling: false
})

const sweetAlertPayload = {
  title: 'Are you sure?',
  text: "You are going to cancel subscription..",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes, sure!',
  cancelButtonText: 'No, cancel!',
  reverseButtons: true
}

const Subscription = () => {
  const [showFlow, setShowFlow] = useState(false)
  const [subscriptionLoading, setSubscriptionLoading] = useState(false)
  const { loading: companyLoading, profile, company } = useSelector(state => ({
    loading: state.company.loading,
    company: state.company.company,
    profile: state.profiles.profile
  }));
  const dispatch = useDispatch()

  let isAdmin = profile?.profile.is_company_admin

  const loading = companyLoading || subscriptionLoading

  useEffect(() => {
    setTabBarLoader(loading)
  }, [loading]) //loading

  async function _cancelSubscription() {
    setSubscriptionLoading(true)
    try {
      await cancelPlan()
      toast.success("Your subscription has been cancelled")
      dispatch(getCompany())
    } catch (error) {
      let { errors } = error

      if (errors?.non_field_errors)
        toast.error(errors.non_field_errors[0]);
      else
        toast.error("Something went wrong while cancelling subscription")
    }
    setSubscriptionLoading(false)
  }

  function alertCancellation() {
    swalWithBootstrapButtons.fire({ ...sweetAlertPayload }).then(({ isConfirmed }) => {
      if (isConfirmed)
        _cancelSubscription()
    })
  }

  if (!company)
    return <Loader />

  return (
    <Card>
      {
        isAdmin &&
        <Flow
          visible={showFlow}
          activePlan={company.subscribed_plan}
          cancel={() => setShowFlow(false)}
        />
      }

      <h5 className="mt-3 px-4">Subscription Plan</h5>
      <CardBody>
        <Row>
          <Col xs="6" className="my-2">
            <h5 className="text-end">Allowed Screens: </h5>
          </Col>
          <Col xs="6" className="my-2">
            <h5>{company.plan_restrictions?.allowed_screens || 0}</h5>
          </Col>
          <hr />
          <Col xs="6" className="my-2">
            <h5 className="text-end">Package Detail: </h5>
          </Col>
          <Col xs="6" className="my-2">
            <h5>{cadFormat(company?.subscribed_plan?.amount || 0, true)}</h5>
          </Col>
          <hr />
          <Col xs="6" className="my-2">
            <h5 className="text-end">Plan Period: </h5>
          </Col>
          <Col xs="6" className="my-2">
            <h5 className="text-lowercase">{!company.subscribed_plan ? (company.is_trial_available ? 'on trial' : 'no plan') : `after every ${company.subscribed_plan?.frequency == 'Yearly' ? 'Year' : 'Month'}`}</h5>
          </Col>
          {
            isAdmin &&
            <div className="d-flex mt-2 justify-content-end">
              {
                company?.is_subscription_active &&
                <button
                  onClick={alertCancellation}
                  disabled={loading || !isAdmin}
                  className="btn btn-danger mx-2"
                >
                  {loading ? "Cancelling..." : "Cancel Subscription"} {" "}
                  {loading && <i className="bx bx-hourglass bx-spin font-size-16 align-middle"></i>}
                </button>
              }

              <button
                onClick={() => setShowFlow(true)}
                disabled={loading || !isAdmin}
                className="btn btn-success"
              >
                {loading ? "Updating..." : "Update Subscription"} {" "}
                {loading && <i className="bx bx-hourglass bx-spin font-size-16 align-middle"></i>}
              </button>
            </div>
          }
        </Row>
      </CardBody>
    </Card>

  );
};

export default withRouter(Subscription);
