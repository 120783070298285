import React, { useEffect } from "react";
import { Alert, Col, Form, Row } from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { confirmUser, resetRegister, tfa, resetAuth } from "../../store/actions";
import OtpInput from 'react-otp-input';

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useHistory } from "react-router-dom";
import { setLoader } from "utils/common";
import { Fragment } from "react";


const Otp = ({ register = false }) => {
  let history = useHistory()
  const dispatch = useDispatch();
  const { registrationSuccess, registrationError, ...registerTokens } = useSelector((state) => state.Account);
  const { success, error, ...authTokens } = useSelector((state) => state.Login);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      otp: ''
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .min(6, 'Too Short!')
        .max(6, 'Too Long!')
        .required("Please enter one time password")
    }),
    onSubmit: (values, actions) => {
      if (register)
        dispatch(confirmUser({ ...values, token: registerTokens.token, uidb64: registerTokens.uidb64 }, actions));
      else
        dispatch(tfa({ ...values, token: authTokens.token, uidb64: authTokens.uidb64 }, actions, history));
    }
  });

  let loading = registerTokens.loading || authTokens.loading

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  let { setFieldValue, values, touched, errors } = validation

  return (
    <Fragment>
      <h5 className="text-center mb-4">Verify yourself</h5>
      <Form
        className="form-horizontal"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        {register && registrationSuccess && registrationSuccess ? (
          <Alert color="success">
            {registrationSuccess}
          </Alert>
        ) : null}

        {register && registrationError && registrationError ? (
          <Alert color="danger">{registrationError}</Alert>
        ) : null}

        {!register && success && success ? (
          <Alert color="success">
            {success}
          </Alert>
        ) : null}

        {!register && error && error ? (
          <Alert color="danger">{error}</Alert>
        ) : null}

        <div className="mb-3">
          <OtpInput
            isDisabled={loading}
            value={values.otp}
            shouldAutoFocus
            isInputNum
            focusStyle={'border-primary'}
            inputStyle={'form-control w-control font-size-24'}
            containerStyle={"justify-content-center w-control-container"}
            onChange={otp => setFieldValue('otp', otp)}
            numInputs={6}
          />
          {errors.otp && touched.otp && <p className="text-danger mt-1 ml-0">{errors.otp}</p>}
        </div>

        <div className="mt-4">
          <button
            className="btn btn-primary d-block w-100"
            type="submit"
          >
            Verify
          </button>

          {/* <button
            type="button"
            onClick={() => {
              if (register)
                dispatch(resetRegister())
              else
                dispatch(resetAuth())
            }}
            className="btn btn-outline-primary mt-1 w-100">
            Back
          </button> */}
        </div>

        {
          register ?
            <div className="mt-3 text-center">
              <p>
                Already have an account ?{" "}
                <Link to="/login" className="font-weight-medium text-primary">
                  {" "}
                  Login
                </Link>{" "}
              </p>
            </div>
            :
            <Row className="py-2">
              <Col>
              </Col>

              <Col>
                <p className="mb-0">
                  <Link to="/forgot-password" style={{ float: 'right' }} className="font-weight-medium text-primary">
                    Forgot Password
                  </Link>
                </p>
              </Col>
            </Row>
        }
      </Form>
    </Fragment>
  );
};

export default Otp;
