import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { editScheduleModal, editSchedule } from 'store/schedules/actions'
import moment from "moment";

function index({ schedule: target }) {
  let dispatch = useDispatch()
  const { edit: modal } = useSelector((state) => state.schedules);
  let [schedule, setSchedule] = useState()

  useEffect(() => {
    if (modal) {
      setSchedule(target)
    }
  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (schedule && schedule.name) || '',
      device: (schedule && schedule.device) || null,
      image: (schedule && schedule.image) || null,
      display_from: (schedule && moment(new Date(schedule.display_from)).format('YYYY-MM-DDTHH:mm:ss')) || '',
      display_to: (schedule && moment(new Date(schedule.display_to)).format('YYYY-MM-DDTHH:mm:ss')) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      display_from: Yup.string().required("Please select display from period"),
      display_to: Yup.string().required("Please select display to period"),
      device: Yup.mixed().required("Please select device"),
      image: Yup.mixed().required("Please drag image")
    }),
    onSubmit: (values, actions) => {
      dispatch(editSchedule(schedule.id, {
        name: values.name,
        image: values.image
      }, actions))
    },
  });

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={modal} toggle={() => dispatch(editScheduleModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(editScheduleModal())}>
          Edit Schedule
        </ModalHeader>
        <ModalBody>
          <Form
            target={target}
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
