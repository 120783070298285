import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { newCeventModal, addCevent } from 'store/cevents/actions'

function index({ cevent: target }) {
  let dispatch = useDispatch()
  const { new: modal } = useSelector((state) => state.cevents);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      devices: []
    },
    validationSchema: Yup.object({
      devices: Yup.array().min(1, "Please select at least 1 device").required("required")
    }),
    onSubmit: (values, actions) => {
      dispatch(addCevent({
        devices: values.devices.map(_device => _device.id),
        event: target.id
      }, actions))
    },
  });

  useEffect(() => {
    if (modal) {
      validation.resetForm()
    }
  }, [modal])

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={modal} toggle={() => dispatch(newCeventModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(newCeventModal())}>
          Subscribe {target?.name}
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
