import {
  GET_SCHEDULES,
  GET_SCHEDULES_SUCCESS,
  GET_SCHEDULES_FAIL,
  DELETE_SCHEDULE,
  ADD_SCHEDULE,
  EDIT_SCHEDULE
} from "./actionTypes"

export const getSchedules = (loading = false, params = {}, deviceId = null) => ({
  type: GET_SCHEDULES,
  payload: { loading, params, deviceId }
})

export const getSchedulesSuccess = schedules => ({
  type: GET_SCHEDULES_SUCCESS,
  payload: schedules,
})

export const getSchedulesFail = error => ({
  type: GET_SCHEDULES_FAIL,
  payload: error,
})

export const deleteSchedule = schedule => ({
  type: DELETE_SCHEDULE,
  payload: { schedule }
})

export const addSchedule = (schedule, actions) => {
  return {
    type: ADD_SCHEDULE,
    payload: { schedule, actions },
  }
}

export const sendSchedule = (schedule, actions, history) => {
  return {
    type: 'SEND_SCHEDULE',
    payload: { schedule, actions, history },
  }
}

export const editSchedule = (id, schedule, actions) => {
  return {
    type: EDIT_SCHEDULE,
    payload: { id, schedule, actions },
  }
}

export const newScheduleModal = () => ({
  type: 'NEW_SCHEDULE_MODAL'
})

export const sendModal = () => ({
  type: 'SEND_SCHEDULE_MODAL'
})

export const deleteScheduleModal = () => ({
  type: 'DELETE_SCHEDULE_MODAL'
})

export const editScheduleModal = () => ({
  type: 'EDIT_SCHEDULE_MODAL'
})
