import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed, changeState, setTokens, resetRegister } from "./actions"
import { signup, join, confirmUser as confirmUserApi } from 'api/v1'
import { getIntersection } from "utils/common"

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, actions } }) {
  try {
    yield call(signup, user)
    yield put(resetRegister())
    yield put(registerUserSuccessful("Registered Successfully"))
    actions.resetForm()

  } catch ({ errors }) {
    if (errors?.non_field_errors)
      yield put(registerUserFailed(errors.non_field_errors[0]))
    else
      yield put(registerUserFailed("Something went wrong"))

    if (errors) {
      if (getIntersection(['country', 'industry', 'province', 'company_name'], Object.keys(errors)).length > 0)
        actions.setStep('location')

      actions.setErrors(errors)


    }
  }
}

function* joinUser({ payload: { user, actions } }) {
  try {
    const { token, uidb64, user: { email } } = yield call(join, user)
    yield put(changeState('otp'))
    yield put(setTokens({ token, uidb64, email }))
    yield put(registerUserSuccessful('Otp has been sent to your email'))
    actions.resetForm()

  } catch ({ errors }) {
    if (errors?.non_field_errors)
      yield put(registerUserFailed(errors.non_field_errors[0]))
    else
      yield put(registerUserFailed("Something went wrong"))

    if (errors) {
      actions.setErrors(errors)

    }
  }
}

function* confirmUser({ payload: { user, actions } }) {
  try {
    const { token, uidb64, user: { email } } = yield call(confirmUserApi, user)
    yield put(changeState('signup'))
    yield put(setTokens({ token, uidb64, email }))
    yield put(registerUserSuccessful('Please setup your account'))
    actions.resetForm()

  } catch ({ errors }) {
    if (errors?.non_field_errors)
      yield put(registerUserFailed(errors.non_field_errors[0]))
    else
      yield put(registerUserFailed("Something went wrong"))

    if (errors) {
      actions.setErrors(errors)

    }
  }
}

export function* watchUserRegister() {
  yield takeEvery('JOIN_USER', joinUser)
  yield takeEvery('CONFIRM_USER', confirmUser)
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
