import React, { useEffect, useState, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Row
} from "reactstrap";

import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import { getActivities } from "api/v1";
//redux
import Loader from "common/Loader";
import { toast } from "react-toastify";

const Activities = () => {
  const [loading, setLoading] = useState(false)
  const [activityWithPagy, setActivityWithPagy] = useState({
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  })

  let { results: activities = [] } = activityWithPagy

  //pagination customization
  const pageOptions = {
    sizePerPage: activityWithPagy.page_size,
    totalSize: activityWithPagy.total,
    page: activityWithPagy.page,
    custom: true,
  };

  async function onGetActivities(params = {}) {
    setLoading(true)
    try {
      const _activityWithPagy = await getActivities(params)
      setActivityWithPagy(_activityWithPagy)
    } catch (error) {
      toast.error("Something went wrong loading activities")
    }
    setLoading(false)
  }

  const COLS = [
    {
      text: "id",
      dataField: "id",
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      text: "User",
      dataField: "user",
      hidden: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.user?.profile ? `${row.user?.profile?.first_name} ${row.user?.profile?.last_name}` : "N/A"}</>,
    },
    {
      text: "Messages",
      dataField: "message",
      hidden: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.message}</>,
    },
  ];


  var node = useRef();

  function init() {
    onGetActivities();
  }

  useEffect(() => {
    init()
  }, []);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      onGetActivities({ page });
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];


  return (
    <Fragment>
      <h4 className="">Recent Activities</h4>
      {
        !loading || activities.length > 0 ?
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="id"
            columns={COLS}
            data={activities}
          >
            {({ paginationProps, paginationTableProps }) => {
              return (
                <ToolkitProvider
                  keyField="id"
                  data={activities || []}
                  columns={COLS}
                  bootstrap4
                  search
                >
                  {toolkitProps => {
                    return (
                      <React.Fragment>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                responsive
                                bordered={false}
                                striped={false}
                                noDataIndication={"No Data found"}
                                defaultSorted={defaultSorted}
                                classes={"table align-middle table-nowrap"}
                                keyField="id"
                                remote={{ search: true, pagination: true }}
                                {...toolkitProps.baseProps}
                                onTableChange={handleTableChange}
                                {...paginationTableProps}
                                ref={node}
                              />
                            </div>

                          </Col>
                        </Row>
                        <Row className="align-items-md-center">
                          <Col className="pagination pagination-rounded justify-content-end inner-custom-pagination">
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )
                  }}
                </ToolkitProvider>
              )
            }}
          </PaginationProvider>
          : <Loader />
      }
    </Fragment>
  );
};

Activities.propTypes = {
  activities: PropTypes.array,
  getActivities: PropTypes.func,
};

export default Activities;
