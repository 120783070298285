import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { newScheduleModal, addSchedule } from 'store/schedules/actions'
import moment from "moment";

function index() {
  let dispatch = useDispatch()
  const { new: modal } = useSelector((state) => state.schedules);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: '',
      display_from: '',
      display_to: '',
      device: null,
      image: null
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      display_from: Yup.string().required("Please select display from period"),
      display_to: Yup.string().required("Please select display to period"),
      device: Yup.mixed().required("Please select device"),
      image: Yup.mixed().required("Please drag image")
    }),
    onSubmit: (values, actions) => {
      dispatch(addSchedule({
        ...values,
        device: values.device.id,
        display_from: moment(values.display_from, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DDTHH:mm:ssZ"),
        display_to: moment(values.display_to, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DDTHH:mm:ssZ"),
      }, actions))
    },
  });

  useEffect(() => {
    if (modal) {
      validation.resetForm()
    }
  }, [modal])

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={modal} toggle={() => dispatch(newScheduleModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(newScheduleModal())}>
          Add Schedule
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
