import React, { forwardRef } from 'react';
import Droppable from './Droppable'
import Image from 'components/Image';

const Item = forwardRef(({ item, setItems, ...props }, ref) => {
  return (
    <div {...props} ref={ref}>
      <Droppable setItems={setItems} item={item}>
        <Image image={item} setItems={setItems} />
      </Droppable>
    </div>
  )
});

Item.displayName = 'Item';

export default Item