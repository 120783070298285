import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';

import {
  setCompany,
  onCompanyComplete
} from "./actions";

import {
  getCompany as getCompanyAPI,
  editCompany as editCompanyAPI,
} from "api/v1";


function* fetchCompany() {
  try {
    const response = yield call(getCompanyAPI);
    yield put(setCompany(response));

  } catch (error) {
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
  yield put(onCompanyComplete());
}

function* editCompany({ payload: { company, actions } }) {
  try {
    const response = yield call(editCompanyAPI, company)
    yield put(setCompany(response));
    toast.success("Company Updated!");

  } catch ({ errors }) {
    if (errors) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
  yield put(onCompanyComplete());
}

function* companySaga() {
  yield takeEvery('GET_COMPANY', fetchCompany);
  yield takeEvery('EDIT_COMPANY', editCompany);
}

export default companySaga;
