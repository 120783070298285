import React from "react";
import {
  Col,
  Row,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap";


function index({ validation }) {
  let [profilePic, setProfilePic] = React.useState()

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row form>
        <Col className="col-12">
          <div className="mb-3">
            <Label className="form-label">First Name</Label>
            <Input
              name="first_name"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.first_name || ""}
              invalid={
                validation.touched.first_name && validation.errors.first_name ? true : false
              }
            />
            {validation.touched.first_name && validation.errors.first_name ? (
              <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">Last Name</Label>
            <Input
              name="last_name"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.last_name || ""}
              invalid={
                validation.touched.last_name && validation.errors.last_name ? true : false
              }
            />
            {validation.touched.last_name && validation.errors.last_name ? (
              <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">Email</Label>
            <Input
              name="email"
              type="email"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email || ""}
              invalid={
                validation.touched.email && validation.errors.email ? true : false
              }
            />
            {validation.touched.email && validation.errors.email ? (
              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
            ) : null}
          </div>

          <div className="form-check form-switch form-switch-md mb-3">
            <Label htmlFor="company_admin" className="form-label">Admin</Label>
            <input
              type="checkbox"
              className="form-check-input"
              id="company_admin"
              defaultChecked={validation.is_company_admin}
              onChange={e => validation.setFieldValue('is_company_admin', !validation.is_company_admin)}
              onBlur={validation.handleBlur}
            />
          </div>

          <div className="mb-3">
            <Label className="form-label">Avatar</Label>
            <Input
              name="profile_pic"
              type="file"
              onChange={event => {
                validation.setFieldValue('profile_pic', event.target.files[0])
                if (event.target.files.length > 0) {
                  var reader = new FileReader();
                  reader.readAsDataURL(event.target.files[0]);
                  reader.onloadend = function (e) {
                    setProfilePic(reader.result)
                  }

                } else {
                  setProfilePic(null)
                }
              }}
              accept="image/*"
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.profile_pic && validation.errors.profile_pic ? true : false
              }
            />
            {validation.touched.profile_pic && validation.errors.profile_pic ? (
              <FormFeedback type="invalid">{validation.errors.profile_pic}</FormFeedback>
            ) : null}

            {
              (profilePic || validation.values.profile_pic) &&
              <div className="table-avatar-lg mt-2">
                <img
                  src={profilePic || validation.values.profile_pic}
                  alt={validation.values.first_name[0]}
                />
              </div>
            }
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-end">
            <button
              type="submit"
              className="btn btn-success save-customer"
            >
              Save
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default index
