import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  getSchedules as onGetSchedules,
  deleteSchedule as onDeleteSchedule,
  newScheduleModal,
  deleteScheduleModal,
  editScheduleModal
} from "store/schedules/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from "utils/common";
import Create from './Create'
import Edit from './Edit'
import moment from "moment";
import Loader from "common/Loader";

const Schedules = ({ deviceId = null }) => {
  const dispatch = useDispatch();

  const { scheduleWithPagy, loading, deleteModal } = useSelector(state => ({
    scheduleWithPagy: state.schedules.scheduleWithPagy,
    loading: state.schedules.loading,
    deleteModal: state.schedules.delete,
  }));
  let { results: schedules } = scheduleWithPagy

  const [schedule, setSchedule] = useState(null);
  const [searchParams, setSearchParams] = useState({
    search: '',
  })

  //pagination customization
  const pageOptions = {
    sizePerPage: scheduleWithPagy.page_size,
    totalSize: scheduleWithPagy.total,
    page: scheduleWithPagy.page,
    custom: true,
  };

  const COLS = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      text: "#",
      dataField: "index",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row, rowIndex) => <>{rowIndex + 1}</>,
    },
    {
      text: "Image",
      dataField: "image",
      hidden: !!deviceId,
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <div className="table-image-content">
        <img
          className="img-thumbnail "
          src={row.image.image}
          alt={"no-image"}
        />
      </div>
    },
    {
      text: "Name",
      dataField: "name",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <b><i>{row.name || 'N/A'}</i></b>
    },
    {
      text: "Device",
      dataField: "device",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <b><i>{row.device.name}</i></b>
    },
    {
      text: "Display from",
      dataField: "display_from",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <p className="mb-0">{moment(new Date(row.display_from)).format("DD MMM Y hh:mm a")}</p>
    },
    {
      text: "Display till",
      dataField: "display_to",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <p className="mb-0">{moment(new Date(row.display_to)).format("DD MMM Y hh:mm a")}</p>
    },
    {
      dataField: "menu",
      isDummyField: true,
      hidden: !!deviceId,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, schedule) => (
        <UncontrolledDropdown direction="left">
          <DropdownToggle className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem
              onClick={() => handleEditSchedule(schedule)}
            >
              <i className="fas fa-pencil-alt text-success me-1" />
              Edit
            </DropdownItem>
            <DropdownItem
              onClick={() => onClickDelete(schedule)}
            >
              <i className="fas fa-trash-alt text-danger me-1" />
              Delete
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];


  var node = useRef();

  //delete schedule

  const onClickDelete = (schedule) => {
    setSchedule(schedule);
    dispatch(deleteScheduleModal())
  };

  const handleDeleteSchedule = () => {
    dispatch(onDeleteSchedule(schedule))
  }


  function init() {
    dispatch(onGetSchedules(true, {}, deviceId));
  }

  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    setLoader(!deviceId && loading);
  }, [loading]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetSchedules(true, { page, ...searchParams }, deviceId));
  };

  const handleCreateSchedule = () => {
    dispatch(newScheduleModal())
  };

  const handleEditSchedule = _schedule => {
    setSchedule(_schedule)
    dispatch(editScheduleModal())
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];


  let handleSearch = (key, value) => {
    let _searchParams = { ...searchParams, [key]: value }
    setSearchParams(_searchParams)
    dispatch(onGetSchedules(false, _searchParams, deviceId));
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteSchedule}
        onCloseClick={() => dispatch(deleteScheduleModal())}
      />

      <Create />
      <Edit schedule={schedule} />

      <div className={!deviceId ? "page-content" : ""}>
        <MetaTags>
          <title>Schedules</title>
        </MetaTags>
        <Container fluid>
          {!deviceId && <Breadcrumbs breadcrumbItem="Schedules" />}
          <Row>
            <Col xs="12" className={!deviceId ? "" : "px-0"}>
              <Card>
                {
                  deviceId &&
                  <h4 className="mt-3 mx-3">Schedules</h4>
                }
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box ms-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <input
                            className="form-control"
                            onChange={e => handleSearch("search", e.target.value)} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="8">
                      {!deviceId && <div className="text-sm-end">
                        <Button
                          type="button"
                          color="success"
                          className="btn-rounded mb-2 me-2"
                          onClick={handleCreateSchedule}
                        >
                          <i className="mdi mdi-plus me-1" />
                          New schedule
                        </Button>
                      </div>}
                    </Col>
                  </Row>
                  {
                    (!loading || !deviceId) ?
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={COLS}
                        data={schedules}
                      >
                        {({ paginationProps, paginationTableProps }) => {
                          return (
                            <ToolkitProvider
                              keyField="id"
                              data={schedules || []}
                              columns={COLS}
                              bootstrap4
                              search
                            >
                              {toolkitProps => {
                                return (
                                  <React.Fragment>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            noDataIndication={"No Data found"}
                                            defaultSorted={defaultSorted}
                                            classes={"table align-middle table-nowrap"}
                                            keyField="id"
                                            remote={{ search: true, pagination: true }}
                                            {...toolkitProps.baseProps}
                                            onTableChange={handleTableChange}
                                            {...paginationTableProps}
                                            ref={node}
                                          />
                                        </div>

                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )
                              }}
                            </ToolkitProvider>
                          )
                        }}
                      </PaginationProvider>
                      : loading ? <Loader /> : null
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Schedules.propTypes = {
  schedules: PropTypes.array,
  onGetSchedules: PropTypes.func,
  onAddNewSchedule: PropTypes.func,
  onDeleteSchedule: PropTypes.func,
  onUpdateSchedule: PropTypes.func,
};

export default Schedules;
