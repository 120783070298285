import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ProfileSaga from "./profiles/saga"
import CompanySaga from "./company/saga"
import DeviceSaga from "./devices/saga"
import LayoutSaga from "./layout/saga"
import InvoicesSaga from "./invoices/saga"
import CardsSaga from "./cards/saga"
import UsersSaga from "./users/saga"
import CeventsSaga from "./cevents/saga"
import SchedulesSaga from "./schedules/saga"
import EventsSaga from "./events/saga"
import PlaylistsSaga from "./playlists/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ProfileSaga),
    fork(EventsSaga),
    fork(CardsSaga),
    fork(InvoicesSaga),
    fork(UsersSaga),
    fork(CeventsSaga),
    fork(DeviceSaga),
    fork(SchedulesSaga),
    fork(PlaylistsSaga),
    fork(CompanySaga),
    fork(LayoutSaga)
  ])
}
