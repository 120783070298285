import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import "./i18n"
import { Provider } from "react-redux"
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import './style.scss'

import store, { persistor } from "./store"
import { PersistGate } from 'redux-persist/integration/react'
import 'react-dropzone-uploader/dist/styles.css'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

const app = (
  <DndProvider backend={HTML5Backend}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          style={{
            zIndex: 1999,
          }}
        />

        <App />
      </PersistGate>
    </Provider>
  </DndProvider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
