import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { editCeventModal, editCevent } from 'store/cevents/actions'

function index({ cevent: target }) {
  let dispatch = useDispatch()
  const { edit: modal } = useSelector((state) => state.cevents);
  let [cevent, setCevent] = useState()

  useEffect(() => {
    if (modal) {
      setCevent(target)
    }
  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      devices: (cevent && cevent.devices) || [],
    },
    validationSchema: Yup.object({
      devices: Yup.array().min(1, "Please select at least 1 device").required("required")
    }),
    onSubmit: (values, actions) => {
      dispatch(editCevent(cevent.id, {
        devices: values.devices.map(_device => _device.id)
      }, actions))
    },
  });

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={modal} toggle={() => dispatch(editCeventModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(editCeventModal())}>
          Edit Event
        </ModalHeader>
        <ModalBody>
          <Form
            target={target}
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
