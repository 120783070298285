import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Form
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import OtpInput from 'react-otp-input';
import { verifyOtp } from "api/v1";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getProfile } from "store/actions";

function Verify({
  modal,
  onSuccess,
  toggle
}) {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  // validation
  const form = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      otp: ''
    },
    validationSchema: Yup.object({
      otp: Yup.string().required("Please Enter Otp"),
    }),
    onSubmit: async (values, actions) => {
      setLoading(true)
      try {
        await verifyOtp(values)
        toast.success("Phone number is verified")
        onSuccess()
        toggle(false)
        dispatch(getProfile());
      } catch ({ errors }) {
        if (errors)
          actions.setErrors(errors)

        if (errors?.non_field_errors)
          toast.error(errors.non_field_errors[0]);
        else
          toast.error("Something went wrong while sending otp!")
      }
      setLoading(false)
    },
  });

  useEffect(() => {
    if (modal) {
      form.resetForm()
    }
  }, [modal])

  return (
    <Modal centered isOpen={modal} toggle={() => toggle(false)}>
      <ModalHeader tag="h4" toggle={() => toggle(false)}>
        Verify phone number
      </ModalHeader>
      <ModalBody>
        <Alert isOpen={true} color="success">
          Otp has been sent to you
        </Alert>
        <Form onSubmit={e => {
          e.preventDefault();
          form.handleSubmit();
          return false;
        }}>
          <OtpInput
            isDisabled={loading}
            value={form.values.otp}
            shouldAutoFocus
            isInputNum
            focusStyle={'border-primary'}
            inputStyle={'form-control w-control font-size-24'}
            containerStyle={"justify-content-center w-control-container"}
            onChange={otp => form.setFieldValue('otp', otp)}
            numInputs={6}
          />
          {form.errors.otp && form.touched.otp && <p className="text-danger mt-1 ml-0">{form.errors.otp}</p>}

          <button
            className="btn btn-primary d-block w-100 mt-4"
            type="submit"
          >
            {loading ? "Submitting" : "Submit"} {" "}
            {loading && <i className="bx bx-hourglass bx-spin font-size-16 align-middle"></i>}
          </button>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default Verify
