import {
  GET_INVOICES,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAIL,
  DELETE_INVOICE,
  ADD_INVOICE,
  EDIT_INVOICE
} from "./actionTypes"

export const getInvoices = (loading = false, params = {}) => ({
  type: GET_INVOICES,
  payload: { loading, params }
})

export const getInvoicesSuccess = invoices => ({
  type: GET_INVOICES_SUCCESS,
  payload: invoices,
})

export const getInvoicesFail = error => ({
  type: GET_INVOICES_FAIL,
  payload: error,
})

export const deleteInvoice = invoice => ({
  type: DELETE_INVOICE,
  payload: { invoice }
})

export const addInvoice = (invoice, actions) => {
  return {
    type: ADD_INVOICE,
    payload: { invoice, actions },
  }
}

export const editInvoice = (id, invoice, actions) => {
  return {
    type: EDIT_INVOICE,
    payload: { id, invoice, actions },
  }
}

export const newInvoiceModal = () => ({
  type: 'NEW_INVOICE_MODAL'
})

export const deleteInvoiceModal = () => ({
  type: 'DELETE_INVOICE_MODAL'
})

export const editInvoiceModal = () => ({
  type: 'EDIT_INVOICE_MODAL'
})
