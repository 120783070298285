import React, { useState, useEffect } from 'react'
import { getCompanyImages, createCompanyImage } from 'api/v1'
import Draggable from 'components/Sortable/Draggable';
import { resizeImage, setTabBarLoader } from 'utils/common';
import { Container } from 'reactstrap';
import { toast } from 'react-toastify';
import Loader from "common/Loader";
import Image from 'components/Image';

function CompanyImages({ bucketImages = [] }) {
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setTabBarLoader(loading)
  }, [loading])

  useEffect(() => {
    loadImages()
  }, [])

  async function loadImages() {
    setLoading(true)
    try {
      let _images = await getCompanyImages()
      setImages(_images)

    } catch (error) {
      toast.error("Something went wrong loading images")
    }
    setLoading(false)
  }

  async function deleteImage(id) {
    setImages(images.filter((_image) => id != _image.id))
  }

  return (
    <Container fluid className='mt-2'>
      <h5>Assets</h5>

      <div className='mt-1'>
        {loading && <Loader />}
        {!loading && images.length == 0 && <h6>No Image found</h6>}

        {
          !loading && (bucketImages.length > 0 || images.length > 0) &&
          <div className='w-100 p-2 draggable-wrapper border rounded mb-2'>
            {images.filter(_image => !bucketImages.map(_item => _item.id).includes(_image.id)).map(_image => <Draggable data={_image} key={`image-${_image.id}`}><Image image={_image} isDeletable={_image.is_deletable} deleteImage={deleteImage} /></Draggable>)}
          </div>
        }

        {
          !loading && <input
            type="file"
            // multiple
            onChange={async (event) => {
              let _image = await resizeImage(event.target.files[0])
              setLoading(true)
              try {
                _image = await createCompanyImage({ image: _image })
                setImages([_image, ...images])
              } catch (error) {
                toast.error("Unable to add company image, please try again")
              }
              setLoading(false)
            }}
            role={"button"}
            className='btn btn-sm'
            accept="image/png, image/gif, image/jpeg"
          />
        }
      </div>

    </Container>
  )
}

export default CompanyImages
