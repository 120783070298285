import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown
} from "reactstrap";

import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
// import DeleteIcon from 'assets/images/Delete.svg'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  getUsers as onGetUsers,
  deleteUser as onDeleteUser,
  newUserModal,
  deleteUserModal,
  editUser,
  inviteUser
} from "store/users/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from "utils/common";
import Create from './Create'

const Users = props => {
  const dispatch = useDispatch();

  const { userWithPagy, loading, deleteModal, company, profile } = useSelector(state => ({
    userWithPagy: state.users.userWithPagy,
    loading: state.users.loading,
    deleteModal: state.users.delete,
    company: state.company.company,
    profile: state.profiles.profile
  }));
  let { results: users } = userWithPagy

  let ownerId = company?.created_by?.id
  let userId = profile?.id
  let isAdmin = profile?.profile.is_company_admin

  const [user, setUser] = useState(null);
  const [searchParams, setSearchParams] = useState({
    search: '',
  })

  //pagination customization
  const pageOptions = {
    sizePerPage: userWithPagy.page_size,
    totalSize: userWithPagy.total,
    page: userWithPagy.page,
    custom: true,
  };

  const COLS = [
    {
      text: "id",
      dataField: "id",
      sort: false,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      dataField: "avatar",
      text: "Avatar",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="table-avatar">
              {
                row.profile.profile_pic ? (
                  <img
                    src={row.profile.profile_pic}
                    alt={row.profile.first_name[0]}
                  />
                ) : (<span>{row.profile.first_name[0]}</span>)
              }

            </div>
          </>
        )
      },
    },
    {
      dataField: "username",
      text: "Username",
      sort: false,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.profile.first_name} ${row.profile.last_name}`}</p>
        </>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      sort: false,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.email}`}</p>
        </>
      ),
    },
    {
      dataField: "admin",
      text: "Admin",
      sort: false,
      formatter: (cellContent, user) => {
        return (
          <>
            <div className="form-check form-switch form-switch-md">
              <input
                disabled={(user.id == ownerId && user.id != userId) || !isAdmin}
                type="checkbox"
                className="form-check-input"
                id="customSwitch2"
                defaultChecked={user.profile.is_company_admin}
                onClick={e => {
                  dispatch(editUser(user.id, { is_company_admin: !user.profile.is_company_admin }), null)
                }}
              />
            </div>
          </>
        )
      }
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => {
        let _deleteableCheck = userId != user.id && user.id != ownerId
        let _invitableCheck = !user.is_password_set

        return (
          <>
            {
              isAdmin && (_deleteableCheck || _invitableCheck) &&
              <UncontrolledDropdown direction="up">
                <DropdownToggle className="card-drop" tag="i">
                  <i className="mdi mdi-dots-horizontal font-size-18" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  {
                    isAdmin && _deleteableCheck &&
                    <DropdownItem
                      onClick={() => onClickDelete(user)}>
                      Delete User
                    </DropdownItem>
                  }
                  {
                    isAdmin && _invitableCheck &&
                    <DropdownItem
                      onClick={() => dispatch(inviteUser(user.id))}>
                      Invite Again
                    </DropdownItem>
                  }
                </DropdownMenu>
              </UncontrolledDropdown>
            }
          </>
        )
      },
    },
  ];

  var node = useRef();
  //delete user

  const onClickDelete = (user) => {
    setUser(user);
    dispatch(deleteUserModal())
  };

  const handleDeleteUser = () => {
    dispatch(onDeleteUser(user))
  }

  function init() {
    dispatch(onGetUsers(true));
  }

  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetUsers(true, { page, ...searchParams }));
  };

  const handleCreateUser = () => {
    dispatch(newUserModal())
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  let handleSearch = (key, value) => {
    let _searchParams = { ...searchParams, [key]: value }
    setSearchParams(_searchParams)
    dispatch(onGetUsers(false, _searchParams));
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => dispatch(deleteUserModal())}
      />

      <Create />

      <div className="page-content">
        <MetaTags>
          <title>ePaperSignSystem | Users</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Users" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box ms-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <input
                            className="form-control"
                            onChange={e => handleSearch("search", e.target.value)} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end">
                        {isAdmin &&
                          <Button
                            type="button"
                            color="success"
                            className="btn-rounded mb-2 me-2"
                            onClick={handleCreateUser}
                          >
                            <i className="mdi mdi-plus me-1" />
                            New user
                          </Button>
                        }
                      </div>
                    </Col>
                  </Row>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={COLS}
                    data={users}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField="id"
                          data={users || []}
                          columns={COLS}
                          bootstrap4
                          search
                        >
                          {toolkitProps => {
                            return (
                              <React.Fragment>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        noDataIndication={"No Data found"}
                                        defaultSorted={defaultSorted}
                                        classes={"table align-middle table-nowrap"}
                                        keyField="id"
                                        remote={{ search: true, pagination: true }}
                                        {...toolkitProps.baseProps}
                                        onTableChange={handleTableChange}
                                        {...paginationTableProps}
                                        ref={node}
                                      />
                                    </div>

                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          }}
                        </ToolkitProvider>
                      )
                    }}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Users.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onUpdateUser: PropTypes.func,
};

export default Users;
