import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./styles.scss";
import CheckoutForm from './components/CheckoutForm';
import { useDispatch, useSelector } from 'react-redux';
import { newCardModal } from 'store/actions';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function Stripe() {
  const dispatch = useDispatch();
  const { new: modal } = useSelector((state) => state.cards);

  const close = () => {
    dispatch(newCardModal())
  }

  return (
    <Modal centered isOpen={modal} toggle={() => close()}>
      <ModalBody className="stripe-form p-4">
        <div className="product">
          <div>
            <Elements stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default Stripe
