import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_SCHEDULES,
  DELETE_SCHEDULE,
  ADD_SCHEDULE,
  EDIT_SCHEDULE
} from "./actionTypes";

import {
  getSchedulesSuccess,
  getSchedules,
  getSchedulesFail,
  deleteScheduleModal
} from "./actions";

import {
  getSchedules as getSchedulesAPI,
  deleteSchedule as deleteScheduleAPI,
  addSchedule as addScheduleAPI,
  sendSchedule as sendScheduleAPI,
  editSchedule as editScheduleAPI,
} from "api/v1";


function* fetchSchedules({ payload: { params, deviceId } }) {
  try {
    const response = yield call(getSchedulesAPI, params, deviceId);
    yield put(getSchedulesSuccess(response));
  } catch (error) {
    yield put(getSchedulesFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addSchedule({ payload: { schedule, actions } }) {
  try {
    yield call(addScheduleAPI, schedule)
    toast.success("Schedule Created!");
    yield put(getSchedules(true));
    actions.resetForm()

  } catch ({ errors }) {
    yield put(getSchedulesFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    if (errors?.non_field_errors)
      toast.error(errors.non_field_errors[0]);
    else
      toast.error("Something went wrong!");
  }
}

function* sendSchedule({ payload: { schedule, actions, history } }) {
  try {
    yield call(sendScheduleAPI, schedule)
    toast.success("Schedule sent!");
    yield put(getSchedules(true));
    actions.resetForm()
    history.push('/schedules')

  } catch ({ errors }) {
    yield put(getSchedulesFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    if (errors?.non_field_errors)
      toast.error(errors.non_field_errors[0]);
    else
      toast.error("Something went wrong!");
  }
}

function* editSchedule({ payload: { id, schedule, actions } }) {
  try {
    yield call(editScheduleAPI, id, schedule)
    toast.success("Schedule Updated!");
    yield put(getSchedules(true));

  } catch ({ errors }) {
    yield put(getSchedulesFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    if (errors?.non_field_errors)
      toast.error(errors.non_field_errors[0]);
    else
      toast.error("Something went wrong!");
  }
}

function* deleteSchedule({ payload: { schedule } }) {
  try {
    yield call(deleteScheduleAPI, schedule.id);
    put(deleteScheduleModal())
    toast.warn("Schedule Deleted!");
  } catch (error) {
    toast.error("Something went wrong!");
  }
  yield put(getSchedules(true));
}

function* scheduleSaga() {
  yield takeEvery(GET_SCHEDULES, fetchSchedules);
  yield takeEvery(DELETE_SCHEDULE, deleteSchedule);
  yield takeEvery(ADD_SCHEDULE, addSchedule);
  yield takeEvery('SEND_SCHEDULE', sendSchedule);
  yield takeEvery(EDIT_SCHEDULE, editSchedule);
}

export default scheduleSaga;
