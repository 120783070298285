import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap";

import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  getInvoices as onGetInvoices
} from "store/invoices/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { cadFormat, setLoader } from "utils/common";
import moment from "moment";
import { useHistory } from "react-router-dom";

/** set Date formate */
const handleValidDate = date => {
  const date1 = moment(new Date(date)).format("DD MMM Y");
  return date1;
};

const Invoices = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { invoiceWithPagy, loading, company, profile } = useSelector(state => ({
    invoiceWithPagy: state.invoices.invoiceWithPagy,
    loading: state.invoices.loading,
    company: state.company.company,
    deleteModal: state.invoices.delete,
    profile: state.profiles.profile
  }));
  let { results: invoices } = invoiceWithPagy
  let isAdmin = profile?.profile.is_company_admin

  const [searchParams, setSearchParams] = useState({
    search: '',
  })

  //pagination customization
  const pageOptions = {
    sizePerPage: invoiceWithPagy.page_size,
    totalSize: invoiceWithPagy.total,
    page: invoiceWithPagy.page,
    custom: true,
  };

  const COLS = [
    {
      text: "id",
      dataField: "id",
      sort: false,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      text: "#",
      dataField: "index",
      hidden: false,
      headerStyle: (colum, colIndex) => {
        return { width: '5%' };
      },
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row, rowIndex) => <>{rowIndex + 1}</>,
    },
    {
      dataField: "plan_name",
      text: "Name",
      sort: false,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{row.plan_name}</p>
        </>
      ),
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: false,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{cadFormat(row.amount)}</p>
        </>
      ),
    },
    {
      dataField: "date",
      text: "Date",
      sort: false,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{handleValidDate(row.created_at)}</p>
        </>
      ),
    },
    {
      dataField: "paid",
      text: "Status",
      sort: false,
      formatter: (cellContent, row) => (
        <>
          <b className="mb-1">{row.paid ? 'Paid' : 'Unpaid'}</b>
        </>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, invoice) => (
        invoice.invoice_url &&
        <a href={invoice.invoice_url} rel="noopener noreferrer" target={"_blank"} className="me-1">
          <i className="fas fa-file-invoice-dollar text-primary font-size-18" />
        </a>
      ),
    },
  ];


  var node = useRef();

  function init() {
    if (!company?.subscribed_plan || !isAdmin) {
      history.push('/dashboard')
      return
    }
    dispatch(onGetInvoices(true));
  }

  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetInvoices(true, { page, ...searchParams }));
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Invoices | ePaperSignSystem</title>
        </MetaTags>
        <Container fluid>
          <div className="text-center my-3">
            <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
              <input type="radio" className="btn-check" name="toggler" id="invoices" autoComplete="off" defaultChecked />
              <label className="btn btn-outline-primary" htmlFor="invoices">Invoices</label>

              <input onChange={() => history.push('/billing/cards')} type="radio" className="btn-check" name="toggler" id="cards" autoComplete="off" />
              <label className="btn btn-outline-primary" htmlFor="cards">Cards Management</label>
            </div>
          </div>

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={COLS}
                    data={invoices}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField="id"
                          data={invoices || []}
                          columns={COLS}
                          bootstrap4
                          search
                        >
                          {toolkitProps => {
                            return (
                              <React.Fragment>
                                <BootstrapTable
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  noDataIndication={"No Data found"}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-middle table-nowrap"}
                                  keyField="id"
                                  remote={{ search: true, pagination: true }}
                                  {...toolkitProps.baseProps}
                                  onTableChange={handleTableChange}
                                  {...paginationTableProps}
                                  wrapperClasses="table-responsive"
                                  ref={node}
                                />

                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          }}
                        </ToolkitProvider>
                      )
                    }}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Invoices.propTypes = {
  invoices: PropTypes.array,
  onGetInvoices: PropTypes.func,
  onAddNewInvoice: PropTypes.func,
  onDeleteInvoice: PropTypes.func,
  onUpdateInvoice: PropTypes.func,
};

export default Invoices;
