import React from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap'

function Policies({ show, close }) {
  return (
    <Modal centered isOpen={show} size="lg" toggle={close}>
      <ModalHeader tag="h5" toggle={close}>
        Privacy Policy
      </ModalHeader>

      <ModalBody>
        <lable>
          <b>Article 1 - DEFINITIONS</b>

          <br />
          <br />

          a) APPLICABLE WEBSITE: This Privacy Policy will refer to and be applicable to the Website listed
          above, which shall
          hereinafter be referred to as "Website." Any listed Website may also refer to a corresponding
          mobile application should
          one be currently in use or hereinafter developed.
          <br />
          <br />

          b) EFFECTIVE DATE: "Effective Date" means the date this Privacy Policy comes into force and
          effect.
          <br />
          <br />

          c) PARTIES: The parties to this privacy policy are the following data controller: Alexander
          Nevermi ("Data Controller")
          and you, as the user of this Website. Hereinafter, the parties will individually be referred to as
          "Party" and
          collectively as "Parties".
          <br />
          <br />

          d) DATA CONTROLLER: Data Controller is the publisher, owner, and operator of the Website and is
          the Party responsible
          for the collection of information described herein. Data Controller shall be referred to either by
          Data Controller's
          name or "Data Controller" as listed above. If Data Controller or Data Controller's property shall
          be referred to through
          first-person pronouns, it shall be through the use of the following: us, we, our, ours, etc.
          <br />


          e) YOU: Should you agree to this Privacy Policy and continue your use of the Website, you will be
          referred to herein as
          either you, the user, or if any second-person pronouns are required and applicable, such pronouns
          as 'your", "yours",
          etc.

          <br />
          <br />

          f) GOODS: "Goods" means any goods that we make available for sale on the Website.

          <br />
          <br />

          g) SERVICES: "Services" means any services that we make available for sale on the Website.

          <br />
          <br />

          h) PERSONAL DATA: "Personal DATA" means personal data and information that we obtain from you in
          connection with your
          use of the Website that is capable of identifying you in any manner.

          <br />
          <br />


          <b>Article 2 - GENERAL INFORMATION</b>

          <br />
          <br />
          This privacy policy (hereinafter "Privacy Policy") describes how we collect and use the Personal
          Data that we receive
          about you, as well as your rights in relation to that Personal Data, when you visit our Website or
          purchase our Goods or
          use our Services.
          <br />


          This Privacy Policy does not cover any information that we may receive about you through sources
          other than the use of
          our Website. The Website may link out to other websites or mobile applications, but this Privacy
          Policy does not and
          will not apply to any of those linked websites or applications.

          <br />
          We are committed to the protection of your privacy while you use our Website.

          <br />

          By continuing to use our Website, you acknowledge that you have had the chance to review and
          consider this Privacy
          Policy, and you acknowledge that you agree to it. This means that you also consent to the use of
          your information and
          the method of disclosure as described in this Privacy Policy. If you do not understand the Privacy
          Policy or do not
          agree to it, then you agree to immediately cease your use of our Website.

          <br />

          <br />

          <b>Article 3 - CONTACT</b>
          <br />
          <br />

          The Party responsible for the processing of your personal data is: Alexander Nevermi.

          <br />


          The Data Controller may be contacted as follows:

          <br />
          <br />

          alexander@epapersignsystem.com
          <br />
          1005 Skyview Drive
          <br />
          Burlington, ON L7P 5B1
          <br />


          The Data Controller and operator of the Website are one and the same.
          <br />

          <br />


          <b>Article 4 - LOCATION</b>
          <br />
          <br />

          Please be advised the data processing activities take place in Canada, outside the European
          Economic Area. Data may also
          be transferred to companies within Canada, but will only be done so in a manner that complies with
          the EU's General Data
          Protection Regulation or GDPR. The location where the data processing activities take place is as
          follows:

          <br />

          Burlington, ON, Canada
          <br />



          <br />
          <b>Article 5 - THE PERSONAL DATA WE RECEIVE FROM YOU</b>

          <br />
          <br />

          Depending on how you use our Website, you will be subject to different types of Personal Data
          collected and different
          manners of collection:

          <br />
          <br />

          a) REGISTERED USERS: You, as a user of the Website, may be asked to register in order to use the
          Website or to purchase
          the Goods and/or Services available for sale.
          <br />
          <br />

          During the process of your registration, we will collect some of the following Personal Data from
          you through your
          voluntary disclosure:
          <br />
          <br />

          First Name, Last Name, Company Name, Phone Number, Industry Type, Email Address
          <br />
          <br />

          Personal Data may be asked for in relation to:
          <br />
          <br />

          I) Interaction with our representatives in any way;
          <br />
          <br />

          Il) making purchases;
          <br />
          <br />

          Ill) receiving notifications by text message or email about marketing;
          <br />
          <br />

          IV) receiving general emails from us;
          <br />
          <br />

          V) commenting on our content or other user-generated content on our Website, such as blogs,
          articles, photographs or
          videos, or participating in our forums, bulletin boards, chat rooms or other similar features:

          <br />
          <br />
          VI) Or the following other forms of participation:

          <br />
          <br />
          Users can upload a new image or choose one from our predefined library, then publish it directly
          through our website to
          one or many ePaper displays linked to their account that have a valid subscription.

          <br />

          By undergoing the registration process, you consent to us collecting your Personal Data, including
          the Personal Data
          described in this clause, as well as storing, using or disclosing your Personal Data in accordance
          with this Privacy
          Policy.

          <br />
          <br />

          b) UNREGISTERED USERS: If you are a passive user of the Website and do not register for any
          purchases or other services,
          you may still be subject to certain passive data collection ("Passive Data Collection"). Such
          Passive Data Collection
          may include through cookies, as described below, IP address information, location information, and
          certain browser data,
          such as history and/or session information.

          <br />
          <br />

          c) ALL USERS: The Passive Data Collection that applies to Unregistered users shall also apply to
          all other users and/or
          visitors of our Website.

          <br />
          <br />

          d) SALES &amp; BILLING INFORMATION: In order to purchase any of the goods or services on the Website,
          you will be asked to
          provide certain credit information, billing address information, and possibly additional specific
          information so that
          you may be properly charged for your purchases.

          <br />
          <br />

          This payment and billing information will be stored in the users account with an active
          subscription and will be used
          exclusively to assist you with making future purchases with us.

          <br />


          Additionally, payment and billing information will be stored for in account that does not have a
          valid subscription for:
          90 days.

          <br />
          <br />

          e) EMAIL MARKETING: You may be asked to provide certain Personal Data, such as your name and email
          address, for the
          purpose of receiving email marketing communications. This information will only be obtained
          through your voluntary
          disclosure and you will be asked to affirmatively opt-in to email marketing communications.

          <br />
          <br />

          f) USER EXPERIENCE: From time to time we may request information from you to assist us in
          improving our Website, and the
          Goods and Services we sell, such as demographic information or your particular preferences.

          <br />
          <br />

          g) CONTENT INTERACTION: Our Website may allow you to comment on the content that we provide or the
          content that other
          users provide, such as blogs, multimedia, or forum posts. If so, we may collect some Personal Data
          from you at that
          time, such as, but not limited to, username or email address.

          <br />
          <br />

          h) COMBINED OR AGGREGATED INFORMATION: We may combine or aggregate some of your Personal Data in
          order to better serve
          you and to better enhance and update our Website for your and other consumers' use.

          <br />


          We may also share such aggregated information with others, but only if that aggregated information
          does not contain any
          Personal Data

          <br />
          <br />


          <b>Article 6 - THE PERSONAL DATA WE RECEIVE AUTOMATICALLY</b>

          <br />
          <br />
          COOKIES: We may collect information from you through automatic tracking systems (such as
          information about your browsing
          preferences) as well as through information that you volunteer to us (such as information that you
          provide during a
          registration process or at other times while using the Website, as described above)
          <br />


          For example, we use cookies to make your browsing experience easier and more intuitive: cookies
          are small strings of
          text used to store some information that may concern the user, his or her preferences or the
          device they are using to
          access the internet (such as a computer, tablet, or mobile phone). Cookies are mainly used to
          adapt the operation of the
          site to your expectations, offering a more personalized browsing experience and memorizing the
          choices you made
          previously.
          <br />


          A cookie consists of a reduced set of data transferred to your browser from a web server and it
          can only be read by the
          server that made the transfer. This is not executable code and does not transmit viruses

          <br />

          Cookies do not record or store any Personal Data. If you want, you can prevent the use of cookies,
          but then you may not
          be able to use our Website as we intend. To proceed without changing the options related to
          cookies, simply continue to
          use our Website.

          <br />
          <br />
          TECHNICAL COOKIES: Technical cookies, which can also sometimes be called HTML cookies, are used
          for navigation and to
          facilitate your access to and use of the site. They are necessary for the transmission of
          communications on the network
          or to supply services requested by you. The use of technical cookies allows the safe and efficient
          use of the site.

          <br />

          You can manage or request the general deactivation or cancellation of cookies through your
          browser. If you do this
          though, please be advised this action might slow down or prevent access to some parts of the site

          <br />

          Cookies may also be retransmitted by an analytics or statistics provider to collect aggregated
          information on the number
          of users and how they visit the Website. These are also considered technical cookies when they
          operate as described.

          <br />
          <br />

          Temporary session cookies are deleted automatically at the end of the browsing session - these are
          mostly used to
          identify you and ensure that you don't have to log in each time - whereas permanent cookies remain
          active longer than
          just one particular session.

          <br />
          <br />

          PROFILING COOKIES: We may also use profiling cookies, which are those that create profiles related
          to the user and are
          used in order to send advertising to the user's browser.
          <br />


          When these types of cookies are used, we will receive your explicit consent.

          <br />
          <br />

          SUPPORT IN CONFIGURING YOUR BROWSER: You can manage cookies through the settings of your browser
          on your device.
          However, deleting cookies from your browser may remove the preferences you have set for this
          Website.

          <br />

          For further information and support, you can also visit the specific help page of the web browser
          you are using:

          <br />

          - Internet Explorer: http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies

          <br />

          - Firefox: https://support.mozilla.org/en-us/kb/enable-and-disable-cookies-website-preferences

          <br />

          - Safari: http://www.apple.com/legal/privacy/

          <br />

          - Chrome: https://support.google.com/accounts/answer/61416?h|=en

          <br />

          -Opera: http://www.opera.com/help/tutorials/security/cookies/

          <br />

          LOG DATA: Like all websites and mobile applications, this Website also makes use of log files that
          store automatic
          information collected during user visits. The different types of log data could be as follows:

          <br />

          - internet protocol (IP) address;

          <br />

          - type of browser and device parameters used to connect to the Website;

          <br />

          - name of the Internet Service Provider (ISP);

          <br />

          - date and time of visit;

          <br />

          - web page of origin of the user (referral) and exit:

          <br />

          - possibly the number of clicks.
          <br />


          The aforementioned information is processed in an automated form and collected in an exclusively
          aggregated manner in
          order to verify the correct functioning of the site, and for security reasons. This information
          will be processed
          according to the legitimate interests of the Data Controller.

          <br />

          For security purposes (spam filters, firewalls, virus detection), the automatically recorded data
          may also possibly
          include Personal Data such as IP address, which could be used, in accordance with applicable laws,
          in order to block
          attempts at damage to the Website or damage to other users, or in the case of harmful activities
          or crime. Such data are
          never used for the identification or profiling of the user, but only for the protection of the
          Website and our users.
          Such information will be treated according to the legitimate interests of the Data Controller.

          <br />
          <br />


          <b>Article 7 - THIRD PARTIES</b>
          <br />
          <br />

          We may utilize third-party service providers ("Third-Party Service Providers"), from time to time
          or all the time, to
          help us with our Website, and to help serve you.

          <br />

          We may use Third-Party Service Providers to assist with information storage (such as cloud
          storage).

          <br />

          We may provide some of your Personal Data to Third-Party Service Providers in order to help us
          track usage data, such as
          referral websites, dates and times of page requests, etc. We use this information to understand
          patterns of usage of,
          and to improve, the Website.

          <br />

          We may use Third-Party Service Providers to host the Website. In this instance, the Third-Party
          Service Provider will
          have access to our Personal Data.

          <br />

          We may use Third-Party Service Providers to fulfill orders in relation to the Website.

          <br />

          We may use Third-Party Service Providers for the following services in relation to the Website:

          <br />

          The website uses third-party comment moderators to review and look over all comments and then
          approve or deny.

          <br />

          We only share your Personal Data with a Third-Party Service Provider if that provider agrees to
          our privacy standards as
          set out in this Privacy Policy.
          <br />


          Your Personal Data will not be sold or otherwise transferred to other third parties without your
          approval.

          <br />

          Notwithstanding the other provisions of this Privacy Policy, we may provide your Personal Data to
          a third party or to
          third parties in order to protect the riahts, property or safety, of us, our customers or third
          parties, or as otherwise
          required by law.

          <br />

          We will not knowingly share your Personal Data with any third parties other than in accordance
          with this Privacy Policy.

          <br />

          If your Personal Data might be provided to a third party in a manner that is other than as
          explained in this Privacy
          Policy, you will be notified. You will also have the opportunity to request that we not share that
          information.

          <br />

          In general, you may request that we do not share our Personal Data with third parties. Please
          contact us via email. if
          so. Please be advised that you may lose access to certain services that we rely on third-party
          providers for.

          <br />
          <br />


          <b>Article 8 - HOW PERSONAL DATA IS STORED</b>

          <br />
          <br />
          We use secure physical and digital systems to store your Personal Data when appropriate. We ensure
          that your Personal
          Data is protected against unauthorized access, disclosure, or destruction.

          <br />

          Please note, however, that no system involving the transmission of information via the internet,
          or the electronic
          storage of data, is completely secure. However, we take the protection and storage of your
          Personal Data very seriously.
          We take all reasonable steps to protect your Personal Data.

          <br />

          The systems that we use to store your information include but are not limited to:

          <br />
          <br />
          We ensure that only a handful of staff members have access to personal user information data. We
          use an outside
          monitoring service to make sure we never have breaches.

          <br />
          <br />
          Personal Data is stored throughout your relationship with us. We delete your Personal Data upon
          request for cancellation
          of your account or other general request for the deletion of data.
          <br />
          <br />

          In the event of a breach of our Personal Data, you will be notified in a reasonable time frame,
          but in no event later
          than two weeks, and we will follow all applicable laws regarding such breach.
          <br />
          <br />



          <b>Article 9 - PURPOSES OF PROCESSING OF PERSONAL DATA</b>

          <br />
          <br />
          We primarily use your Personal Data to help us provide a better experience for you on our Website
          and to provide you the
          services and/or information you may have requested, such as use of our Website.
          <br />


          Information that does not identify you personally, but that may assist in providing us broad
          overviews of our customer
          base, will be used for market research or marketing efforts. Such information may include, but is
          not limited to,
          interests based on your cookies.
          <br />


          Personal Data that may be considering identifying may be used for the following:

          <br />

          a) Improving your personal user experience;

          <br />
          <br />
          b) Communicating with you about your user account with us;
          <br />
          <br />

          c) Marketing and advertising to you, including via email;

          <br />
          <br />
          d) Fulfilling your purchases;

          <br />
          <br />
          e) Providing customer service to you;
          <br />
          <br />

          f) Advising you about updates to the Website or related Items
          <br />
          <br />



          <b>Article 10 - DISCLOSURE OF PERSONAL DATA</b>
          <br />
          <br />

          Although our policy is to maintain the privacy of your Personal Data as described herein, we may
          disclose your Personal
          Data if we believe that it is reasonable to do so in certain cases, in our sole and exclusive
          discretion. Such cases may
          include, but are not limited to:
          <br />


          a) To satisfy any local, state, or Federal laws or regulations;
          <br />
          <br />

          b) To respond to requests, such discovery, criminal, civil, or administrative process, subpoenas,
          court orders, or writs
          from law enforcement or other governmental or legal bodies;
          <br />
          <br />

          c) To bring legal action against a user who has violated the law or violated the terms of use of
          our Website;

          <br />
          <br />
          d) As may be necessary for the operation of our Website;
          <br />
          <br />

          e) To generally cooperate with any lawful investigation about our users;
          <br />
          <br />

          f) If we suspect any fraudulent activity on our Website or if we have noticed any activity which
          may violate our terms
          or other applicable rules.
          <br />
          <br />



          <b>Article 11 - PUBLIC INFORMATION</b>
          <br />
          <br />

          We may allow users to post their own content or information publicly on our Website. Such content
          or information may
          include, but is not limited to, photographs, status updates, blogs, articles, or other personal
          snippets. Please be
          aware that any such information or content that you may post should be considered entirely public
          and that we do not
          purport to maintain the privacy of such public information.

          <br />
          <br />


          <b>Article 12 - OPTING OUT OF TRANSMITTALS FROM US</b>

          <br />
          <br />
          From time to time, we may send you informational or marketing communications related to our
          Website such as
          announcements or other information. If you wish to opt-out of such communications, you may contact
          the following email:
          info@epapersignsystem.com. You may also click the opt-out link which will be provided at the
          bottom of any and all such
          communications.
          <br />


          Please be advised that even though you may opt-out of such communications, you may still receive
          information from us
          that is specifically about your use of our Website or about your account with us.

          <br />

          By providing any Personal Data to us, or by using our Website in any manner, you have created a
          commercial relationship
          with us. As such, you agree that any email sent from us or third-party affiliates, even
          unsolicited email, shall
          specifically not be considered SPAM, as that term is legally defined.

          <br />
          <br />


          <b>Article 13 - MODIFYING, DELETING, AND ACCESSING YOUR INFORMATION</b>
          <br />
          <br />

          If you wish to modify or delete any information we may have about you, or you wish to simply
          access any information we
          have about you, you may do so from your account settings page.

          <br />
          <br />


          <b>Article 14 - ACCEPTANCE OF RISK</b>
          <br />
          <br />

          By continuing to our Website in any manner, use the Product, you manifest our continuing asset to
          this Privacy Policy.
          You further acknowledge, agree and accept that no transmission of information or data via the
          internet is not always
          completely secure, no matter what steps are taken. You acknowledge, agree and accept that we do
          not guarantee or warrant
          the security of any information that you provide to us, and that you transmit such information at
          your own risk.

          <br />
          <br />


          <b>Article 15 - CONTACT INFORMATION</b>
          <br />
          <br />

          If you have any questions about this Privacy Policy or the way we collect information from you, or
          if you would like to
          launch a complaint about anything related to this Privacy Policy, you may contact us at the
          following email address:
          info@epapersignsystem.com
        </lable>
      </ModalBody>

      <ModalFooter>
        <Button color="secondary" onClick={close}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Policies