import usFlag from "../assets/images/flags/us.jpg"
import spain from "../assets/images/flags/spain.jpg"
import brasil from "../assets/images/flags/brasil.png"

const languages = {
  en: {
    label: "English",
    flag: usFlag,
  },
  // bt: {
  //   label: "Brasil",
  //   flag: brasil,
  // },
  // sp: {
  //   label: "Spanish",
  //   flag: spain,
  // },
  
}

export default languages
