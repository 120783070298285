import PropTypes from 'prop-types'
import MetaTags from "react-meta-tags";
import React, { Fragment, useEffect } from "react";

import { Row, Col, CardBody, Card, Container } from "reactstrap";

import { withRouter, Link } from "react-router-dom";
import Preloader from "components/Common/Preloader";
import Login from "./Login";
import { useDispatch, useSelector } from "react-redux";
import { resetAuth } from "store/actions";
import logo from "../../assets/images/logo.png";
import Otp from './Otp'

const LoginControl = () => {
  const dispatch = useDispatch();
  const { state } = useSelector((state) => state.Login);

  useEffect(() => {
    dispatch(resetAuth())
  }, [])

  return (
    <Fragment>
      <MetaTags>
        <title>Login | ePaperSignSystem</title>
      </MetaTags>

      <section className="auth-wrapper">
        <Preloader />
        <div className="min-vh-100 d-flex align-items-center">
          <Container className="py-3">
            <Row className="justify-content-center">
              <Col xl={7}>
                <Card>
                  <div className="p-4 text-center">
                    <div className="text-center mb-3">
                      <img
                        src={logo}
                        alt=""
                        height="65"
                      />
                    </div>
                    <h6>Welcome Back</h6>
                    <h4>Sign in to your account</h4>
                  </div>
                  <CardBody className="pt-0">
                    <div className="p-2">
                      {
                        state == 'login' ?
                          <Login />
                          : <Otp />
                      }

                    </div>
                    <div className="mt-3 text-center">
                      <p>
                        Do not have an account ?{" "}
                        <Link to="/register" className="font-weight-medium text-primary">
                          {" "}
                          Sign up
                        </Link>{" "}
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </Fragment>
  );
};

export default withRouter(LoginControl);

LoginControl.propTypes = {
  history: PropTypes.object,
};
