import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { map } from "lodash";
import {
  Badge,
  Card,
  CardBody,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { getThumbnail } from "utils/common";

const DevicesCard = ({ devices }) => {
  return (
    <React.Fragment>
      {map(devices, (device, key) => (
        <Col xl="4" md="6" xs="12" key={key}>
          <Link to={`/devices/${device.id}`}>
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="avatar-lg me-4">
                    <span className="avatar-title rounded bg-light text-danger font-size-16">
                      <img src={getThumbnail(device.active_image)} alt="" height="100" width="100" className="rounded" />
                    </span>
                  </div>

                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="text-truncate font-size-15 mb-1">
                      {device.name}
                    </h5>
                    <p className="text-muted mb-3">{device.serial_no}</p>

                    <div className="mb-1 row align-items-baseline">
                      <i className="col-1 fas fa-broadcast-tower text-dark me-1" />
                      <span className="col-9 text-dark">{device.info.comms_type}</span>
                    </div>

                    <div className="row align-items-baseline mb-1">
                      <i className="col-1 bx bxs-detail text-dark me-1" />
                      <span className="col-9 text-dark">{device.info.description}</span>
                    </div>

                    <div className="row align-items-baseline mb-1 ">
                      <i className="col-1 fas fa-arrows-alt text-dark me-1" />
                      <span className="col-9 text-dark">{device.info.displays[0]?.res_x || 0}x{device.info.displays[0]?.res_y || 0}</span>
                    </div>
                  </div>

                  <div>
                    <span id={`device-${device.id}`} className={`fas fa-circle text-${device.is_active ? 'primary' : 'danger'}`} />
                    <UncontrolledTooltip
                      placement="bottom"
                      target={`device-${device.id}`}
                    >
                      {device.is_active ? 'Active' : 'Inactive'}
                    </UncontrolledTooltip>
                  </div>
                </div>
              </CardBody>
              <div className="px-3 py-3 border-top d-flex justify-content-between">
                <div>
                  <div className="d-flex mb-1 align-items-baseline">
                    <i className="fas fa-globe text-dark me-2" />
                    <span className="text-dark">{device.info.timezone?.split("_").join(" ")}</span>
                  </div>

                  <div className="d-flex mb-1 align-items-baseline">
                    <i className="fas fa-times-circle text-dark me-2" />
                    <span className="text-dark">{device.info.last_disconnection_time}</span>
                  </div>

                  <div className="d-flex mb-1 align-items-baseline">
                    <i className="fas fa-check-circle text-dark me-2" />
                    <span className="text-dark">{device.info.expected_activation_time}</span>
                  </div>
                </div>

                <ul className="list-inline">
                  <li className="list-inline-item">
                    <Badge className={"bg-primary"}>
                      {device.info.state}
                    </Badge>
                  </li>
                </ul>
              </div>
            </Card>
          </Link>
        </Col>
      ))}
    </React.Fragment>
  );
};

DevicesCard.propTypes = {
  devices: PropTypes.array,
};

export default DevicesCard;
