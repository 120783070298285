import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_DEVICES,
  GET_DEVICE
} from "./actionTypes";

import {
  getDevicesSuccess,
  getDevicesFail,
  getDeviceSuccess
} from "./actions";

import {
  getDevices as getDevicesAPI,
  getDevice as getDeviceAPI,
} from "api/v1";


function* fetchDevices({ payload: { params } }) {
  try {
    const response = yield call(getDevicesAPI, params);
    yield put(getDevicesSuccess(response));
  } catch (error) {
    if (error.message != "request-cancel") {
      yield put(getDevicesFail("Something went wrong!"))
      toast.error("Something went wrong!");
    }
  }
}

function* getDevice({ payload: { id } }) {
  try {
    const response = yield call(getDeviceAPI, id);
    yield put(getDeviceSuccess(response));
  } catch (error) {
    if (error.message != "request-cancel") {
      yield put(getDevicesFail("Something went wrong!"))
      toast.error("Something went wrong!");
    }
  }
}

function* deviceSaga() {
  yield takeEvery(GET_DEVICES, fetchDevices);
  yield takeEvery(GET_DEVICE, getDevice);
}

export default deviceSaga;
