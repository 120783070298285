import React from 'react'

function Loader({ message = "Please wait" }) {
  return (
    <div className="text-center text-primary my-3">
      <i className="bx bx-hourglass bx-spin me-2" />
      {message}
    </div>
  )
}

export default Loader
