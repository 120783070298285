import React from 'react'

function Timeline({ steps, currentStep }) {
  let _currentStepIndex = steps.findIndex(_step => _step == currentStep) + 1

  return (
    <div className="stepper-wrapper">
      {
        steps.map((_step, _index) => <div key={`step-${_index}`}
          className={`stepper-item ${_index + 1 == _currentStepIndex ? "active" : _index + 1 < _currentStepIndex ? "completed" : ""}`}>
          <div className="step-counter">{_index + 1}</div>
          <div className="step-name text-capitalize">{_step}</div>
        </div>)
      }
    </div>
  )
}

export default Timeline
