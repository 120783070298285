import {
  GET_CEVENTS_FAIL,
  GET_CEVENTS_SUCCESS,
  GET_CEVENTS,
  ADD_CEVENT,
  EDIT_CEVENT,
  DELETE_CEVENT,
} from "./actionTypes";

const INIT_STATE = {
  ceventWithPagy: {},
  new: false,
  edit: false,
  delete: false,
  loading: false,
};

const cevents = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CEVENTS:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case GET_CEVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        ceventWithPagy: action.payload,
      };

    case ADD_CEVENT:
      return {
        ...state,
        loading: true
      };

    case 'SEND_CEVENT':
      return {
        ...state,
        loading: true
      };

    case EDIT_CEVENT:
      return {
        ...state,
        loading: true
      };

    case GET_CEVENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_CEVENT:
      return {
        ...state,
        loading: true
      };

    case "NEW_CEVENT_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "DELETE_CEVENT_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "EDIT_CEVENT_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default cevents;
