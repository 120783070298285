import { createPlan } from 'api/v1'
import Loader from 'common/Loader'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { setTabBarLoader } from 'utils/common'
import Stripe from 'components/Payment'

function Payment({
  subscription,
  timeline,
  goBack,
  close
}) {
  const [loading, setLoading] = useState(false)
  const [paymentIntent, setPaymentIntent] = useState(null)
  const { frequency, total_screens } = subscription

  useEffect(() => {
    setTabBarLoader(loading)
  }, [loading])

  async function _createPlan() {
    setLoading(true)
    try {
      let _paymentIntent = await createPlan({ total_screens, frequency })
      setPaymentIntent(_paymentIntent)
    } catch (error) {
      let { errors } = error

      if (errors?.non_field_errors)
        toast.error(errors.non_field_errors[0]);
      else
        toast.error("Something went wrong while generating plan")
    }
    setLoading(false)
  }

  useEffect(() => {
    _createPlan()
  }, [])

  return (
    <div>
      {timeline}

      <div className='text-center mt-5'>
        {loading && <Loader />}

        {!loading &&
          <div className='row px-3'>
            <Stripe cart={paymentIntent} close={close} />
          </div>
        }

        <div className="d-flex justify-content-between mt-4">
          <button onClick={goBack} type="button" className="btn btn-light px-4">Back</button>
        </div>
      </div>
    </div>
  )
}

export default Payment