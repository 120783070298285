import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { editPlaylistModal, editPlaylist } from 'store/playlists/actions'
import moment from "moment";

function index({ playlist: target }) {
  let dispatch = useDispatch()
  const { edit: modal } = useSelector((state) => state.playlists);
  let [playlist, setPlaylist] = useState()

  useEffect(() => {
    if (modal) {
      setPlaylist(target)
    }
  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (playlist && playlist.name) || "",
      start_time: (playlist && moment(new Date(playlist.start_time)).format('YYYY-MM-DDTHH:mm:ss')) || '',
      duration: (playlist && playlist.duration) || "",
      interval: (playlist && playlist.interval) || "",
      device: (playlist && playlist.device) || null,
      images: (playlist && playlist.images?.map(_img => _img.id)) || [],
      imagesLength: (playlist && playlist.images?.length)
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      start_time: Yup.string().required("Please select start time"),
      duration: Yup.string().required("Please select duration"),
      interval: Yup.string().required("Please select interval"),
      device: Yup.mixed().required("Please select device"),
      images: Yup.array().
        when(['duration', 'interval', 'imagesLength'], {
          is: (duration, interval, imagesLength) => (duration && interval && imagesLength < (parseInt(duration) * 60) / parseInt(interval)),
          then: Yup.array().min(13, "Please drag all images").required("required"),
          otherwise: Yup.array()
        })
        .required("Please drag images")
    }),
    onSubmit: (values, actions) => {
      dispatch(editPlaylist(playlist.id, {
        ...values,
        device: values.device.id,
        duration: parseFloat(values.duration),
        interval: parseFloat(values.interval),
        start_time: moment(values.start_time, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DDTHH:mm:ssZ"),
      }, actions))
    },
  });

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={modal} toggle={() => dispatch(editPlaylistModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(editPlaylistModal())}>
          Edit Playlist
        </ModalHeader>
        <ModalBody>
          <Form
            target={target}
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
