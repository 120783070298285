import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { newPlaylistModal, addPlaylist } from 'store/playlists/actions'
import moment from "moment";

function index() {
  let dispatch = useDispatch()
  const { new: modal } = useSelector((state) => state.playlists);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: '',
      start_time: '',
      duration: "",
      interval: "",
      device: null,
      images: [],
      imagesLength: 0
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      start_time: Yup.string().required("Please select start time"),
      duration: Yup.string().required("Please select duration"),
      interval: Yup.string().required("Please select interval"),
      device: Yup.mixed().required("Please select device"),
      images: Yup.array().
        when(['duration', 'interval', 'imagesLength'], {
          is: (duration, interval, imagesLength) => (duration && interval && imagesLength < (parseInt(duration) * 60) / parseInt(interval)),
          then: Yup.array().min(13, "Please drag all images").required("required"),
          otherwise: Yup.array()
        })
        .required("Please drag images")
    }),
    onSubmit: (values, actions) => {
      dispatch(addPlaylist({
        ...values,
        device: values.device.id,
        duration: parseFloat(values.duration),
        interval: parseFloat(values.interval),
        start_time: moment(values.start_time, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DDTHH:mm:ssZ"),
      }, actions))
    },
  });

  useEffect(() => {
    if (modal) {
      validation.resetForm()
    }
  }, [modal])

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={modal} toggle={() => dispatch(newPlaylistModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(newPlaylistModal())}>
          Add Playlist
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
