import { filterParams } from "../apiHelper";
import request from "../restClient";

export const getDevices = async (params) => {
  return await request({
    url: "devices/",
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const getDevice = async (id) => {
  return await request({
    url: `devices/${id}/`,
    method: "GET"
  });
};
