import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom"
import { Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  getDevices as onGetDevices
} from "store/devices/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from "utils/common";
import DevicesCard from "components/Devices";
import Pagination from "components/Pagination";

const Devices = () => {
  const dispatch = useDispatch();

  const { deviceWithPagy, loading } = useSelector(state => ({
    deviceWithPagy: state.devices.deviceWithPagy,
    loading: state.devices.loading
  }));
  let { results: devices = [] } = deviceWithPagy

  function init() {
    dispatch(onGetDevices(true));
  }

  useEffect(() => {
    init()
  }, []);

  let pagination = {
    sizePerPage: deviceWithPagy.page_size,
    totalSize: deviceWithPagy.total,
    page: deviceWithPagy.page
  }

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetDevices(true, { page }));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Devices | ePaperSignSystem</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem="Devices" />

          {devices.length == 0 && !loading && <h5>No Devices Found</h5>}

          <Row>
            <DevicesCard
              devices={devices}
            />
          </Row>

          <Pagination
            className="pagination-bar mt-4"
            currentPage={pagination.page}
            totalCount={pagination.totalSize}
            pageSize={pagination.sizePerPage}
            onPageChange={page => handleTableChange("pagination", { page })}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

Devices.propTypes = {
  devices: PropTypes.array,
  onGetDevices: PropTypes.func,
};

export default withRouter(Devices)
