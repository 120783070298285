import { createPlan, getPlans } from 'api/v1'
import Loader from 'common/Loader'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { Badge } from 'reactstrap'
import { getCompany } from 'store/actions'
import Swal from 'sweetalert2'
import { cadFormat, setTabBarLoader } from 'utils/common'

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success mx-2',
    cancelButton: 'btn btn-danger'
  },
  buttonsStyling: false
})

const sweetAlertPayload = {
  title: 'Are you sure?',
  text: "You are going to subscribe different plan..",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes, update!',
  cancelButtonText: 'No, cancel!',
  reverseButtons: true
}

function Pricing({
  subscription,
  setSubscription,
  timeline,
  close,
  forwardable,
  goBack,
  goForward
}) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [plans, setPlans] = useState([])
  const { frequency, total_screens } = subscription

  useEffect(() => {
    setTabBarLoader(loading)
  }, [loading])

  function onUpdateSubscription() {
    close()
    Swal.fire({
      title: 'Processing...',
      html: 'Please wait we are updating subscription.',
      timer: 5000,
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      },
    }).finally(() => {

      dispatch(getCompany())
    })
  }

  async function loadPlans() {
    setLoading(true)
    try {
      let _plans = await getPlans({ total_screens })
      setPlans(_plans)
    } catch (error) {
      let { errors } = error

      if (errors?.non_field_errors)
        toast.error(errors.non_field_errors[0]);
      else
        toast.error("Something went wrong while loading plans")
    }
    setLoading(false)
  }

  useEffect(() => {
    loadPlans()
  }, [])

  function onSelectPlan(_plan) {
    async function _updateSubscription() {
      setLoading(true)
      try {
        await createPlan({ frequency: _plan.frequency, total_screens })
        setSubscription({
          ...subscription,
          frequency: _plan.frequency
        })
        onUpdateSubscription()

      } catch (error) {
        let { errors } = error

        if (errors?.non_field_errors)
          toast.error(errors.non_field_errors[0]);
        else
          toast.error("Something went wrong while updating subscription")
      }
      setLoading(false)

    }

    if (forwardable)
      setSubscription({
        ...subscription,
        frequency: _plan.frequency
      })
    else {
      swalWithBootstrapButtons.fire({ ...sweetAlertPayload }).then(({ isConfirmed }) => {
        if (isConfirmed)
          _updateSubscription()
      })
    }

  }

  return (
    <div>
      {timeline}

      <div className='text-center mt-5 mb-2'>
        {loading && <Loader />}

        {!loading &&
          <div className='visual-picker-container px-4 row'>
            {
              plans.map(_plan =>
                <div key={`plan-${_plan.frequency}`} className='visual-picker visual-picker-lg col-6'>
                  <input checked={_plan.frequency == frequency} onChange={_ => onSelectPlan(_plan)} type="radio" name={`plans`} id={`plan-${_plan.frequency}`} />
                  <label className='visual-picker-figure w-100' htmlFor={`plan-${_plan.frequency}`}>
                    <span className="visual-picker-content w-100">
                      <h4 className='text-capitalize text-primary d-block'>{_plan.frequency}</h4>
                      <hr />
                      <span className='text-lowercase font-size-16 mt-1 d-block'>{cadFormat(_plan.plan_price, true)}  (+{_plan.tax_rate}% Tax)</span>
                      {_plan.discount > 0 && <Badge color="primary" className="my-2 px-2 py-1 font-size-12">discount {_plan.discount_rate}%</Badge>}
                      <span className='text-lowercase font-size-16 mt-1 d-block'>{cadFormat(_plan.total_price, true)}</span>
                      {_plan.discount == 0 && <div className="my-4" />}
                    </span>
                  </label>
                </div>
              )
            }
          </div>
        }

        <div className="d-flex justify-content-between mt-5">
          <button onClick={goBack} type="button" className="btn btn-light px-4">Back</button>
          {forwardable && <button onClick={goForward} type="button" className="btn btn-primary px-4">Next</button>}
        </div>
      </div>
    </div>
  )
}

export default Pricing