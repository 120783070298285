import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, apiPassError, changeState, setTokens } from "./actions";
import { login, requestPasswordChange, verifyPasswordChange, passwordChange, tfa as tfaApi } from 'api/v1'
import { toast } from "react-toastify";

function* loginUser({ payload: { user, history, actions } }) {
  try {
    const { access_token, token, uidb64 } = yield call(login, user);
    if (access_token) {
      localStorage.setItem("access_token", access_token);
      yield put(loginSuccess("Logged in Successfully!"));

      history.push("/dashboard");
    } else {
      yield put(setTokens({ token, uidb64 }))
      yield put(changeState('otp'))
      yield put(loginSuccess("Otp has been sent to your email"));
    }

  } catch ({ errors }) {
    if (errors?.non_field_errors)
      yield put(apiError(errors.non_field_errors[0]));
    else
      yield put(apiError("Something went wrong!"));
    if (errors) {
      actions.setErrors(errors)

    }
  }
}

function* verifyPasswordChangeToken({ payload: { history, data } }) {
  try {
    yield call(verifyPasswordChange, data);
    yield put(loginSuccess("Please set your password"));

  } catch ({ errors }) {
    if (errors?.non_field_errors)
      yield put(apiError(errors.non_field_errors[0]));
    else
      yield put(apiError("Something went wrong!"));
    toast.error('Tokens are invalid')
    history.push("/login");
  }
}

function* resetPassword({ payload: { history, data, actions } }) {
  try {
    yield call(passwordChange, data);

    toast.success('Password is now setup')
    actions.resetForm()
    history.push("/login");

  } catch ({ errors }) {
    if (errors?.non_field_errors)
      yield put(apiError(errors.non_field_errors[0]));
    else
      yield put(apiError("Something went wrong!"));

    if (errors) {
      actions.setErrors(errors)
    }
  }
}

function* requestPassChange({ payload: { user, actions, history } }) {
  try {
    yield call(requestPasswordChange, user)
    toast.success('Email has been sent for password change')
    history.push('/login');

  } catch ({ errors }) {
    if (errors?.non_field_errors)
      yield put(apiPassError(errors.non_field_errors[0]));
    else
      yield put(apiPassError("Something went wrong!"));

    if (errors) {
      actions.setErrors(errors)

    }
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("access_token");

    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* tfa({ payload: { user, history, actions } }) {
  try {
    const { access_token } = yield call(tfaApi, user)
    if (access_token) {
      yield put(changeState('login'))
      localStorage.setItem("access_token", access_token);
      yield put(loginSuccess("Logged in Successfully!"));
      actions.resetForm()
      toast.success("Logged in Successfully")
      history.push("/dashboard");
    }

  } catch ({ errors }) {
    if (errors?.non_field_errors)
      yield put(apiError(errors.non_field_errors[0]))
    else
      yield put(apiError("Something went wrong"))

    if (errors) {
      actions.setErrors(errors)

    }
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery('REQUEST_PASSWORD_CHANGE', requestPassChange);
  yield takeEvery('VERIFY_PASSWORD_CHANGE_TOKEN', verifyPasswordChangeToken);
  yield takeEvery('RESET_PASSWORD', resetPassword);
  yield takeEvery('TFA', tfa)
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
