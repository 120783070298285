import {
  GET_CARDS,
  GET_CARDS_SUCCESS,
  GET_CARDS_FAIL,
  DELETE_CARD,
  ADD_CARD,
  EDIT_CARD
} from "./actionTypes"

export const getCards = (loading = false, params = {}) => ({
  type: GET_CARDS,
  payload: { loading, params }
})

export const getCardsSuccess = cards => ({
  type: GET_CARDS_SUCCESS,
  payload: cards,
})

export const getCardsFail = error => ({
  type: GET_CARDS_FAIL,
  payload: error,
})

export const deleteCard = card => ({
  type: DELETE_CARD,
  payload: { card }
})

export const addCard = (card) => {
  return {
    type: ADD_CARD,
    payload: { card },
  }
}

export const editCard = (id) => {
  return {
    type: EDIT_CARD,
    payload: { id },
  }
}

export const newCardModal = () => ({
  type: 'NEW_CARD_MODAL'
})

export const deleteCardModal = () => ({
  type: 'DELETE_CARD_MODAL'
})

export const editCardModal = () => ({
  type: 'EDIT_CARD_MODAL'
})
