import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container, Row,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getTimezoneAbb } from "utils/common";
import Devices from "components/Dashboard/Devices";
import Loader from "common/Loader";
import Activities from "components/Dashboard/Activities";

const Dashboard = props => {
  const { company } = useSelector(state => ({
    company: state.company.company
  }));

  const [dateState, setDateState] = useState(new Date());
  useEffect(() => {
    setInterval(() => setDateState(new Date()), 30000);
  }, []);

  const _isSubscriptionActive = (company?.is_trial_available && company?.remaining_trial_days > 0) || company?.is_subscription_active

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard</title>
        </MetaTags>
        {
          !company ? <Loader />
            : <Container fluid>
              {/* Render Breadcrumb */}
              <Breadcrumbs
                title={props.t("Dashboard")}
                breadcrumbItem={props.t("Dashboard")}
              />

              <Row>
                <Col xs="12">
                  <h1 className="text-center">Welcome{company ? ", " + company.name : ""}</h1>
                  <p className="text-center font-size-18 mb-0">Thank you for subscribing to the ePaperSignSystem.</p>
                  <p className="text-center font-size-18">Use the tab on the left to manage active devices linked to your subscription.</p>
                </Col>
              </Row>

              <Row className="my-2">
                <Col xs="12">
                  {_isSubscriptionActive && <Devices />}
                </Col>
              </Row>

              <Row>
                <Col xs="12" sm="6">
                  <Card style={{ height: '250px' }}>
                    <CardBody>
                      <p className="text-uppercase font-weight-semibold font-size-16">{getTimezoneAbb()}</p>

                      <b className="display-3">{dateState.toLocaleString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: false,
                      })}
                      </b>

                      <p className="mb-0 mt-4 text-uppercase font-weight-semibold font-size-16">{dateState.toLocaleDateString('en-GB', {
                        weekday: 'long'
                      })}</p>
                      <p className="text-uppercase font-weight-semibold font-size-16">{dateState.toLocaleDateString('en-GB', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                      })}</p>
                    </CardBody>
                  </Card>
                </Col>

                <Col xs="12" sm="6">
                  <Card style={{ height: '250px' }}>
                    <CardBody>
                      <p className="text-uppercase font-weight-semibold font-size-16">Your Plan</p>

                      <p className="mt-4 mb-0 font-weight-normal">You are currently subscribed to:</p>
                      <p className="mb-0 text-uppercase font-weight-semibold font-size-16">{!company.subscribed_plan ? (company.is_trial_available ? 'on trial' : 'no plan') : `${company.subscribed_plan?.frequency}`}</p>

                      <p className="mt-3 mb-0 font-weight-normal">Your subscription includes:</p>
                      <p className="mb-0 text-uppercase font-weight-semibold font-size-16">{company.plan_restrictions?.allowed_screens || 0} Devices</p>
                    </CardBody>
                  </Card>
                </Col>

                <Col xs="12">
                  <Card>
                    <CardBody>
                      <Activities />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
        }
      </div>
    </React.Fragment >
  );
};

Dashboard.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Dashboard);
